// @ts-nocheck

// Packages
import React, { useState, useEffect } from 'react';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ListItemIcon from '@mui/material/ListItemIcon';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';

// Utils
import isEmpty from 'src/utils/isEmpty';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
    textAlign: 'center',
  },
  closeButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  submitButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

function AddressDialog({
  addresses,
  address,
  openAddressDialog,
  setOpenAddressDialog,
  handleCreateAddress,
  handleUpdateAddress,
}) {
  const classes = useStyles();
  const [addressData, setAddressData] = useState(
    address || {
      usage: '',
      street: '',
      houseNumber: '',
      zip: '',
      city: '',
      country: 'Deutschland',
    },
  );
  useEffect(() => () => setAddressData({}), []);

  function handleChangeAddressData(event) {
    const { name, value, type, checked } = event.target;
    setAddressData({
      ...addressData,
      [name]: type !== 'checkbox' ? value : checked,
    });
  }

  function handleCloseAddressDialog() {
    setOpenAddressDialog(false);
  }

  function handleSubmit() {
    address ? handleUpdateAddress(addressData) : handleCreateAddress(addressData);
    handleCloseAddressDialog();
  }

  function isFormDisabled() {
    return (
      !addressData.usage ||
      !addressData.street ||
      !addressData.houseNumber ||
      !addressData.zip ||
      !addressData.city ||
      !addressData.country
    );
  }

  return (
    <Dialog maxWidth="md" open={openAddressDialog} onClose={() => handleCloseAddressDialog()}>
      <DialogTitle className={classes.title}>Adresse {address ? 'bearbeiten' : 'hinzufügen'}</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
          <Grid item mobile={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Adress-Art</InputLabel>
              <Select
                label="Address-Art"
                value={addressData.usage || ''}
                name="usage"
                onChange={handleChangeAddressData}
              >
                <MenuItem disabled={addresses.some((address) => address.usage === 'home')} value="home">
                  Zentrale Anschrift
                </MenuItem>
                <MenuItem disabled={addresses.some((address) => address.usage === 'invoice')} value="invoice">
                  Rechnungs-Anschrift
                </MenuItem>
                <MenuItem value="delivery">Liefer-Anschrift</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item mobile={12} desktop={3}>
            <TextField
              variant="outlined"
              label="Strasse"
              type="text"
              value={addressData.street || ''}
              name="street"
              fullWidth
              onChange={handleChangeAddressData}
            />
          </Grid>
          <Grid item mobile={12} desktop={2}>
            <TextField
              variant="outlined"
              label="Haus-Nr."
              type="text"
              value={addressData.houseNumber || ''}
              name="houseNumber"
              fullWidth
              onChange={handleChangeAddressData}
            />
          </Grid>
          <Grid item mobile={12} desktop={2}>
            <TextField
              variant="outlined"
              label="PLZ"
              type="text"
              value={addressData.zip || ''}
              name="zip"
              fullWidth
              onChange={handleChangeAddressData}
            />
          </Grid>
          <Grid item mobile={12} desktop={3}>
            <TextField
              variant="outlined"
              label="Ort"
              type="text"
              value={addressData.city || ''}
              name="city"
              fullWidth
              onChange={handleChangeAddressData}
            />
          </Grid>
          <Grid item mobile={12} desktop={2}>
            <TextField
              variant="outlined"
              label="Land"
              type="text"
              value={addressData.country || ''}
              name="country"
              fullWidth
              onChange={handleChangeAddressData}
            />
          </Grid>
          {address && (
            <Grid item mobile={12}>
              aktiv
              <ListItemIcon>
                <Switch
                  checked={!isEmpty(addressData.isActive) ? addressData.isActive : true}
                  name="isActive"
                  onChange={handleChangeAddressData}
                />
              </ListItemIcon>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button fullWidth onClick={() => handleCloseAddressDialog()} className={classes.closeButton}>
          Abbrechen
        </Button>
        <Button
          fullWidth
          disabled={isFormDisabled()}
          onClick={handleSubmit}
          variant="contained"
          className={classes.submitButton}
        >
          {address ? 'Aktualisieren' : 'Erstellen'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddressDialog;
