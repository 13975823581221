// Packages
import React from 'react';

// Views
import { UserAuth as UserAuthLayout } from 'src/layouts';
import { default as SignInUserView } from './_SignInUser';

function SignInUser() {
  return (
    <UserAuthLayout>
      <SignInUserView />
    </UserAuthLayout>
  );
}

export default SignInUser;
