// @ts-nocheck
// Packages
import React from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// MUI
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Interfaces
import { Order as OrderData } from 'src/services/interfaces/order.interface';
import { Offer as OfferData } from 'src/services/interfaces/offer.interface';

// Services
import { createOrder } from 'src/services/order';
import { updateOrder } from 'src/services/order';
import { createOffer, updateOffer } from 'src/services/offer';

interface Props {
  assignment: OrderData | OfferData;
  isOffer: boolean;
  offerOrOrder: 'Angebot' | 'Auftrag';
}

function Submit({ assignment, isOffer, offerOrOrder }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { assignmentForm }: { assignmentForm: OrderData | OfferData } = useSelector(
    (state: RootStateOrAny) => state.assignment,
  );

  const isBaseData = () => !isEmpty(assignmentForm.customer);

  async function handleSubmit() {
    try {
      const orderData = {
        ...assignmentForm,
        assignmentId: (assignment && assignment._id) || null,
        customer: assignmentForm.customer._id,
        purchaser: !isEmpty(assignmentForm.purchaser) ? assignmentForm.purchaser._id : null,
        number: (assignment && assignment.number) || null,
        numberLetter: assignmentForm.numberLetter || null,
      };

      const formData = new FormData();
      formData.append('assignmentForm', JSON.stringify(orderData));

      if (assignmentForm.pdf) {
        // @ts-ignore
        formData.append('pdf', assignmentForm.pdf);
      }

      // prettier-ignore
      const data = assignment
        ? isOffer
          ? await updateOffer(formData) // Fall 1
          : await updateOrder(formData) // Fall 2
        : offerOrOrder === 'Angebot'
          ? await createOffer(formData) // Fall 5
          : await createOrder(formData); // Fall 6

      // prettier-ignore
      dispatch(
        showSnackbarReducer({
          message: assignment
            ? isOffer
              ? 'Angebot aktualisiert' // Fall 1
              : 'Auftrag aktualisiert' // Fall 2
            : offerOrOrder === 'Angebot'
              ? 'Angebot erstellt' // Fall 5
              : 'Auftrag erstellt', // Fall 6
          severity: 'success',
        }),
      );

      history.push(
        isOffer || offerOrOrder === 'Angebot'
          ? `/assignment/details/offer/${data._id}`
          : `/assignment/details/order/${data._id}`,
      );
    } catch (error) {
      if (error) throw error;
    }
  }

  // prettier-ignore
  return (
    <Button
      fullWidth
      disabled={!isBaseData()}
      onClick={handleSubmit}
      variant="contained"
      color="green"
      size="large"
      startIcon={<CheckIcon />}
    >
      {
        assignment
          ? isOffer
            ? 'Angebot aktualisieren' // Fall 1
            : 'Auftrag aktualisieren' // Fall 2
          : offerOrOrder === 'Angebot'
            ? 'Angebot erstellen' // Fall 5
            : 'Auftrag erstellen' // Fall 6
      }
    </Button>
  );
}

export default Submit;
