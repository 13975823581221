// @ts-nocheck

// Packages
import React from 'react';

// Views
import { App as AppLayout } from 'src/layouts';
import { default as AssignmentDetailsView } from './_AssignmentDetails';

// Layouts

function AssignmentDetails() {
  return (
    <AppLayout>
      <AssignmentDetailsView />
    </AppLayout>
  );
}

export default AssignmentDetails;
