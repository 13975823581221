// Packages
import React from 'react';

// Views
import { App as AppLayout } from 'src/layouts';
import { default as WarehouseView } from './_Warehouse';

// Layouts

function Warehouse() {
  return (
    <AppLayout>
      <WarehouseView />
    </AppLayout>
  );
}

export default Warehouse;
