// Packages
import React from 'react';

// MUI
import { ChangeEventHandler } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MuiTextField from '@mui/material/TextField';

const useStyles = makeStyles({
  formControl: { width: '100%' },
  error: { lineHeight: '20px', margin: '0', textTransform: 'uppercase', fontWeight: 'bold' },
});

interface Props {
  label: string;
  margin?: 'normal' | 'none' | 'dense' | undefined;
  color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined;
  type?: string;
  value: string;
  name: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  placeholder: string;
  error: string;
  multiline?: boolean | undefined;
  rows?: string;
}

function TextField({
  label,
  margin,
  color,
  type,
  value,
  name,
  onChange,
  placeholder,
  error,
  multiline,
  rows,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} error>
      <MuiTextField
        color={color}
        type={type || 'text'}
        margin={margin}
        variant="outlined"
        error={!!error}
        label={label}
        name={name}
        value={value || ''}
        onChange={onChange}
        multiline={multiline}
        rows={rows}
        placeholder={placeholder}
        fullWidth
      />
      {error && <FormHelperText className={classes.error}>{error}</FormHelperText>}
    </FormControl>
  );
}

export default TextField;
