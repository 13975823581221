// Packages
import React from 'react';

// Interfaces
import { Position as PositionData } from 'src/services/interfaces/position.interface';
import { PackingList as PackingListData } from 'src/services/interfaces/packingList.interface';

// Local Components
import { Positions } from './components';

interface Props {
  positions: PositionData[];
  deliveryCondition: PackingListData['deliveryCondition'];
}

function PositionsPackingList({ positions, deliveryCondition }: Props): JSX.Element {
  return <Positions positions={positions} deliveryCondition={deliveryCondition} />;
}

export default PositionsPackingList;
