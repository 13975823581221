// Packages
import React, { useState } from 'react';

// Global Components
import NumPad from 'src/components/NumPad';

import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const useStyles = makeStyles({
  button: { width: '100%', height: 200 },
});

interface Props {
  _id: string;
  firstName: string;
  lastName: string;
  personnelNumber: number;
  passwordStatus: string;
  signInUserData: {
    _id: string;
    password: string;
    passwordStatus: string;
  };
  setSignInUserData: React.Dispatch<
    React.SetStateAction<{
      _id: string;
      password: string;
      passwordStatus: string;
    }>
  >;
  handleSubmit: () => Promise<void>;
  handleResetPassword: () => Promise<void>;
  errors: { password: string };
}

function Modal({
  _id,
  firstName,
  lastName,
  personnelNumber,
  passwordStatus,
  signInUserData,
  setSignInUserData,
  handleSubmit,
  handleResetPassword,
  errors,
}: Props): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setSignInUserData({ ...signInUserData, _id });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button className={classes.button} variant="contained" color="primary" onClick={handleClickOpen}>
        {firstName} {lastName} (#{personnelNumber})
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <NumPad
            _id={_id}
            passwordStatus={passwordStatus}
            signInUserData={signInUserData}
            setSignInUserData={setSignInUserData}
            errors={errors}
            handleSubmit={handleSubmit}
            handleResetPassword={handleResetPassword}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Modal;
