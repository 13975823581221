// Packages
import React from 'react';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

// React-Icons
import { BsBackspaceFill } from 'react-icons/bs';

const useStyles = makeStyles((theme) => ({
  digit: {
    fontSize: '41px',
    width: '150px',
    height: '70px',
    padding: '0',
    color: 'white',
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

interface Props {
  textfieldName: string;
  tabValue: number;
  warehouseItemToStore: any;
  handleChangeWarehouseItemToStore: Function;
  warehouseItemToFind: any;
  handleChangeWarehouseItemToFind: Function;
}

function NumPad({
  textfieldName,
  tabValue,
  warehouseItemToStore,
  handleChangeWarehouseItemToStore,
  warehouseItemToFind,
  handleChangeWarehouseItemToFind,
}: Props): JSX.Element {
  const classes = useStyles();

  const handleChangeNumPad = (number: string) => {
    if (tabValue === 0) {
      handleChangeWarehouseItemToStore({
        name: textfieldName,
        value: warehouseItemToStore[textfieldName] ? warehouseItemToStore[textfieldName] + number : number,
      });
    }

    if (tabValue === 1) {
      handleChangeWarehouseItemToFind({
        name: textfieldName,
        value: warehouseItemToFind[textfieldName as keyof typeof warehouseItemToFind] + number,
      });
    }
  };

  const handleDeleteNumPad = () => {
    if (tabValue === 0) {
      const changedValue: string =
        (warehouseItemToStore[textfieldName as keyof typeof warehouseItemToStore] as string) || '';
      handleChangeWarehouseItemToStore({
        name: textfieldName,
        value: changedValue.toString().slice(0, -1),
      });
    }

    if (tabValue === 1) {
      const changedValue: string =
        (warehouseItemToFind[textfieldName as keyof typeof warehouseItemToFind] as string) || '';
      handleChangeWarehouseItemToFind({
        name: textfieldName,
        value: changedValue.slice(0, -1),
      });
    }
  };

  return (
    <Grid container spacing={2} alignItems="center" sx={{ display: { mobile: 'none', desktop: 'flex' } }}>
      <Grid item xs={4}>
        <Button className={classes.digit} onClick={() => handleChangeNumPad('1')} variant="contained">
          1
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} onClick={() => handleChangeNumPad('2')} variant="contained">
          2
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} onClick={() => handleChangeNumPad('3')} variant="contained">
          3
        </Button>
      </Grid>

      <Grid item xs={4}>
        <Button className={classes.digit} onClick={() => handleChangeNumPad('4')} variant="contained">
          4
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} onClick={() => handleChangeNumPad('5')} variant="contained">
          5
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} onClick={() => handleChangeNumPad('6')} variant="contained">
          6
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} onClick={() => handleChangeNumPad('7')} variant="contained">
          7
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} onClick={() => handleChangeNumPad('8')} variant="contained">
          8
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} onClick={() => handleChangeNumPad('9')} variant="contained">
          9
        </Button>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <Button className={classes.digit} onClick={() => handleChangeNumPad('0')} variant="contained">
          0
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} onClick={() => handleDeleteNumPad()} variant="contained">
          <BsBackspaceFill />
        </Button>
      </Grid>
    </Grid>
  );
}

export default NumPad;
