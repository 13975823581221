// @ts-nocheck

// Packages
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Utils
import isEmpty from 'src/utils/isEmpty';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  abortButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  greenButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

function DeliveryTermDialog({
  editDeliveryTerm,
  openDeliveryTermDialog,
  setOpenDeliveryTermDialog,
  handleCreateDeliveryTerm,
  handleUpdateDeliveryTerm,
  companyData,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [deliveryTermData, setDeliveryTermData] = useState(editDeliveryTerm.incoterm ? editDeliveryTerm : {});

  useEffect(() => {
    setDeliveryTermData(editDeliveryTerm.incoterm ? editDeliveryTerm : {});
    return () => setDeliveryTermData({});
  }, []); //eslint-disable-line

  function handleCloseDeliveryTermDialog() {
    setOpenDeliveryTermDialog(false);
  }

  function handleChangeDeliveryTermData(event) {
    const { name, value, checked } = event.target;
    setDeliveryTermData({
      ...deliveryTermData,
      [name]: name === 'deliveryService' ? checked : value,
    });
  }

  function handleSubmit() {
    const isDuplicateDeliveryTerm = companyData.deliveryTerms
      ? companyData.deliveryTerms.map((deliveryTerm) => deliveryTerm.incoterm).includes(deliveryTermData.incoterm)
      : false;

    const deliveryTermIndex = companyData.deliveryTerms
      ? companyData.deliveryTerms.map((deliveryTerm) => deliveryTerm.incoterm).indexOf(editDeliveryTerm.incoterm)
      : -1;

    if (!isDuplicateDeliveryTerm || editDeliveryTerm.incoterm === deliveryTermData.incoterm) {
      isEmpty(editDeliveryTerm)
        ? handleCreateDeliveryTerm(deliveryTermData)
        : handleUpdateDeliveryTerm(deliveryTermData, deliveryTermIndex);

      handleCloseDeliveryTermDialog();
    } else {
      dispatch(
        showSnackbarReducer({
          message: 'diese Lieferbedingung ist bereits vorhanden',
          severity: 'error',
        }),
      );
    }
  }

  return (
    <Dialog maxWidth="md" open={openDeliveryTermDialog} onClose={() => handleCloseDeliveryTermDialog()}>
      <Box textAlign="center">
        <DialogTitle className={classes.dialogTitle}>
          Lieferbedinung {editDeliveryTerm.incoterm ? 'bearbeiten' : 'hinzufügen'}
        </DialogTitle>
      </Box>
      <DialogContent>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid item mobile={3}>
            <TextField
              variant="outlined"
              label="Incoterm"
              type="text"
              value={deliveryTermData.incoterm || ''}
              name="incoterm"
              fullWidth
              onChange={handleChangeDeliveryTermData}
            />
          </Grid>
          <Grid item mobile={9}>
            <TextField
              variant="outlined"
              label="Bedeutung"
              type="text"
              value={deliveryTermData.meaning || ''}
              name="meaning"
              fullWidth
              onChange={handleChangeDeliveryTermData}
            />
          </Grid>
          <Grid item mobile={12}>
            <FormControl>
              <FormControlLabel
                name="deliveryService"
                control={
                  <Switch
                    color="primary"
                    checked={deliveryTermData.deliveryService || false}
                    onChange={handleChangeDeliveryTermData}
                  />
                }
                label="Versand mit externem Dienstleister"
                labelPlacement="start"
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item mobile={6}>
          <Button
            fullWidth
            variant="contained"
            className={classes.abortButton}
            onClick={() => handleCloseDeliveryTermDialog()}
          >
            Abbrechen
          </Button>
        </Grid>
        <Grid item mobile={6}>
          <Button
            fullWidth
            onClick={handleSubmit}
            variant="contained"
            className={classes.greenButton}
            disabled={
              isEmpty(deliveryTermData.incoterm) ||
              (editDeliveryTerm.incoterm === deliveryTermData.incoterm &&
                editDeliveryTerm.meaning === deliveryTermData.meaning &&
                editDeliveryTerm.deliveryService === deliveryTermData.deliveryService)
            }
          >
            {editDeliveryTerm.incoterm ? 'Aktualisieren' : 'Erstellen'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default DeliveryTermDialog;
