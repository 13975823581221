// Packages
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import { useSelector, RootStateOrAny } from 'react-redux';

// MUI
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';

interface Props {
  handleUpdateBaseData: (data: { name: string; value: any }) => void;
}

function DeliveryDate({ handleUpdateBaseData }: Props) {
  const { deliveryDate } = useSelector((state: RootStateOrAny) => state.assignment.assignmentForm);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils} locale={deLocale}>
      <DatePicker
        label="Lieferdatum"
        renderInput={(props) => <TextField {...props} />}
        value={deliveryDate}
        onChange={(date) => handleUpdateBaseData({ name: 'deliveryDate', value: new Date(date) })}
      />
    </LocalizationProvider>
  );
}

export default DeliveryDate;
