// @ts-nocheck

//  JUST BECAUSE OF WAREHOUSE_OLD
/* eslint-disable camelcase */
// Packages
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import dotenv from 'dotenv';

// Services
import { getCurrentCompany } from 'src/services/company';
import { getCurrentUser } from 'src/services/user';

// Contexts
import { SocketContextProvider, useSocket } from 'src/contexts/SocketContext';

// Theme
import theme from 'src/theme';

// Redux
import {
  signInUserReducer,
  signInCompanyReducer,
  setCurrentCompanyReducer,
  setCurrentUserReducer,
} from 'src/slices/authSlice';
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Global Components
import PrivateRoute from 'src/components/PrivateRoute';
import Snackbar from 'src/components/Snackbar';

// MUI 5 MIGRATION START
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

// Views
import {
  SignInUser,
  Staff,
  AssignmentCreate,
  AssignmentEdit,
  AssignmentDetails,
  AssignmentOverview,
  CustomerOverview,
  CustomerDetails,
  SignUpCompany,
  SignInCompany,
  CompanyChangeData,
  CompanyForgotPassword,
  CompanyResetPassword,
  PackingList,
  Invoice,
  Warehouse,
} from 'src/views';

dotenv.config();

const ConnectionStatus = () => {
  const { socket } = useSocket();
  const dispatch = useDispatch();
  const [onlineStatus, setOnlineStatus] = useState(true);
  const [dbStatus, setDbStatus] = useState('online');

  useEffect(() => {
    function changeStatus() {
      setOnlineStatus(navigator.onLine);
    }
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);
    return () => {
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('db-status', (status) => {
        setDbStatus(status);
      });
    }
  }, [socket]);

  if (dbStatus === 'offline') {
    dispatch(showSnackbarReducer({ message: 'Keine Verbindung zur Datenbank', severity: 'error', permanent: true }));
  }

  if (dbStatus === 'connecting') {
    dispatch(showSnackbarReducer({ message: 'Verbindungsversuch zur Datenbank', severity: 'error', permanent: true }));
  }

  if (!onlineStatus) {
    dispatch(showSnackbarReducer({ message: 'Keine Verbindung zum Internet', severity: 'error', permanent: true }));
  }

  if (dbStatus === 'online' && onlineStatus) {
    dispatch(showSnackbarReducer({ message: '', severity: '' }));
  }

  return null;
};

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setInitialAuthState();
  }, []); // eslint-disable-line

  const setInitialAuthState = async () => {
    const cookies = new Cookies();
    const jwtTokenUser = cookies.get('jwtTokenUser');
    const jwtTokenCompany = cookies.get('jwtTokenCompany');
    const isUser = jwtTokenCompany && jwtTokenUser;
    const isCompany = jwtTokenCompany && !jwtTokenUser;

    if (isUser) {
      await dispatch(signInUserReducer(jwtTokenUser));
      const foundUser = await getCurrentUser();
      await dispatch(setCurrentUserReducer(foundUser));
      const foundCompany = await getCurrentCompany();
      await dispatch(setCurrentCompanyReducer(foundCompany));
    }

    if (isCompany) {
      await dispatch(signInCompanyReducer(jwtTokenCompany));
      const foundCompany = await getCurrentCompany();
      await dispatch(setCurrentCompanyReducer(foundCompany));
    }

    setIsLoading(false);
  };

  return !isLoading ? (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SocketContextProvider>
          <BrowserRouter>
            <ConnectionStatus />
            <Snackbar />
            <Switch>
              <Route path="/" render={() => <Redirect to="/sign-up-company" />} exact />
              <Route path="/sign-in-user" component={SignInUser} exact />
              <Route path="/sign-in-company" component={SignInCompany} exact />
              <Route path="/sign-up-company" component={SignUpCompany} exact />
              <Route path="/company-forgot-password" component={CompanyForgotPassword} exact />
              <Route path="/company-reset-password/:token" component={CompanyResetPassword} exact />
              <PrivateRoute path="/assignment/create" component={AssignmentCreate} exact />
              <PrivateRoute
                path="/assignment/details/:assignmentType/:assignmentId"
                component={AssignmentDetails}
                exact
              />
              <PrivateRoute path="/assignment/edit/:assignmentType/:assignmentId" component={AssignmentEdit} exact />
              <PrivateRoute path="/assignment/overview" component={AssignmentOverview} exact />
              <PrivateRoute path="/customer/overview" component={CustomerOverview} exact />
              <PrivateRoute path="/customer/details/:customerId" component={CustomerDetails} exact />
              <PrivateRoute path="/staff" component={Staff} exact />
              <PrivateRoute path="/company-change-data" component={CompanyChangeData} exact />
              <PrivateRoute path="/packinglist" component={PackingList} exact />
              <PrivateRoute path="/invoice" component={Invoice} exact />
              <PrivateRoute path="/warehouse" component={Warehouse} exact />
            </Switch>
          </BrowserRouter>
        </SocketContextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  ) : null;
}

export default App;
