// @ts-nocheck

// Packages
import React from 'react';

// Views
import { App as AppLayout } from 'src/layouts';
import { default as AssignmentCreateView } from './_AssignmentCreate';

// Layouts

function AssignmentCreate() {
  return (
    <AppLayout>
      <AssignmentCreateView />
    </AppLayout>
  );
}

export default AssignmentCreate;
