// Packages
import React from 'react';

// Packages
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';

// MUI
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

interface Props {
  deliveryDate: string;
  handleUpdatePosition: (event: { target: { name: string; value: Date } }) => void;
}

function DeliveryDate({ deliveryDate, handleUpdatePosition }: Props): JSX.Element {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils} locale={deLocale}>
      <DatePicker
        label="Lieferdatum"
        value={deliveryDate}
        renderInput={(props) => <TextField {...props} />}
        onChange={(date) =>
          handleUpdatePosition({ target: { name: 'deliveryDate', value: (date && new Date(date)) || new Date() } })
        }
      />
    </LocalizationProvider>
  );
}

export default DeliveryDate;
