// @ts-nocheck

// Packages
import React, { useState } from 'react';
import Moment from 'react-moment';

// Local Components

// MUI
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

// Utils
import isEmpty from 'src/utils/isEmpty';
import VatRateDialog from './components/_VatRateDialog';

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
  editIcon: {
    color: theme.palette.yellow.main,
  },
  deleteIcon: {
    color: theme.palette.red.main,
  },
  cell: {
    borderBottom: 0,
  },
}));

function VatRates({ handleSubmit, companyData, setCompanyData, showLastItemMessage }) {
  const classes = useStyles();
  const [openVatRateDialog, setOpenVatRateDialog] = useState(false);
  const [editVatRate, setEditVatRate] = useState();

  function handleCreateVatRate(vatRateData) {
    const updatedCompanyData = {
      ...companyData,
      vatRates: companyData.vatRates ? [...companyData.vatRates, vatRateData] : [vatRateData],
    };

    const message = 'Neuer MwSt-Satz wurde erstellt';
    const severity = 'success';

    setCompanyData(updatedCompanyData);
    handleSubmit(updatedCompanyData, message, severity);
    setOpenVatRateDialog(false);
  }

  function handleUpdateVatRate(vatRateData, vatRateIndex) {
    const updatedCompanyData = {
      ...companyData,
      vatRates: [
        ...companyData.vatRates.slice(0, vatRateIndex),
        vatRateData,
        ...companyData.vatRates.slice(vatRateIndex + 1),
      ],
    };

    const message = 'MwSt-Satz wurde aktualisiert';
    const severity = 'success';

    setCompanyData(updatedCompanyData);
    handleSubmit(updatedCompanyData, message, severity);
    setOpenVatRateDialog(false);
  }

  function handleDeleteVatRate(vatRateData) {
    const isLastItem = companyData.vatRates.length < 2;

    if (isLastItem) {
      showLastItemMessage();
    } else {
      const index = companyData.vatRates.map((vatRate) => vatRate.date).indexOf(vatRateData.date);

      const updatedCompanyData = {
        ...companyData,
        vatRates: [...companyData.vatRates.slice(0, index), ...companyData.vatRates.slice(index + 1)],
      };

      const message = 'MWSt-Satz wurde gelöscht';
      const severity = 'info';

      setCompanyData(updatedCompanyData);
      handleSubmit(updatedCompanyData, message, severity);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            {!isEmpty(companyData.vatRates) && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>gültig ab</TableCell>
                    <TableCell>regulär</TableCell>
                    <TableCell>ermäßigt</TableCell>
                    <TableCell>
                      <Tooltip title="MwSt-Satz hinzufügen" placement="bottom-end" followCursor>
                        <Button
                          fullWidth
                          variant="contained"
                          className={classes.addButton}
                          onClick={() => {
                            setEditVatRate({});
                            setOpenVatRateDialog(true);
                          }}
                          size="large"
                        >
                          <AddCircleIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {companyData.vatRates.map((vatRate, index) => (
                    <TableRow hover key={index}>
                      <TableCell className={classes.cell}>
                        <Moment format="DD.MM.YYYY">{vatRate.date}</Moment>
                      </TableCell>
                      <TableCell className={classes.cell}>{vatRate.regular}%</TableCell>
                      <TableCell className={classes.cell}>{vatRate.reduced}%</TableCell>
                      <TableCell className={classes.cell}>
                        <Tooltip title="MwSt-Satz bearbeiten" placement="bottom-end" followCursor>
                          <IconButton
                            className={classes.editIcon}
                            onClick={() => {
                              setEditVatRate(vatRate);
                              setOpenVatRateDialog(true);
                            }}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="MwSt-Satz löschen" placement="bottom-end" followCursor>
                          <IconButton
                            onClick={() => handleDeleteVatRate(vatRate)}
                            size="large"
                            className={classes.deleteIcon}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          {openVatRateDialog && (
            <VatRateDialog
              openVatRateDialog={openVatRateDialog}
              setOpenVatRateDialog={setOpenVatRateDialog}
              handleCreateVatRate={handleCreateVatRate}
              editVatRate={editVatRate}
              handleUpdateVatRate={handleUpdateVatRate}
              companyData={companyData}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default VatRates;
