// @ts-nocheck
// Packages
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// Utils
import isEmpty from 'src/utils/isEmpty';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// Services
import { createPosition } from 'src/services/position';

function DeliveryConditionDialog({
  isDeliveryConditionDialogOpen,
  setIsDeliveryConditionDialogOpen,
  deliveryCondition,
  setDeliveryCondition,
  handleCreatePackingList,
  packingListPositions,
}) {
  const { deliveryTerms } = useSelector((state) => state.auth.company);
  const [indexDeliveryTerm, setIndexDeliveryTerm] = useState();
  const [uniqueOrders, setUniqueOrders] = useState([]);
  const [chosenOrder, setChosenOrder] = useState(null);

  useEffect(() => {
    setInitialDeliveryTerm();
    handleSetUniqueOrders();
    return () => setDeliveryCondition({});
  }, []); // eslint-disable-line

  function setInitialDeliveryTerm() {
    const orders = packingListPositions.map((position) => position.order);
    const deliveryConditions = orders.map((order) => order.deliveryTerm);
    const uniqueDeliveryConditions = _.uniqBy(deliveryConditions, 'incoterm');

    if (uniqueDeliveryConditions.length > 1) {
      setDeliveryCondition({ ...deliveryCondition, deliveryTerm: null });
    } else {
      const incoterm = uniqueDeliveryConditions.map((uniqueDeliveryConditions) => uniqueDeliveryConditions.incoterm)[0];

      const index = deliveryTerms.map((deliveryTerm) => deliveryTerm.incoterm).indexOf(incoterm);

      setDeliveryCondition({
        ...deliveryCondition,
        deliveryTerm: deliveryTerms[index],
      });
    }
  }

  function handleChangeDeliveryTerm(event) {
    const { name, value } = event.target;
    setDeliveryCondition({ [name]: value });
    setIndexDeliveryTerm(deliveryTerms.map((deliveryTerm) => deliveryTerm.incoterm).indexOf(value.incoterm));
  }

  function handleSetUniqueOrders() {
    const orders = packingListPositions.map((position) => position.order);
    const unique = _.uniqBy(orders, '_id');
    setUniqueOrders(unique);
    setChosenOrder(unique[0]);
  }

  function isFormDisabled() {
    const isDeliveryService = deliveryCondition.deliveryTerm && deliveryCondition.deliveryTerm.deliveryService;
    const areDifferentOrders = uniqueOrders.length > 1;

    return (
      (isDeliveryService &&
        (!deliveryCondition.deliveryCost ||
          !deliveryCondition.parcelServiceName ||
          !deliveryCondition.parcelTrackingNumber)) ||
      (isDeliveryService && areDifferentOrders && !chosenOrder) ||
      !deliveryCondition.deliveryTerm
    );
  }

  async function handleShippingCostsPositionOnOrder() {
    const positionData = {
      isDeliveryCost: true,
      description: 'Lieferkosten',
      quantity: 1,
      price: deliveryCondition.deliveryCost,
      vatRate: '19',
      unit: { name: 'Stück', shortName: 'Stk.' },
      order: chosenOrder._id,
      customer: chosenOrder.customer,
      deliveryCompleted: true,
    };

    const formData = new FormData();
    formData.append('positionData', JSON.stringify(positionData));

    const createdPosition = await createPosition(formData);

    return createdPosition;
  }

  return (
    <Dialog
      maxWidth="xs"
      open={isDeliveryConditionDialogOpen}
      onClose={() => {
        setIsDeliveryConditionDialogOpen(false);
        setDeliveryCondition({});
      }}
    >
      <DialogTitle id="form-dialog-title">Lieferbedingungen</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {!isEmpty(deliveryTerms) ? (
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Lieferbedingung</InputLabel>
                <Select
                  value={deliveryCondition.deliveryTerm}
                  name="deliveryTerm"
                  onChange={handleChangeDeliveryTerm}
                  label="Lieferbedingung"
                >
                  {deliveryTerms.map((deliveryTerm, index) => (
                    <MenuItem value={deliveryTerm} key={index}>
                      {deliveryTerm.meaning} ({deliveryTerm.incoterm})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Typography variant="caption" color="secondary">
              keine Lieferbedingungen hinterlegt
            </Typography>
          )}

          {deliveryCondition && deliveryCondition.deliveryTerm && deliveryCondition.deliveryTerm.deliveryService && (
            <>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Lieferkosten"
                  type="number"
                  value={deliveryCondition.deliveryCost || ''}
                  name="deliveryCost"
                  onChange={({ target }) =>
                    setDeliveryCondition({
                      ...deliveryCondition,
                      [target.name]: target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Paketdienst/Spedition"
                  type="text"
                  value={deliveryCondition.parcelServiceName || ''}
                  name="parcelServiceName"
                  onChange={({ target }) =>
                    setDeliveryCondition({
                      ...deliveryCondition,
                      [target.name]: target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Sendungs-Nummer"
                  type="text"
                  value={deliveryCondition.parcelTrackingNumber || ''}
                  name="parcelTrackingNumber"
                  onChange={({ target }) =>
                    setDeliveryCondition({
                      ...deliveryCondition,
                      [target.name]: target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              {uniqueOrders.length > 1 && (
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Auftrags-Zuordnung</InputLabel>
                    <Select
                      value={chosenOrder || ''}
                      onChange={(event) => {
                        setChosenOrder(event.target.value);
                      }}
                      label="Auftrags-Zuordnung"
                    >
                      {uniqueOrders.map((order, index) => (
                        <MenuItem value={order} key={index}>
                          {order.number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Button
              disabled={isFormDisabled()}
              onClick={async () => {
                try {
                  const isDeliveryService = deliveryTerms[indexDeliveryTerm || 0].deliveryService;

                  if (isDeliveryService) {
                    const { data: createdShippingCostsPosition } = await handleShippingCostsPositionOnOrder();
                    await handleCreatePackingList(createdShippingCostsPosition);
                  } else {
                    handleCreatePackingList();
                  }
                } catch (error) {
                  if (error) throw error;
                }
              }}
              variant="contained"
              color="secondary"
              fullWidth
            >
              Übernehmen
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default DeliveryConditionDialog;
