// @ts-nocheck
// Packages
import React from 'react';
import { useState } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';

// Services
import { checkDuplicateCustomerNumber } from 'src/services/assignment';

// MUI
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

// Local Components
import OrderLettersDialog from './components/OrderLettersDialog';

const useStyles = makeStyles({
  button: {
    minHeight: '54px',
    minWidth: '0px',
    maxWidth: '100%',
  },
  grid: {
    maxWidth: '100%',
  },
});

interface Props {
  handleUpdateBaseData: (data: { name: string; value: any }) => void;
  offerOrOrder: 'Angebot' | 'Auftrag';
}

function RequestOrderNumber({ handleUpdateBaseData, offerOrOrder }: Props) {
  const classes = useStyles();
  const isOffer: boolean = window.location.pathname.includes('offer');
  const isOrder: boolean = window.location.pathname.includes('order');

  const [duplicateError, setDuplicateError] = useState('');
  const { customersNoForOffer, customersNoForOrder, customer, numberLetter } = useSelector(
    (state: RootStateOrAny) => state.assignment.assignmentForm,
  );
  const [openDialog, setOpenDialog] = useState(false);

  async function checkForDuplicateCustomerNumber(number: string) {
    try {
      const isDuplicate = await checkDuplicateCustomerNumber({
        type: isOffer ? 'offer' : 'order',
        customerId: customer._id,
        customersNo: number,
      });

      if (number.length && isDuplicate && isOffer) {
        setDuplicateError('Angebots-Nr. bereits vorhanden');
      } else if (number.length && isDuplicate && isOrder) {
        setDuplicateError('Bestell-Nr. bereits vorhanden');
      } else {
        setDuplicateError('');
      }
    } catch (error) {
      if (error) throw error;
    }
  }

  return (
    <>
      {offerOrOrder === 'Angebot' && (
        <>
          <TextField
            variant="outlined"
            label="Anfrage-Nr."
            type="text"
            value={customersNoForOffer}
            name="customersNoForOffer"
            onChange={({ target }) => {
              checkForDuplicateCustomerNumber(target.value);
              handleUpdateBaseData({ name: target.name, value: target.value });
            }}
            fullWidth
          />
          {duplicateError && <FormHelperText style={{ color: 'red' }}>{duplicateError}</FormHelperText>}
        </>
      )}
      {offerOrOrder === 'Auftrag' && (
        <Grid container>
          <Grid item mobile={9}>
            <TextField
              variant="outlined"
              label="Bestell-Nr."
              type="text"
              value={customersNoForOrder}
              name="customersNoForOrder"
              onChange={({ target }) => {
                checkForDuplicateCustomerNumber(target.value);
                handleUpdateBaseData({ name: target.name, value: target.value });
              }}
              fullWidth
            />
            {duplicateError && <FormHelperText style={{ color: 'red' }}>{duplicateError}</FormHelperText>}
          </Grid>
          <Grid item mobile={3}>
            <Tooltip title={`Buchstabe ${numberLetter ? 'ändern' : 'hinzufügen'}`} placement="bottom-end" followCursor>
              <Button
                onClick={() => setOpenDialog(true)}
                className={classes.button}
                size="large"
                variant="contained"
                color="green"
                fullWidth
              >
                {numberLetter ? <Typography variant="h5">{numberLetter}</Typography> : <AddIcon />}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      )}

      <OrderLettersDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleUpdateBaseData={handleUpdateBaseData}
      />
    </>
  );
}

export default RequestOrderNumber;
