// Packages
import axios from 'axios';

// Interfaces
import { User as UserData } from 'src/services/interfaces/user.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/auth`;

export function companyForgotPassword(data: { email: string }) {
  return axios
    .post(`${apiURI}/company-forgot-password`, data)
    .then((response) => response.data as { alertMessage: string });
}

export function companyResetPassword(data: { token: string; password: string; password2: string }) {
  return axios
    .post(`${apiURI}/company-reset-password`, data)
    .then((response) => response.data as { alertMessage: string });
}

export function signInUser(data: { _id: string; password: string; passwordStatus: string }) {
  return axios.post(`${apiURI}/sign-in-user`, data).then((response) => response.data as string);
}

export function signUpCompany(data: {
  email: string;
  companyName: string;
  firstName: string;
  lastName: string;
  password: string;
}) {
  return axios.post(`${apiURI}/sign-up-company`, data).then((response) => response.data as string);
}

export function signInCompany(data: { email: string; password: string }) {
  return axios.post(`${apiURI}/sign-in-company`, data).then((response) => response.data as string);
}

export function register(data: {
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  personnelNumber: number;
  role: string;
}) {
  return axios.post(`${apiURI}/register`, data).then((response) => response.data as UserData);
}
