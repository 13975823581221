// Packages
import axios from 'axios';

// Interfaces
import { Assignment as AssignmentData } from 'src/services/interfaces/assignment.inteface';

const apiURI = `${process.env.REACT_APP_API_URI}/assignment`;

export function getFilteredAssignment(query: string) {
  return axios.get(`${apiURI}/get-filtered-assignments${query ? `?${query}` : ''}`).then(
    (response) =>
      response.data as {
        from: number;
        to: number;
        total: number;
        page: number;
        pages: number;
        size: number;
        result: AssignmentData[];
        limit: number;
      },
  );
}

export function checkDuplicateCustomerNumber(data: { type: string; customerId: string; customersNo: string }) {
  return axios.post(`${apiURI}/check-duplicate-customer-number`, data).then((response) => response.data as boolean);
}
