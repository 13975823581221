// Packages
import React from 'react';

// MUI
import TextField from '@mui/material/TextField';

interface Props {
  text: string;
  handleUpdatePosition: (data: any) => void;
}

function Text({ text, handleUpdatePosition }: Props): JSX.Element {
  return (
    <TextField
      multiline
      rows={4}
      variant="outlined"
      label="Text"
      value={text || ''}
      name="text"
      onChange={handleUpdatePosition}
      fullWidth
    />
  );
}

export default Text;
