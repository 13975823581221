// Packages
import React from 'react';

// MUI
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

interface Props {
  itemText: string;
  itemIcon: JSX.Element;
}

function DrawerListItem({ itemText, itemIcon }: Props): JSX.Element {
  return (
    <ListItem button key={itemText}>
      <ListItemIcon>{itemIcon}</ListItemIcon>
      <ListItemText primary={itemText} />
    </ListItem>
  );
}

export default DrawerListItem;
