// Packages
import React, { useState, useEffect } from 'react';

// MUI
import Grid from '@mui/material/Grid';

// Services
import { getWarehousePlacesByCompanyId } from 'src/services/warehousePlace';
import { getOutsourcedWarehouseItems } from 'src/services/warehouseItem';

// Interfaces
import { WarehousePlace } from 'src/services/interfaces/warehousePlace.interface';
import { WarehouseItem } from 'src/services/interfaces/warehouseItem.interface';

// Locale Components
import { WarehouseItemFind, WarehouseItemForm, WarehouseItemsOutsourced, Categories, NumPad } from './components';

export type WarehouseCategoryData = 'round' | 'tube' | 'hexagon' | 'flat' | 'article' | 'diverse' | '';

function Warehouse(): JSX.Element {
  const [outsourcedWarehouseItems, setOutsourcedWarehouseItems] = useState<WarehouseItem[]>([]);
  const [warehousePlaces, setWarehousePlaces] = useState<WarehousePlace[]>([]);
  const [warehouseItems, setWarehouseItems] = useState<WarehouseItem[]>([]);
  const [tabValue, setTabValue] = useState(0);
  const [category, setCategory] = useState('' as WarehouseCategoryData);
  const [textfieldName, setTextfieldName] = useState('');
  const [numPadOpen, setNumPadOpen] = useState(false);
  const [outscourcedListOpen, setOutscourcedListOpen] = useState(false);
  const [warehouseItemToStore, setWarehouseItemToStore] = useState({
    category: '',
    outerDiameter: '',
    innerDiameter: '',
    length: '',
    width: '',
    height: '',
    material: {},
    description: '',
    quantity: '',
    articleNo: '',
    finished: true,
    place: {},
    space: {},
  } as unknown as WarehouseItem);
  const [warehouseItemToFind, setWarehouseItemToFind] = useState({
    category: '',
    outerDiameter: '',
    innerDiameter: '',
    length: '',
    width: '',
    height: '',
    material: '',
    description: '',
    quantity: '',
    articleNo: '',
    place: {},
    space: {},
    certificate: false,
  } as unknown as WarehouseItem);

  useEffect(() => {
    handleGetWarehousePlaces();
    handleGetOutsourcedWarehouseItems();
  }, []);

  useEffect(() => {
    setCategory('');
    handleResetWarehouseItemToStore();
    handleResetWarehouseItemToFind();
  }, [tabValue]);

  async function handleGetWarehousePlaces() {
    const foundWarehousePlaces = await getWarehousePlacesByCompanyId();
    setWarehousePlaces(foundWarehousePlaces);
  }

  async function handleGetOutsourcedWarehouseItems() {
    const foundOutsourcedWarehouseItems: WarehouseItem[] = await getOutsourcedWarehouseItems();
    setOutsourcedWarehouseItems(foundOutsourcedWarehouseItems);
  }

  const handleChangeWarehouseItemToStore = (data: { name: any; value: any }) => {
    const { name, value } = data;
    setWarehouseItemToStore({ ...warehouseItemToStore, [name]: value });
    name === 'category' && setNumPadOpen(false);
  };

  const handleChangeWarehouseItemToFind = (data: { name: any; value: any }) => {
    const { name, value } = data;
    setWarehouseItemToFind({ ...warehouseItemToFind, [name]: value });
  };

  function handleResetWarehouseItemToStore() {
    setWarehouseItemToStore({
      ...warehouseItemToStore,
      finished: true,
      category: '',
      outerDiameter: '',
      innerDiameter: '',
      length: '',
      width: '',
      height: '',
      material: {},
      description: '',
      quantity: '',
      articleNo: '',
      place: '',
      space: '',
      certificate: false,
    } as unknown as WarehouseItem);
    setNumPadOpen(false);
  }

  function handleResetWarehouseItemToFind() {
    setWarehouseItemToFind({
      category: '',
      outerDiameter: '',
      innerDiameter: '',
      length: '',
      width: '',
      height: '',
      material: '',
      description: '',
      quantity: '',
      articleNo: '',
      place: '',
      space: '',
      certificate: false,
    } as unknown as WarehouseItem);
    setNumPadOpen(false);
  }

  function handleSetCategory(category: WarehouseCategoryData) {
    setCategory(category);
    outscourcedListOpen && setOutscourcedListOpen(false);
  }

  function handleOpenNumPad(textfield: string) {
    setTextfieldName(textfield);
    setNumPadOpen(true);
  }

  function handleRestoreWarehouseItem(item: WarehouseItem) {
    setWarehouseItemToStore(item);
    setCategory(item.category as WarehouseCategoryData);
    setOutscourcedListOpen(false);
  }

  const categories: { value: string; name: string }[] = [
    { value: 'round', name: 'Rohmaterial - RUND' },
    { value: 'tube', name: 'Rohmaterial - ROHR' },
    { value: 'hexagon', name: 'Rohmaterial - 6-KANT(SW)' },
    { value: 'flat', name: 'Rohmaterial - FLACH' },
    { value: 'article', name: 'ARTIKEL' },
    { value: 'diverse', name: 'Diverses' },
  ];

  return (
    <>
      <Grid container spacing={2} sx={{ maxWidth: { mobile: 'xs', desktop: '100%' } }}>
        <>
          <Grid item xs={12}>
            <Categories
              tabValue={tabValue}
              setTabValue={setTabValue}
              category={category}
              handleSetCategory={handleSetCategory}
              handleResetWarehouseItemToStore={handleResetWarehouseItemToStore}
              handleResetWarehouseItemToFind={handleResetWarehouseItemToFind}
              warehouseItemToStore={warehouseItemToStore}
            />
          </Grid>
          {tabValue === 0 && (
            <>
              {/* @ts-ignore */}
              <Grid item mobile={12} desktop={6}>
                <WarehouseItemForm
                  handleGetOutsourcedWarehouseItems={handleGetOutsourcedWarehouseItems}
                  warehousePlaces={warehousePlaces}
                  category={category}
                  warehouseItemToStore={warehouseItemToStore}
                  setWarehouseItemToStore={setWarehouseItemToStore}
                  handleChangeWarehouseItemToStore={handleChangeWarehouseItemToStore}
                  handleOpenNumPad={handleOpenNumPad}
                  setNumPadOpen={setNumPadOpen}
                  handleSetCategory={handleSetCategory}
                  tabValue={undefined}
                  handleResetWarehouseItemToStore={undefined}
                  handleResetWarehouseItemToFind={undefined}
                  setTabValue={undefined}
                  categories={categories}
                />
              </Grid>
              <Grid item xs={6}>
                {numPadOpen && category && (
                  <NumPad
                    textfieldName={textfieldName}
                    tabValue={tabValue}
                    warehouseItemToStore={warehouseItemToStore}
                    handleChangeWarehouseItemToStore={handleChangeWarehouseItemToStore}
                    warehouseItemToFind={warehouseItemToFind}
                    handleChangeWarehouseItemToFind={handleChangeWarehouseItemToFind}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <WarehouseItemsOutsourced
                  handleRestoreWarehouseItem={handleRestoreWarehouseItem}
                  outsourcedWarehouseItems={outsourcedWarehouseItems}
                  outscourcedListOpen={outscourcedListOpen}
                  setOutscourcedListOpen={setOutscourcedListOpen}
                  setWarehouseItemToStore={setWarehouseItemToStore}
                  setNumPadOpen={setNumPadOpen}
                  handleSetCategory={handleSetCategory}
                />
              </Grid>
            </>
          )}
          {tabValue === 1 && (
            <>
              {/* @ts-ignore */}
              <Grid item mobile={12} desktop={6}>
                <WarehouseItemFind
                  handleGetOutsourcedWarehouseItems={handleGetOutsourcedWarehouseItems}
                  warehousePlaces={warehousePlaces}
                  category={category}
                  warehouseItems={warehouseItems}
                  setWarehouseItems={setWarehouseItems}
                  warehouseItemToFind={warehouseItemToFind}
                  handleChangeWarehouseItemToFind={handleChangeWarehouseItemToFind}
                  handleOpenNumPad={handleOpenNumPad}
                  setNumPadOpen={setNumPadOpen}
                  setWarehouseItemToFind={setWarehouseItemToFind}
                  setTabValue={setTabValue}
                  setOutscourcedListOpen={setOutscourcedListOpen}
                />
              </Grid>
              <Grid item xs={6}>
                {numPadOpen && (
                  <NumPad
                    textfieldName={textfieldName}
                    tabValue={tabValue}
                    warehouseItemToStore={warehouseItemToStore}
                    handleChangeWarehouseItemToStore={handleChangeWarehouseItemToStore}
                    warehouseItemToFind={warehouseItemToFind}
                    handleChangeWarehouseItemToFind={handleChangeWarehouseItemToFind}
                  />
                )}
              </Grid>
            </>
          )}
        </>
      </Grid>
    </>
  );
}

export default Warehouse;
