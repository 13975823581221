// Packages
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// MUI
import { makeStyles } from '@mui/styles';
import MuiLink from '@mui/material/Link';

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

interface Props {
  children: React.ReactNode;
  to: string;
}

function Link({ children, to }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <MuiLink to={to} component={RouterLink} color="textPrimary" className={classes.link}>
      {children}
    </MuiLink>
  );
}

export default Link;
