// Packages
import axios from 'axios';

// Interfaces
import { Position as PositionData } from './interfaces/position.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/position`;

export function createPosition(data: FormData | PositionData) {
  return axios.post(`${apiURI}/create-position`, data).then((response) => response.data as PositionData);
}

export function updatePosition(data: FormData) {
  return axios.post(`${apiURI}/update-position`, data).then((response) => response.data as PositionData);
}

export function deletePosition(data: { positionId: string; assignmentId: string; assignmentType: string }) {
  return axios.post(`${apiURI}/delete-position`, data).then((response) => response.data as PositionData[]);
}

export function getUndeliveredPositionsByOrderId(data: { orderId: string }) {
  return axios
    .post(`${apiURI}/get-undelivered-positions-by-order-id`, data)
    .then((response) => response.data as PositionData[]);
}

export function getPositionsByCustomerAndDrawNo(data: { customerId: string; drawNo: string }) {
  return axios
    .post(`${apiURI}/get-positions-by-customer-and-drawno`, data)
    .then((response) => response.data as PositionData[]);
}
