// @ts-nocheck

//  Packages
import React, { useState } from 'react';

// MUI
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import PartialDeliveryDialog from '../../../PartialDeliveryDialog';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.green.main,
    borderRadius: '10px',
    width: '40px',
    '&:hover': {
      backgroundColor: theme.palette.lightGreen.main,
    },
  },
  typoBold: { fontWeight: 600 },
}));

function Number({ position, handleAddPositionToPackingList }) {
  const classes = useStyles();
  const [isPartialDeliveryDialogOpen, setIsPartialDeliveryDialogOpen] = useState(false);

  return (
    <>
      <Grid item mobile={3} desktop={3}>
        <Grid container justifyContent="center" alignItems="center">
          <Tooltip
            title={`${position.quantity - position.quantityDelivered} ${position.unit.shortName}`}
            placement="bottom-end"
            followCursor
          >
            <Button variant="contained" className={classes.button} onClick={() => setIsPartialDeliveryDialogOpen(true)}>
              <Typography variant="h4" className={classes.typoBold}>
                {position.number}
              </Typography>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      {isPartialDeliveryDialogOpen && (
        <PartialDeliveryDialog
          type="add"
          position={position}
          handleAddPositionToPackingList={handleAddPositionToPackingList}
          isPartialDeliveryDialogOpen={isPartialDeliveryDialogOpen}
          setIsPartialDeliveryDialogOpen={setIsPartialDeliveryDialogOpen}
          handleUpdatePositionOnPackingList={undefined}
        />
      )}
    </>
  );
}

export default Number;
