// Packages
import React, { ReactNode } from 'react';

// MUI
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
  root: { height: '100vh' },
});

interface Props {
  children: ReactNode;
}

function CompanyAuth({ children }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root}>
      <Grid item xs={10} md={6} lg={4}>
        {children}
      </Grid>
    </Grid>
  );
}

export default CompanyAuth;
