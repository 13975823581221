// @ts-nocheck

// Packages
import React from 'react';
import { useDispatch } from 'react-redux';

// Local Components

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Services
import { updateCustomer } from 'src/services/customer';
import List from './components/List';

function Addresses({ customer, setCustomer }) {
  const dispatch = useDispatch();

  async function handleCreateAddress(addressData) {
    try {
      const data = {
        ...customer,
        addresses: [...customer.addresses, addressData],
      };
      const updatedCustomer = await updateCustomer(data);
      setCustomer(updatedCustomer);

      dispatch(
        showSnackbarReducer({
          message: 'Adresse wurde erstellt',
          severity: 'success',
        }),
      );
    } catch (error) {
      if (error) throw error;
    }
  }

  async function handleUpdateAddress(addressData) {
    try {
      const index = customer.addresses.map((address) => address._id).indexOf(addressData._id);

      const data = {
        ...customer,
        addresses: [...customer.addresses.slice(0, index), addressData, ...customer.addresses.slice(index + 1)],
      };

      const updatedCustomer = await updateCustomer(data);
      setCustomer(updatedCustomer);

      dispatch(
        showSnackbarReducer({
          message: 'Adresse wurde aktualisiert',
          severity: 'success',
        }),
      );
    } catch (error) {
      if (error) throw error;
    }
  }

  return (
    <>
      <List customer={customer} handleCreateAddress={handleCreateAddress} handleUpdateAddress={handleUpdateAddress} />
    </>
  );
}

export default Addresses;
