// @ts-nocheck

// Packages
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Utils
import isEmpty from 'src/utils/isEmpty';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  abortButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  greenButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

function CurrencyDialog({
  editCurrency,
  openCurrencyDialog,
  setOpenCurrencyDialog,
  handleCreateCurrency,
  handleUpdateCurrency,
  companyData,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [currencyData, setCurrencyData] = useState(editCurrency.name ? editCurrency : {});

  useEffect(() => {
    setCurrencyData(editCurrency.name ? editCurrency : {});
    return () => setCurrencyData({});
  }, []); //eslint-disable-line

  function handleCloseCurrencyDialog() {
    setOpenCurrencyDialog(false);
  }

  function handleChangeCurrencyData(event) {
    const { name, value } = event.target;
    setCurrencyData({ ...currencyData, [name]: value });
  }

  function handleSubmit() {
    const isDuplicateCurrencyName = companyData.currencies
      ? companyData.currencies.map((currency) => currency.name).includes(currencyData.name)
      : false;

    const currencyIndex = companyData.currencies
      ? companyData.currencies.map((currency) => currency.name).indexOf(editCurrency.name)
      : -1;

    if (!isDuplicateCurrencyName || editCurrency.name === currencyData.name) {
      isEmpty(editCurrency) ? handleCreateCurrency(currencyData) : handleUpdateCurrency(currencyData, currencyIndex);

      handleCloseCurrencyDialog();
    } else {
      dispatch(
        showSnackbarReducer({
          message: 'diese Währung ist bereits vorhanden',
          severity: 'error',
        }),
      );
    }
  }

  return (
    <Dialog maxWidth="md" open={openCurrencyDialog} onClose={() => handleCloseCurrencyDialog()}>
      <Box textAlign="center">
        <DialogTitle className={classes.dialogTitle}>
          Währung {editCurrency.name ? 'bearbeiten' : 'hinzufügen'}
        </DialogTitle>
      </Box>
      <DialogContent>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} md={7}>
            <TextField
              variant="outlined"
              label="Währung"
              type="text"
              value={currencyData.name || ''}
              name="name"
              fullWidth
              onChange={handleChangeCurrencyData}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              variant="outlined"
              label="Symbol"
              type="text"
              value={currencyData.symbol || ''}
              name="symbol"
              fullWidth
              onChange={handleChangeCurrencyData}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item mobile={6}>
          <Button
            fullWidth
            variant="contained"
            className={classes.abortButton}
            onClick={() => handleCloseCurrencyDialog()}
          >
            Abbrechen
          </Button>
        </Grid>
        <Grid item mobile={6}>
          <Button
            fullWidth
            variant="contained"
            className={classes.greenButton}
            onClick={handleSubmit}
            disabled={
              isEmpty(currencyData.name) ||
              (editCurrency.name === currencyData.name && editCurrency.symbol === currencyData.symbol)
            }
          >
            {editCurrency.name ? 'Aktualisieren' : 'Erstellen'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default CurrencyDialog;
