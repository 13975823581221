// @ts-nocheck

// Packages
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Utils
import isEmpty from 'src/utils/isEmpty';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  abortButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  greenButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

function PaymentTermDialog({
  editPaymentTerm,
  openPaymentTermDialog,
  setOpenPaymentTermDialog,
  handleCreatePaymentTerm,
  handleUpdatePaymentTerm,
  companyData,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [paymentTermData, setPaymentTermData] = useState(editPaymentTerm.name ? editPaymentTerm : {});

  useEffect(() => {
    setPaymentTermData(editPaymentTerm.name ? editPaymentTerm : {});
    return () => setPaymentTermData({});
  }, []); //eslint-disable-line

  function handleClosePaymentTermDialog() {
    setOpenPaymentTermDialog(false);
  }

  function handleChangePaymentTermData(event) {
    const { name, value } = event.target;
    setPaymentTermData({ ...paymentTermData, [name]: value });
  }

  function handleSubmit() {
    const isDuplicatePaymentTermName = companyData.paymentTerms
      ? companyData.paymentTerms.map((paymentTerm) => paymentTerm.name).includes(paymentTermData.name)
      : false;

    const paymentTermIndex = companyData.paymentTerms
      ? companyData.paymentTerms.map((paymentTerm) => paymentTerm.name).indexOf(editPaymentTerm.name)
      : -1;

    if (!isDuplicatePaymentTermName) {
      isEmpty(editPaymentTerm)
        ? handleCreatePaymentTerm(paymentTermData)
        : handleUpdatePaymentTerm(paymentTermData, paymentTermIndex);

      handleClosePaymentTermDialog();
    } else {
      dispatch(
        showSnackbarReducer({
          message: 'diese Zahlungsbedingung ist bereits vorhanden',
          severity: 'error',
        }),
      );
    }
  }

  return (
    <Dialog maxWidth="md" open={openPaymentTermDialog} onClose={() => handleClosePaymentTermDialog()}>
      <Box textAlign="center">
        <DialogTitle className={classes.dialogTitle}>
          Zahlungsbedingung {editPaymentTerm.name ? 'bearbeiten' : 'hinzufügen'}
        </DialogTitle>
      </Box>
      <DialogContent>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              variant="outlined"
              label="Name"
              type="text"
              value={paymentTermData.name || ''}
              name="name"
              fullWidth
              onChange={handleChangePaymentTermData}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item mobile={6}>
          <Button
            fullWidth
            variant="contained"
            className={classes.abortButton}
            onClick={() => handleClosePaymentTermDialog()}
          >
            Abbrechen
          </Button>
        </Grid>

        <Grid item mobile={6}>
          <Button
            fullWidth
            onClick={handleSubmit}
            variant="contained"
            className={classes.greenButton}
            disabled={isEmpty(paymentTermData.name) || editPaymentTerm.name === paymentTermData.name}
          >
            {editPaymentTerm.name ? 'Aktualisieren' : 'Erstellen'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentTermDialog;
