// @ts-nocheck
// Packages
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { createPdf } from 'src/services/pdf';

// React-Icons
import { MdOutlineDeleteForever } from 'react-icons/md';

// Global Components
import Pdf from 'src/components/Pdf';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Services
import { getCustomersByCompanyId } from 'src/services/customer';
import { getUndeliveredOrdersByCustomerId } from 'src/services/order';
import { getUndeliveredPositionsByOrderId } from 'src/services/position';
import { createPackingList } from 'src/services/packingList';

// Utils
import isEmpty from 'src/utils/isEmpty';

// MUI
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

// Local Components
import {
  UndeliveredOrders,
  UndeliveredPositions,
  PackingListPositions,
  DeliveryConditionDialog,
  ResetDialog,
} from './components';

const useStyles = makeStyles({
  button: {
    minHeight: '54px',
  },
  customer: {
    backgroundColor: 'white',
  },
});

function PackingList() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState();
  const [undeliveredOrders, setUndeliveredOrders] = useState([]);
  const [undeliveredPositions, setUndeliveredPositions] = useState([]);
  const [packingListPositions, setPackingListPositions] = useState([]);
  const [isDeliveryConditionDialogOpen, setIsDeliveryConditionDialogOpen] = useState(false);
  const [resetDialogOptions, setResetDialogOptions] = useState({ isOpen: false });
  const [deliveryCondition, setDeliveryCondition] = useState({});
  const [showIcons, setShowIcons] = useState(false);

  useEffect(() => {
    getInitialData();
  }, []); // eslint-disable-line

  async function getInitialData() {
    const customers = await getCustomersByCompanyId();
    setCustomers(customers);
  }

  function handleChangeCustomer(changedCustomer) {
    setCustomer(changedCustomer);
    handleGetUndeliveredOrders(changedCustomer);
    setUndeliveredPositions([]);
    setPackingListPositions([]);
  }

  async function handleGetUndeliveredOrders(changedCustomer) {
    const orders = await getUndeliveredOrdersByCustomerId({
      customerId: changedCustomer._id,
    });

    setUndeliveredOrders(orders);
  }

  async function handleGetUndeliveredPositions(orderId) {
    const positions = await getUndeliveredPositionsByOrderId({
      orderId,
    });
    setUndeliveredPositions(positions);
  }

  function handleAddPositionToPackingList(position, quantityAdded) {
    function isDeliveryCompleted() {
      return position.quantity === position.quantityDelivered + quantityAdded;
    }

    setPackingListPositions([
      ...packingListPositions,
      {
        ...position,
        quantityAdded,
        deliveryCompleted: isDeliveryCompleted(),
      },
    ]);
  }

  function handleUpdatePositionOnPackingList(position, quantityAdded) {
    const index = packingListPositions.map((position) => position._id).indexOf(position._id);

    function isDeliveryCompleted() {
      return position.quantity === position.quantityDelivered + quantityAdded;
    }

    setPackingListPositions([
      ...packingListPositions.slice(0, index),
      {
        ...position,
        quantityAdded,
        deliveryCompleted: isDeliveryCompleted(),
      },
      ...packingListPositions.slice(index + 1),
    ]);
  }

  function handleDeletePositionOnPackingList(_id) {
    const index = packingListPositions.map((position) => position._id).indexOf(_id);
    setPackingListPositions([...packingListPositions.slice(0, index), ...packingListPositions.slice(index + 1)]);
  }

  async function handleCreatePackingList(shippingCostsPosition) {
    try {
      const { data: createdPackingList } = await createPackingList({
        customer: customer._id,
        positions: shippingCostsPosition
          ? [
              ...packingListPositions,
              {
                ...shippingCostsPosition,
                quantityAdded: 1,
                deliveryCompleted: true,
              },
            ]
          : packingListPositions,
        deliveryCondition,
      });

      await handleCreatePackingListPdf(createdPackingList);
      dispatch(
        showSnackbarReducer({
          message: 'Lieferschein erstellt',
          severity: 'success',
        }),
      );
      history.push(`/assignment/details/packinglist/${createdPackingList._id}`);
    } catch (error) {
      if (error) throw error;
    }
  }

  async function handleCreatePackingListPdf(createdPackingList) {
    try {
      const { company, number, customer, positions, _id, dateCreated, deliveryCondition } = createdPackingList;

      const blob = await pdf(
        <Pdf
          company={company}
          number={number}
          customer={customer}
          positions={positions}
          deliveryDate={dateCreated}
          deliveryTerm={deliveryCondition.deliveryTerm.incoterm}
          isPackingList
          deliveryCondition={deliveryCondition}
          isOffer={undefined}
          isOrder={undefined}
          isInvoice={undefined}
          revision={undefined}
          purchaser={undefined}
          paymentTerm={undefined}
          customersNoForOffer={undefined}
          customersNoForOrder={undefined}
        />,
      ).toBlob();

      const filename = `Lieferschein-${number}.pdf`;

      const file = await new File([blob], filename, {
        lastModified: new Date().getTime(),
      });

      const formData = new FormData();
      formData.append('pdf', file);
      formData.append('packingList', _id);
      await createPdf(formData);
    } catch (error) {
      if (error) throw error;
    }
  }

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between" alignItems="stretch">
        <Grid item mobile={12} desktop={6}>
          <Autocomplete
            className={classes.customer}
            value={customer || null}
            disableClearable
            clearText="Enfernen"
            openText="Oeffnen"
            onChange={(_, changedCustomer) => {
              if (!customer) {
                handleChangeCustomer(changedCustomer);
              } else {
                setResetDialogOptions({ isOpen: true, customer: changedCustomer });
              }
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={(option) => `${option.name} (${option.nameShort})`}
            options={customers}
            renderInput={(params) => <TextField {...params} label="Kunde" variant="outlined" />}
          />
        </Grid>
        <Grid item mobile={6} desktop={3}>
          <Button
            className={classes.button}
            fullWidth
            variant="contained"
            color="red"
            disabled={isEmpty(packingListPositions)}
            onClick={() => setResetDialogOptions({ isOpen: true, customer: '' })}
          >
            <MdOutlineDeleteForever fontSize="1.5rem" color="white" />
            Lieferschein leeren
          </Button>
        </Grid>
        <Grid item mobile={6} desktop={3}>
          <Button
            className={classes.button}
            fullWidth
            variant="contained"
            color="green"
            disabled={isEmpty(packingListPositions)}
            onClick={() => setIsDeliveryConditionDialogOpen(true)}
            startIcon={<CheckIcon />}
          >
            Lieferschein erstellen
          </Button>
        </Grid>

        <Grid item mobile={12} desktop={showIcons ? 3 : 1.5}>
          {!isEmpty(undeliveredOrders) && (
            <UndeliveredOrders
              undeliveredOrders={undeliveredOrders}
              handleGetUndeliveredPositions={handleGetUndeliveredPositions}
              showIcons={showIcons}
              setShowIcons={setShowIcons}
            />
          )}
        </Grid>

        <Grid item mobile={12} desktop={showIcons ? 3 : 4.5}>
          {!isEmpty(undeliveredPositions) && (
            <UndeliveredPositions
              undeliveredPositions={undeliveredPositions}
              packingListPositions={packingListPositions}
              handleAddPositionToPackingList={handleAddPositionToPackingList}
              showIcons={showIcons}
              setShowIcons={setShowIcons}
            />
          )}
        </Grid>

        <Grid item mobile={12} desktop={6}>
          {!isEmpty(packingListPositions) && (
            <PackingListPositions
              packingListPositions={packingListPositions}
              handleUpdatePositionOnPackingList={handleUpdatePositionOnPackingList}
              handleDeletePositionOnPackingList={handleDeletePositionOnPackingList}
            />
          )}
        </Grid>
      </Grid>

      {isDeliveryConditionDialogOpen && (
        <DeliveryConditionDialog
          isDeliveryConditionDialogOpen={isDeliveryConditionDialogOpen}
          setIsDeliveryConditionDialogOpen={setIsDeliveryConditionDialogOpen}
          deliveryCondition={deliveryCondition}
          setDeliveryCondition={setDeliveryCondition}
          handleCreatePackingList={handleCreatePackingList}
          packingListPositions={packingListPositions}
        />
      )}

      {resetDialogOptions.isOpen && (
        <ResetDialog
          handleChangeCustomer={handleChangeCustomer}
          resetDialogOptions={resetDialogOptions}
          setResetDialogOptions={setResetDialogOptions}
        />
      )}
    </>
  );
}

export default PackingList;
