// @ts-nocheck

// Packages
import React, { useState } from 'react';

// Local Components

// MUI
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

// Utils
import isEmpty from 'src/utils/isEmpty';
import SalutationDialog from './components/_SalutationDialog';

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
  editIcon: {
    color: theme.palette.yellow.main,
  },
  deleteIcon: {
    color: theme.palette.red.main,
  },
  cell: {
    borderBottom: 0,
  },
}));

function Salutations({ handleSubmit, companyData, showLastItemMessage }) {
  const classes = useStyles();
  const [openSalutationDialog, setOpenSalutationDialog] = useState(false);
  const [editSalutation, setEditSalutation] = useState();

  function handleCreateSalutation(salutationData) {
    const updatedCompanyData = {
      ...companyData,
      salutations: [...companyData.salutations, salutationData],
    };

    const message = 'Neue Anrede wurde erstellt';
    const severity = 'success';

    handleSubmit(updatedCompanyData, message, severity);
  }

  function handleUpdateSalutation(salutationData) {
    const index = companyData.salutations.map((salutation) => salutation.id).indexOf(salutationData.id);

    const updatedCompanyData = {
      ...companyData,
      salutations: [
        ...companyData.salutations.slice(0, index),
        salutationData,
        ...companyData.salutations.slice(index + 1),
      ],
    };

    const message = 'Anrede wurde aktualisiert';
    const severity = 'success';

    handleSubmit(updatedCompanyData, message, severity);
  }

  function handleDeleteSalutation(salutationData) {
    const isLastItem = companyData.salutations.length < 2;

    if (isLastItem) {
      showLastItemMessage();
    } else {
      const index = companyData.salutations.map((salutation) => salutation.id).indexOf(salutationData.id);

      const updatedCompanyData = {
        ...companyData,
        salutations: [...companyData.salutations.slice(0, index), ...companyData.salutations.slice(index + 1)],
      };

      const message = 'Anrede wurde gelöscht';
      const severity = 'info';

      handleSubmit(updatedCompanyData, message, severity);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            {!isEmpty(companyData.salutations) && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Geschlecht</TableCell>
                    <TableCell>Titel</TableCell>
                    <TableCell>Kurzschreibweise</TableCell>
                    <TableCell>
                      <Tooltip title="Anrede hinzufügen" placement="bottom-end" followCursor>
                        <Button
                          fullWidth
                          variant="contained"
                          className={classes.addButton}
                          onClick={() => {
                            setEditSalutation({});
                            setOpenSalutationDialog(true);
                          }}
                          color="primary"
                          size="large"
                        >
                          <AddCircleIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {companyData.salutations.map((salutation, index) => (
                    <TableRow hover key={index}>
                      <TableCell className={classes.cell}>{salutation.gender}</TableCell>
                      <TableCell className={classes.cell}>{salutation.title}</TableCell>
                      <TableCell className={classes.cell}>{salutation.shortTitle}</TableCell>
                      <TableCell className={classes.cell}>
                        <Tooltip title="Anrede bearbeiten" placement="bottom-end" followCursor>
                          <IconButton
                            className={classes.editIcon}
                            onClick={() => {
                              setEditSalutation(salutation);
                              setOpenSalutationDialog(true);
                            }}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Anrede löschen" placement="bottom-end" followCursor>
                          <IconButton
                            onClick={() => handleDeleteSalutation(salutation)}
                            size="large"
                            className={classes.deleteIcon}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          {openSalutationDialog && (
            <SalutationDialog
              openSalutationDialog={openSalutationDialog}
              setOpenSalutationDialog={setOpenSalutationDialog}
              handleCreateSalutation={handleCreateSalutation}
              editSalutation={editSalutation}
              handleUpdateSalutation={handleUpdateSalutation}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Salutations;
