declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    // xs: false; // removes the `xs` breakpoint
    // sm: false;
    // md: false;
    // lg: false;
    // xl: false;
    mobile: true; // adds the `mobile` breakpoint
    desktop: true;
  }
}

export default {
  values: {
    // remove later
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    // remove end
    mobile: 0,
    desktop: 1024, // tablet | desktop
    full: window.innerWidth,
  },
};
