// @ts-nocheck

// Packages
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Services
import { getOrderByOrderId } from 'src/services/order';
import { getOfferByOfferId } from 'src/services/offer';

// Redux
import { setAssignmentFormReducer, resetAssignmentFormReducer } from 'src/slices/assignmentSlice';

// Global Components
import AssignmentForm from 'src/components/AssignmentForm';

function AssignmentEdit() {
  const dispatch = useDispatch();
  const { assignmentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [assignment, setAssignment] = useState({});
  const isOrder = window.location.pathname.includes('order');

  useEffect(() => {
    getInitialData();
    return () => handleResetAssignmentFormReducer();
  }, []); // eslint-disable-line

  function handleResetAssignmentFormReducer() {
    dispatch(resetAssignmentFormReducer());
  }

  async function getInitialData() {
    const assignment = isOrder ? await getOrderByOrderId({ assignmentId }) : await getOfferByOfferId({ assignmentId });
    await setAssignment({
      ...assignment,
      offerOrOrder: isOrder ? 'Auftrag' : 'Angebot',
    });
    await dispatch(
      setAssignmentFormReducer({
        ...assignment,
        offerOrOrder: isOrder ? 'Auftrag' : 'Angebot',
      }),
    );
    setIsLoading(false);
  }

  return !isLoading ? <AssignmentForm assignment={assignment} /> : null;
}

export default AssignmentEdit;
