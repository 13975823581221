// @ts-nocheck
// Packages
import React, { useState } from 'react';

// Services
import { createWarehousePlace } from 'src/services/warehousePlace';

// Interfaces
import { WarehousePlace } from 'src/services/interfaces/warehousePlace.interface';

// MUI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  abortButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  greenButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

interface Props {
  warehousePlaces: WarehousePlace[];
  setWarehousePlaces: React.Dispatch<React.SetStateAction<WarehousePlace[]>>;
}

function Header({ warehousePlaces, setWarehousePlaces }: Props): JSX.Element {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [newWarehousePlace, setNewWarehousePlace] = useState<string>('');

  async function handleCreateWarehousePlace(event: { preventDefault: () => void }): Promise<void> {
    event.preventDefault();
    const createdWarehousePlace = await createWarehousePlace({
      name: newWarehousePlace,
    });
    setWarehousePlaces([...warehousePlaces, createdWarehousePlace]);
    setIsDialogOpen(false);
    setNewWarehousePlace('');
  }

  return (
    <>
      <Tooltip title="Lagerort hinzufügen" placement="bottom-end" followCursor>
        <Button
          sx={{ width: '120px' }}
          variant="contained"
          className={classes.greenButton}
          onClick={() => setIsDialogOpen(true)}
        >
          <AddCircleIcon />
        </Button>
      </Tooltip>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <Box textAlign="center">
          <DialogTitle className={classes.dialogTitle}>Lagerort hinzufügen</DialogTitle>
        </Box>

        <DialogContent>
          <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item mobile={12}>
              <TextField
                autoFocus
                onChange={(event) => {
                  setNewWarehousePlace(event.target.value);
                }}
                value={newWarehousePlace}
                fullWidth
                variant="outlined"
                label="Name des neuen Lagerorts"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item mobile={6}>
            <Button
              fullWidth
              variant="contained"
              className={classes.abortButton}
              onClick={() => setIsDialogOpen(false)}
            >
              Abbrechen
            </Button>
          </Grid>
          <Grid item mobile={6}>
            <Button fullWidth variant="contained" className={classes.greenButton} onClick={handleCreateWarehousePlace}>
              Speichern
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Header;
