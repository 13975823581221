// @ts-nocheck

// Packages
import React from 'react';

// Views
import { CompanyAuth as CompanyAuthLayout } from 'src/layouts';
import { default as CompanyForgotPassword } from './_CompanyForgotPassword';

function SignInCompany() {
  return (
    <CompanyAuthLayout>
      <CompanyForgotPassword />
    </CompanyAuthLayout>
  );
}

export default SignInCompany;
