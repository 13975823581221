// Packages
import axios from 'axios';

// Interfaces
import { WarehouseItem as WarehouseItemData } from './interfaces/warehouseItem.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/warehouseitem`;

export function createWarehouseItem(data: { warehouseItem: WarehouseItemData }) {
  return axios.post(`${apiURI}/create-warehouseitem`, data);
}

export function getWarehouseItemsByFilter(data: WarehouseItemData | Record<string, any>) {
  return axios
    .post(`${apiURI}/get-warehouseitem-by-filter`, data)
    .then((response) => response.data as WarehouseItemData);
}

export function updateWarehouseItem(data: WarehouseItemData) {
  return axios.post(`${apiURI}/update-warehouseitem`, data).then((response) => response.data as WarehouseItemData);
}

export function getOutsourcedWarehouseItems() {
  return axios.get(`${apiURI}/get-outsourced-warehouseitems`).then((response) => response.data as WarehouseItemData[]);
}
