// Packages
import React, { useEffect, useState } from 'react';

// Services
import { getCustomersByCompanyId } from 'src/services/customer';

// Global Components
import { CustomerForm, Link } from 'src/components';

// Interfaces
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';

// MUI
// import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  addButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
  editButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.blue.main,
    '&:hover': {
      backgroundColor: theme.palette.blue.main,
    },
  },
  row: {
    '&:hover': {
      backgroundColor: theme.palette.lightBlue.main,
    },
  },
  cell: {
    fontSize: '16px',
  },
}));

const CustomerOverview = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [customersAll, setCustomersAll] = useState([] as CustomerData[]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    getInitialCustomers();
  }, []); // eslint-disable-line

  const getInitialCustomers = async () => {
    try {
      const customerData = await getCustomersByCompanyId();
      setCustomersAll(customerData);
      setIsLoading(false);
    } catch (error) {
      if (error) throw error;
    }
  };

  function useWindowSize() {
    // +++ copied from: https://usehooks.com/useWindowSize/ +++
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener('resize', handleResize);

      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
  }

  return (
    <>
      {!isLoading && (
        <TableContainer sx={{ maxHeight: size.height - 130, overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }} className={classes.cell}>
                  Kunden-Nr.
                </TableCell>
                <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }} className={classes.cell}>
                  Kürzel
                </TableCell>
                <TableCell className={classes.cell}>Name</TableCell>
                <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }} className={classes.cell}>
                  PLZ Ort
                </TableCell>
                <TableCell>
                  <Button fullWidth className={classes.addButton} onClick={() => setIsDialogOpen(true)}>
                    <AddIcon />
                    Kunde hinzufügen
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {customersAll
                // @ts-ignore
                .sort((a, b) => a.number - b.number)
                .map((customer) => (
                  <TableRow className={classes.row} key={customer._id}>
                    <TableCell
                      sx={{ width: '10%', display: { mobile: 'none', desktop: 'table-cell' } }}
                      className={classes.cell}
                    >
                      {customer.number}
                    </TableCell>
                    <TableCell
                      sx={{ width: '10%', display: { mobile: 'none', desktop: 'table-cell' } }}
                      className={classes.cell}
                    >
                      {customer.nameShort}
                    </TableCell>
                    <TableCell style={{ width: '30%' }} className={classes.cell}>
                      {customer.name}
                    </TableCell>
                    <TableCell
                      sx={{ width: '30%', display: { mobile: 'none', desktop: 'table-cell' } }}
                      className={classes.cell}
                    >
                      {/* @ts-ignore */}
                      {customer.addresses
                        .filter((address) => address.usage === 'home')
                        .map(
                          (home) =>
                            `${home.zip} ${home.city} ${home.country === 'Deutschland' ? '' : `(${home.country})`}`,
                        )}
                    </TableCell>
                    <TableCell style={{ width: '20%' }} className={classes.cell}>
                      <Link to={`/customer/details/${customer._id}`}>
                        <Button fullWidth className={classes.editButton}>
                          Stammdaten anzeigen
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {isDialogOpen && <CustomerForm isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />}
    </>
  );
};

export default CustomerOverview;
