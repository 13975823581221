// Packages
import React from 'react';

// Interfaces
import { Position as PositionData } from 'src/services/interfaces/position.interface';

// MUI
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import Switch from '@mui/material/Switch';

interface Props {
  position: PositionData;
  setPosition: React.Dispatch<React.SetStateAction<PositionData>>;
}

const useStyles = makeStyles((theme) => ({
  formControl: { margin: theme.spacing(2.5, 0, 0, 0) },
  formControlCertificate: { margin: theme.spacing(0) },
}));

function Material({ position, setPosition }: Props): JSX.Element {
  const classes = useStyles();
  const { materialProvided, materialIncluded, materialCertificate } = position;

  return (
    <>
      <FormControlLabel
        className={classes.formControl}
        name="materialProvided"
        control={
          <Checkbox
            checked={materialProvided}
            color="primary"
            onChange={(event) => {
              setPosition({
                ...position,
                materialProvided: event.target.checked,
                materialIncluded: false,
                materialCertificate: false,
              });
            }}
          />
        }
        label="Material beigestellt?"
        labelPlacement="start"
      />

      <FormControlLabel
        className={classes.formControl}
        name="materialIncluded"
        control={
          <Checkbox
            checked={materialIncluded}
            color="secondary"
            onChange={(event) => {
              setPosition({
                ...position,
                materialProvided: false,
                materialIncluded: event.target.checked,
                materialCertificate: false,
              });
            }}
          />
        }
        label="inkl."
        labelPlacement="start"
      />

      {materialIncluded && (
        <FormControlLabel
          className={classes.formControlCertificate}
          name="materialCertificate"
          control={
            <Switch
              checked={materialCertificate}
              color="secondary"
              onChange={(event) => {
                setPosition({
                  ...position,
                  materialCertificate: event.target.checked,
                });
              }}
            />
          }
          label="Zeugnis"
          labelPlacement="top"
        />
      )}
    </>
  );
}

export default Material;
