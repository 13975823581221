// Packages
import axios from 'axios';

// Interfaces
import { Order as OrderData } from 'src/services/interfaces/order.interface';
import { StringDecoder } from 'string_decoder';

const apiURI = `${process.env.REACT_APP_API_URI}/order`;

export function createOrder(data: FormData) {
  return axios.post(`${apiURI}/assignment/create`, data).then((response) => response.data as OrderData);
}

export function updateOrder(data: FormData) {
  return axios.post(`${apiURI}/order-update`, data).then((response) => response.data as OrderData);
}

export function getOrderByOrderId(data: { assignmentId: StringDecoder }) {
  return axios.post(`${apiURI}/get-order-by-order-id`, data).then((response) => response.data as OrderData);
}

export function getUndeliveredOrdersByCustomerId(data: { customerId: string }) {
  return axios
    .post(`${apiURI}/get-undelivered-orders-by-customer-id`, data)
    .then((response) => response.data as OrderData[]);
}

export function getOrdersByDeliveredUnbilledPositions(data: { customerId: string }) {
  return axios
    .post(`${apiURI}/get-orders-by-delivered-unbilled-positions`, data)
    .then((response) => response.data as OrderData[]);
}

export function getOrderLettersByCustomerId(data: { customerId: string }) {
  return axios.post(`${apiURI}/get-order-letters-customer-id`, data).then((response) => response.data as Array<string>);
}
