// @ts-nocheck
// Packages
import React from 'react';

// Utils

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function ResetDialog({ handleChangeCustomer, resetDialogOptions, setResetDialogOptions }) {
  return (
    <Dialog
      maxWidth="xl"
      open={resetDialogOptions.isOpen}
      onClose={() => {
        setResetDialogOptions({ isOpen: false });
      }}
    >
      <DialogTitle align="center">Lieferschein leeren?</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button onClick={() => setResetDialogOptions({ isOpen: false })} variant="contained" color="blue" fullWidth>
              Abbrechen
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                setResetDialogOptions({ isOpen: false });
                handleChangeCustomer(resetDialogOptions.customer);
              }}
              variant="contained"
              color="red"
              fullWidth
            >
              Ja, leeren
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ResetDialog;
