// Packages
import React, { Key } from 'react';
import { Text, View } from '@react-pdf/renderer';
import moment from 'moment';

// Interfaces
import { Position as PositionData } from 'src/services/interfaces/position.interface';

interface Props {
  positions: PositionData[];
  cleanUpArrayOfPositionsPerPage: (position: PositionData, pageNumber: number) => void;
  isInvoice: boolean;
}

function Positions({ positions, cleanUpArrayOfPositionsPerPage, isInvoice }: Props): JSX.Element {
  return (
    <>
      <View
        style={{
          margin: 10,
          padding: 10,
          flexDirection: 'row',
          borderBottom: 1,
          borderBottomColor: 'lightgrey',
        }}
      >
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: 10,
            fontWeight: 600,
            width: '5%',
            textAlign: 'left',
          }}
        >
          Pos
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: 10,
            fontWeight: 600,
            width: '55%',
            textAlign: 'left',
          }}
        >
          Artikel
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: 10,
            fontWeight: 600,
            width: '10%',
            textAlign: 'right',
          }}
        >
          Menge
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: 10,
            fontWeight: 600,
            width: '15%',
            textAlign: 'right',
          }}
        >
          Einzelpreis
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: 10,
            fontWeight: 600,
            width: '15%',
            textAlign: 'right',
          }}
        >
          Gesamtpreis
        </Text>
      </View>
      <View
        style={{
          margin: '0 10 0 10',
          padding: '0 10 0 10',
          flexDirection: 'column',
        }}
      >
        {positions.map((position, index) => {
          const {
            _id,
            number,
            quantity,
            unit,
            price,
            description,
            drawNo,
            service,
            text,
            vatRate,
            material,
            materialProvided,
            materialIncluded,
            materialCertificate,
            order,
            // @ts-ignore
            deliveries,
          } = position;

          return (
            <View wrap={false} key={number + index} style={{ flexDirection: 'row', marginBottom: 10 }}>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: 10,
                  fontWeight: 600,
                  width: '5%',
                  textAlign: 'left',
                }}
              >
                {index + 1}
              </Text>
              <View style={{ width: '55%' }}>
                <Text style={{ fontFamily: 'Roboto', fontSize: 10, fontWeight: 600 }}>{description}</Text>
                {!position.isDeliveryCost && (
                  <Text style={{ fontFamily: 'Roboto', fontSize: 10 }}>
                    nach Zeichnung {drawNo} {service}
                  </Text>
                )}
                {!position.isDeliveryCost && (
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: 10,
                      fontWeight: 300,
                    }}
                  >
                    {text && `${text}\n`}
                    {materialProvided && 'aus kundenseitig beigestelltem Rohmaterial '}
                    {materialIncluded && 'inkl. Material '}
                    {materialCertificate && 'und Zeugnis \n'}
                    {material &&
                      `(Werkstoff: ${material.name} | ${material.shortName} | ${material.number} | ${material.dinEn})`}
                  </Text>
                )}

                {isInvoice && (
                  <>
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: 10,
                        fontWeight: 300,
                        color: 'red',
                      }}
                    >
                      zu ihrer Bestell-Nr. {order.customersNoForOrder}, Pos. {number}{' '}
                      {order.purchaser &&
                        `(bestellt von ${order.purchaser.salutation.shortTitle} ${order.purchaser.name})`}
                    </Text>
                    {deliveries.map(
                      (
                        delivery: {
                          quantity: any;
                          packingList: { dateCreated: moment.MomentInput; number: any; positions: any[] };
                        },
                        index: Key | null | undefined,
                      ) => (
                        <Text
                          key={index}
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 10,
                            fontWeight: 300,
                            color: 'blue',
                          }}
                        >
                          {`${delivery.quantity} Stk. geliefert am ${moment(delivery.packingList.dateCreated).format(
                            'DD.MM.YYYY',
                          )} mit Lieferschein ${delivery.packingList.number}, Pos. ${
                            delivery.packingList.positions.map((position) => position.position).indexOf(_id) + 1
                          }`}
                        </Text>
                      ),
                    )}
                  </>
                )}
              </View>
              <Text style={{ fontFamily: 'Roboto', fontSize: 10, width: '10%', textAlign: 'right' }}>
                {quantity} {unit.shortName}
              </Text>
              <Text style={{ fontFamily: 'Roboto', fontSize: 10, width: '15%', textAlign: 'right' }}>
                {/* @ts-ignore */}
                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price.toFixed(2))}
              </Text>
              <View
                fixed
                style={{ fontFamily: 'Roboto', fontSize: 10, width: '15%', textAlign: 'right' }}
                render={({ pageNumber }) => {
                  cleanUpArrayOfPositionsPerPage(position, pageNumber);

                  return (
                    <Text>
                      {new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(Number((price * quantity).toFixed(2)))}
                      {vatRate === '7' && ' *'}
                    </Text>
                  );
                }}
              />
            </View>
          );
        })}
      </View>
    </>
  );
}

export default Positions;
