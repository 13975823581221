// @ts-nocheck

// Packages
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Utils
import isEmpty from 'src/utils/isEmpty';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  abortButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  greenButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

function UnitDialog({ editUnit, openUnitDialog, setOpenUnitDialog, handleCreateUnit, handleUpdateUnit, companyData }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [unitData, setUnitData] = useState(editUnit.name ? editUnit : {});

  useEffect(() => {
    setUnitData(editUnit.name ? editUnit : {});
    return () => setUnitData({});
  }, []); //eslint-disable-line

  function handleCloseUnitDialog() {
    setOpenUnitDialog(false);
  }

  function handleChangeUnitData(event) {
    const { name, value } = event.target;
    setUnitData({ ...unitData, [name]: value });
  }

  function handleSubmit() {
    const isDuplicateUnitName = companyData.units
      ? companyData.units.map((unit) => unit.name).includes(unitData.name)
      : false;

    const unitIndex = companyData.units ? companyData.units.map((unit) => unit.name).indexOf(editUnit.name) : -1;

    if (!isDuplicateUnitName || editUnit.name === unitData.name) {
      isEmpty(editUnit) ? handleCreateUnit(unitData) : handleUpdateUnit(unitData, unitIndex);

      handleCloseUnitDialog();
    } else {
      dispatch(
        showSnackbarReducer({
          message: 'diese Einheit ist bereits vorhanden',
          severity: 'error',
        }),
      );
    }
  }

  return (
    <Dialog maxWidth="md" open={openUnitDialog} onClose={() => handleCloseUnitDialog()}>
      <Box textAlign="center">
        <DialogTitle className={classes.dialogTitle}>Einheit {editUnit.name ? 'bearbeiten' : 'hinzufügen'}</DialogTitle>
      </Box>
      <DialogContent>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid item mobile={7}>
            <TextField
              variant="outlined"
              label="Einheit"
              type="text"
              value={unitData.name || ''}
              name="name"
              fullWidth
              onChange={handleChangeUnitData}
            />
          </Grid>
          <Grid item mobile={5}>
            <TextField
              variant="outlined"
              label="Kurzschreibweise"
              type="text"
              value={unitData.shortName || ''}
              name="shortName"
              fullWidth
              onChange={handleChangeUnitData}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item mobile={6}>
          <Button fullWidth variant="contained" className={classes.abortButton} onClick={() => handleCloseUnitDialog()}>
            Abbrechen
          </Button>
        </Grid>
        <Grid item mobile={6}>
          <Button
            fullWidth
            variant="contained"
            className={classes.greenButton}
            onClick={handleSubmit}
            disabled={
              isEmpty(unitData.name) || (editUnit.name === unitData.name && editUnit.shortName === unitData.shortName)
            }
          >
            {editUnit.name ? 'Aktualisieren' : 'Erstellen'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default UnitDialog;
