// Packages
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

// MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Redux
import { updatePdfReducer } from 'src/slices/assignmentSlice';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Pdf() {
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(0);
  const { pdf } = useSelector((state: RootStateOrAny) => state.assignment.assignmentForm);
  const size = useWindowSize();

  useEffect(() => {
    return () => handleResetPdf();
  }, []);

  function handleResetPdf() {
    dispatch(updatePdfReducer(null));
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  function useWindowSize() {
    // +++ copied from: https://usehooks.com/useWindowSize/ +++
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener('resize', handleResize);

      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="center" direction="column" spacing={2}>
      {pdf && (
        <Grid item xs>
          <Box sx={{ maxHeight: size.height - 130, overflow: 'auto' }}>
            <Document file={!isEmpty(pdf) && pdf.Location ? pdf.Location : pdf} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <>
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} width={size.width * 0.55} />
                  {numPages > 1 && (
                    <Divider>
                      <Chip label="- - - nächste Seite- - - -" color="secondary" />
                    </Divider>
                  )}
                </>
              ))}
            </Document>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default Pdf;
