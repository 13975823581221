// Packages
import axios from 'axios';

// Interfaces
import { Pdf as PdfData } from 'src/services/interfaces/pdf.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/pdf`;

export function createPdf(data: FormData) {
  return axios.post(`${apiURI}/create-pdf`, data).then((response) => response.data as PdfData);
}

export function sendPdf(data: { pdfData: PdfData['S3Data']; customerId: string }) {
  return axios.post(`${apiURI}/send-pdf`, data).then((response) => response.data as string);
}

export function getPdfsByAssignmentId(data: { [x: string]: string }) {
  return axios.post(`${apiURI}/get-pdfs-by-assignment-id`, data).then((response) => response.data as PdfData[]);
}
