// @ts-nocheck

//  Packages
import React, { useState } from 'react';

// Local Components

// MUI
import { ListItem, ListItemText, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import PartialDeliveryDialog from '../../../PartialDeliveryDialog';

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.superLightGreen.main,
      color: 'black',
    },
  },
  typoBold: { fontWeight: 600 },
}));

function Item({ position, handleAddPositionToPackingList, showNumbers }) {
  const classes = useStyles();
  const [isPartialDeliveryDialogOpen, setIsPartialDeliveryDialogOpen] = useState(false);

  const {
    _id,
    number,
    description,
    quantity,
    quantityDelivered,
    unit,
    text,
    drawNo,
    service,
    material,
    materialProvided,
    materialIncluded,
    materialCertificate,
  } = position;
  const quantityRemaining = quantity - quantityDelivered;

  return (
    <>
      <Tooltip title={`Position ${number} liefern`} placement="bottom-end" followCursor>
        <ListItem button className={classes.listItem} key={_id} onClick={() => setIsPartialDeliveryDialogOpen(true)}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Grid item mobile={1} desktop={1}>
              <Typography variant="h4" align="center" color="text.green">
                {number}
              </Typography>
            </Grid>
            <Grid item mobile={8} desktop={8}>
              <ListItemText
                primary={
                  <Typography variant="body1" className={classes.typoBold}>
                    {description} {!showNumbers}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography variant="body2" className={classes.typoBold}>
                      nach Zeichnung {drawNo} {service}
                    </Typography>
                    <Typography variant="body2">{text}</Typography>
                    <Typography variant="body2">
                      {materialProvided && 'aus kundenseitig beigestelltem Rohmaterial '}
                      {materialIncluded && 'inkl. Material '} {materialCertificate && 'und Zeugnis \n'}
                    </Typography>
                    {material && (
                      <Typography variant="caption">{`(${material.name} | ${material.shortName} | ${material.number} | ${material.dinEn})`}</Typography>
                    )}
                  </>
                }
              />
            </Grid>
            <Grid item mobile={3} desktop={3}>
              <Typography variant="h4" align="right">{`${quantityRemaining} ${unit.shortName}`}</Typography>
            </Grid>
          </Grid>
        </ListItem>
      </Tooltip>

      {isPartialDeliveryDialogOpen && (
        <PartialDeliveryDialog
          type="add"
          position={position}
          handleAddPositionToPackingList={handleAddPositionToPackingList}
          isPartialDeliveryDialogOpen={isPartialDeliveryDialogOpen}
          setIsPartialDeliveryDialogOpen={setIsPartialDeliveryDialogOpen}
          handleUpdatePositionOnPackingList={undefined}
        />
      )}
    </>
  );
}

export default Item;
