// @ts-nocheck

//  Packages
import React, { useState } from 'react';

// Utils
import isEmpty from 'src/utils/isEmpty';

// MUI
import { List, Typography, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { Item, Number } from './components';

function UndeliveredPositions({ undeliveredPositions, packingListPositions, handleAddPositionToPackingList }) {
  const [showNumbers, setShowNumbers] = useState(false);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Tooltip
          title={showNumbers ? 'Positions-Text anzeigen' : 'nur Nummern anzeigen'}
          placement="bottom-end"
          followCursor
        >
          <Button onClick={() => setShowNumbers(!showNumbers)}>
            <Typography variant="h6" color="text.green" align="center">
              POSITIONEN
            </Typography>
          </Button>
        </Tooltip>
      </Grid>

      {!showNumbers ? (
        <Paper>
          <List disablePadding>
            {undeliveredPositions
              .sort((a, b) => {
                if (a.number < b.number) {
                  return -1;
                }
                if (a.number > b.number) {
                  return 1;
                }
                return 0;
              })
              .map((position) => (
                <React.Fragment key={position._id}>
                  {isEmpty(packingListPositions.filter((pos) => pos._id === position._id)) && (
                    <Item position={position} handleAddPositionToPackingList={handleAddPositionToPackingList} />
                  )}
                </React.Fragment>
              ))}
          </List>
        </Paper>
      ) : (
        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
          {undeliveredPositions
            .sort((a, b) => {
              if (a.number < b.number) {
                return -1;
              }
              if (a.number > b.number) {
                return 1;
              }
              return 0;
            })
            .map((position) => (
              <React.Fragment key={position._id}>
                {isEmpty(packingListPositions.filter((pos) => pos._id === position._id)) && (
                  <Number position={position} handleAddPositionToPackingList={handleAddPositionToPackingList} />
                )}
              </React.Fragment>
            ))}
        </Grid>
      )}
    </>
  );
}

export default UndeliveredPositions;
