// Packages
import React from 'react';

// Local Components

// Interfaces
import { Position as PositionData } from 'src/services/interfaces/position.interface';
import { PaymentTerm as PaymentTermData } from 'src/services/interfaces/company.interface';
import { Positions, Totals } from './components';

interface Props {
  positions: PositionData[];
  cleanUpArrayOfPositionsPerPage: (position: PositionData, pageNumber: number) => void;
  totalNet: number;
  totalGross: number;
  groupedTotal: {
    vatRate: string;
    positions: any;
    totalNet: number;
    totalGross: number;
    totalVat: number;
  }[];
  paymentTerm: PaymentTermData;
  isInvoice: boolean;
}

function PositionsOfferOrderInvoice({
  positions,
  cleanUpArrayOfPositionsPerPage,
  totalNet,
  totalGross,
  groupedTotal,
  paymentTerm,
  isInvoice,
}: Props): JSX.Element {
  return (
    <>
      {/* Positions */}
      <Positions
        positions={positions}
        cleanUpArrayOfPositionsPerPage={cleanUpArrayOfPositionsPerPage}
        isInvoice={isInvoice}
      />

      {/* Totals */}
      <Totals totalNet={totalNet} groupedTotal={groupedTotal} totalGross={totalGross} paymentTerm={paymentTerm} />
    </>
  );
}

export default PositionsOfferOrderInvoice;
