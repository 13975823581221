// Packages
import React, { SetStateAction, useState } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

// Services
import { updateCompany, getCurrentCompany } from 'src/services/company';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Reducers
import { showSnackbarReducer } from 'src/slices/snackbarSlice';
import { setCurrentCompanyReducer } from 'src/slices/authSlice';

// MUI
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  service: string;
  handleUpdatePosition: (data: any) => void;
}

function Service({ service, handleUpdatePosition }: Props): JSX.Element {
  const dispatch = useDispatch();
  const { company } = useSelector((state: RootStateOrAny) => state.auth);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newService, setNewService] = useState('');

  function handleClose() {
    setIsAddForm(false);
    setNewService('');
  }

  function handleSetNewService(event: { target: { value: SetStateAction<string> } }) {
    setNewService(event.target.value);
  }

  async function handleAddNewService() {
    setIsLoading(true);
    await updateCompany({ ...company, services: [...company.services, newService] });
    const foundCompany = await getCurrentCompany();
    dispatch(setCurrentCompanyReducer(foundCompany));
    dispatch(showSnackbarReducer({ message: 'Neue Bearbeitung wurde erstellt', severity: 'success' }));
    handleUpdatePosition({ target: { name: 'service', value: newService } });
    handleClose();
    setIsLoading(false);
  }

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs>
        <Autocomplete
          value={!isEmpty(service) ? service : null}
          clearText="Enfernen"
          openText="Oeffnen"
          onChange={(event, service) => {
            handleUpdatePosition({
              target: { name: 'service', value: service },
            });
          }}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option}
          options={company.services}
          renderInput={(params) => <TextField {...params} label="Bearbeitung" variant="outlined" />}
        />
      </Grid>
      <Grid>
        <IconButton onClick={() => setIsAddForm(!isAddForm)} size="large">
          <AddIcon />
        </IconButton>
      </Grid>
      {isAddForm && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                onChange={handleSetNewService}
                // value={newService.name}
                value={newService}
                name="service"
                label="Name"
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <Button onClick={handleClose} fullWidth variant="outlined">
                Abbrechen
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={isLoading || !newService}
                onClick={handleAddNewService}
                color="secondary"
                fullWidth
                variant="outlined"
              >
                hinzufügen
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default Service;
