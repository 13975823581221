// @ts-nocheck

// Packages
import { createSlice } from '@reduxjs/toolkit';

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    message: '',
    severity: '',
    permanent: false,
  },
  reducers: {
    showSnackbarReducer: (state, action) => {
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.permanent = action.payload.permanent;
    },
    closeSnackbarReducer: (state) => {
      state.message = '';
      state.severity = '';
      state.permanent = false;
    },
  },
});

export const { showSnackbarReducer, closeSnackbarReducer } = snackbarSlice.actions;

export const snackbar = (state) => state.snackbar;

export default snackbarSlice.reducer;
