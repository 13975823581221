// Packages
import React from 'react';

// Packages
import { Text, View } from '@react-pdf/renderer';

// Interfaces
import { PaymentTerm as PaymentTermData } from 'src/services/interfaces/company.interface';

interface Props {
  totalNet: number;
  groupedTotal: {
    vatRate: string;
    positions: any;
    totalNet: number;
    totalGross: number;
    totalVat: number;
  }[];
  totalGross: number;
  paymentTerm: PaymentTermData;
}

function Totals({ totalNet, groupedTotal, totalGross, paymentTerm }: Props): JSX.Element {
  return (
    <>
      {/* Totals */}
      <View wrap={false}>
        <View
          style={{
            margin: 10,
            padding: 10,
            borderBottom: 1,
            borderTop: 1,
            borderColor: 'lightgrey',
          }}
        >
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={{ fontFamily: 'Roboto', fontSize: 10, fontWeight: 600 }}>Gesamt netto</Text>
            <View>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: 10,
                  flexDirection: 'row',
                  fontWeight: 600,
                }}
              >
                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalNet)}
              </Text>
            </View>
          </View>

          {groupedTotal.map((group) => {
            const { vatRate, totalNet, totalVat } = group;
            return (
              <View key={group.vatRate} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={{ fontFamily: 'Roboto', fontSize: 10 }}>
                  {vatRate === '7' && '* mit reduziertem Steuersatz \n'}
                  zzgl. MwSt (aktuell {vatRate} %) auf{' '}
                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalNet)}
                </Text>
                <View>
                  <Text style={{ fontFamily: 'Roboto', fontSize: 10, flexDirection: 'row' }}>
                    {vatRate === '7' && '\n'}
                    {/* @ts-ignore */}
                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalVat.toFixed(2))}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
        <View
          style={{
            margin: '0 10 10 10 ',
            padding: '0 10 10 10 ',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text style={{ fontFamily: 'Roboto', fontSize: 10, fontWeight: 600 }}>Gesamtbetrag</Text>
          <Text style={{ fontFamily: 'Roboto', fontSize: 10, fontWeight: 600 }}>
            {/* @ts-ignore */}
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalGross.toFixed(2))}
          </Text>
        </View>
        <View
          style={{
            margin: '0 10 10 10 ',
            padding: '0 10 10 10 ',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text style={{ fontFamily: 'Roboto', fontSize: 10 }}>Zahlungsziel: {paymentTerm.name}</Text>
        </View>
      </View>
    </>
  );
}

export default Totals;
