// @ts-nocheck

//  Packages
import React, { useState } from 'react';

// MUI
import { ListItem, ListItemText } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import PartialDeliveryDialog from '../../../PartialDeliveryDialog';

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.lightRed.main,
    },
  },
  deleteButton: { minHeight: '54px', maxWidth: '54px' },
  typoBold: { fontWeight: 600 },
  button: {
    backgroundColor: theme.palette.red.main,
    color: theme.palette.white.main,
    borderRadius: '10px',
    width: '40px',
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  grid: {
    maxWidth: '54px',
  },
}));

function Item({ position, handleUpdatePositionOnPackingList, handleDeletePositionOnPackingList }) {
  const classes = useStyles();
  const theme = useTheme();
  const [isPartialDeliveryDialogOpen, setIsPartialDeliveryDialogOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  const {
    quantityAdded,
    description,
    unit,
    _id,
    number,
    text,
    drawNo,
    service,
    material,
    materialProvided,
    materialIncluded,
    materialCertificate,
  } = position;

  return (
    <>
      <ListItem button className={classes.listItem} key={_id} onClick={() => setIsPartialDeliveryDialogOpen(true)}>
        <Tooltip title="Position bearbeiten" placement="bottom-end" followCursor>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
            <Grid item mobile={2.5} desktop={1} className={classes.grid}>
              <Grid container justifyContent="center" alignItems="center">
                <Button variant="contained" className={classes.button}>
                  <Typography variant="h4" align="center" color="white">
                    {position.order.numberLetter}
                    {number}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid item mobile={5} desktop={6.5}>
              <ListItemText
                primary={
                  <Typography variant="body1" className={classes.typoBold}>
                    {description}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography variant="body2" className={classes.typoBold}>
                      nach Zeichnung {drawNo} {service}
                    </Typography>
                    <Typography variant="body2">{text}</Typography>
                    <Typography variant="body2">
                      {materialProvided && 'aus kundenseitig beigestelltem Rohmaterial '}
                      {materialIncluded && 'inkl. Material '} {materialCertificate && 'und Zeugnis \n'}
                    </Typography>
                    {material && (
                      <Typography variant="caption">{`(${material.name} | ${material.shortName} | ${material.number} | ${material.dinEn})`}</Typography>
                    )}
                  </>
                }
              />
            </Grid>
            <Grid item mobile={1.5} desktop={2}>
              <Typography
                variant={isMobile ? 'h6' : 'h4'}
                align="center"
                className={classes.typoQuantity}
              >{`${quantityAdded} ${unit.shortName}`}</Typography>
            </Grid>

            <Grid item mobile={3} desktop={1.5}>
              <Grid container justifyContent="center" alignItems="center">
                <Tooltip title="Position entfernen" placement="bottom-end" followCursor>
                  <Button
                    fullWidth
                    color="red"
                    variant="contained"
                    onClick={() => handleDeletePositionOnPackingList(_id)}
                    className={classes.deleteButton}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Tooltip>
      </ListItem>

      {isPartialDeliveryDialogOpen && (
        <PartialDeliveryDialog
          type="update"
          position={position}
          handleUpdatePositionOnPackingList={handleUpdatePositionOnPackingList}
          isPartialDeliveryDialogOpen={isPartialDeliveryDialogOpen}
          setIsPartialDeliveryDialogOpen={setIsPartialDeliveryDialogOpen}
          handleAddPositionToPackingList={undefined}
        />
      )}
    </>
  );
}

export default Item;
