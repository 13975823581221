// Packages
import React, { useEffect, useState } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Interfaces
import { DeliveryTerm as DeliverTermData } from 'src/services/interfaces/company.interface';

// MUI
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface Props {
  handleUpdateBaseData: (data: { name: string; value: any }) => void;
}

function DeliveryTerm({ handleUpdateBaseData }: Props) {
  const { deliveryTerm }: { deliveryTerm: DeliverTermData } = useSelector(
    (state: RootStateOrAny) => state.assignment.assignmentForm,
  );
  const { deliveryTerms }: { deliveryTerms: DeliverTermData[] } = useSelector(
    (state: RootStateOrAny) => state.auth.company,
  );
  const [indexDeliveryTerm, setIndexDeliveryTerm] = useState(0);

  useEffect(() => {
    deliveryTerm &&
      setIndexDeliveryTerm(deliveryTerms.map((deliveryTerm) => deliveryTerm.incoterm).indexOf(deliveryTerm.incoterm));
  }, [deliveryTerm]); // eslint-disable-line

  function handleChangeDeliveryTerm(event: { target: any }) {
    const { name, value } = event.target;
    handleUpdateBaseData({ name, value });
  }

  return (
    <>
      {!isEmpty(deliveryTerms) ? (
        <Autocomplete
          value={deliveryTerms[indexDeliveryTerm || 0] || null}
          clearText="Enfernen"
          openText="Oeffnen"
          onChange={(event, deliveryTerm) => {
            handleChangeDeliveryTerm({
              target: { name: 'deliveryTerm', value: deliveryTerm || '' },
            });
          }}
          isOptionEqualToValue={(option, value) => option.incoterm === value.incoterm}
          getOptionLabel={(option) => `${option.meaning} (${option.incoterm})`}
          options={deliveryTerms}
          renderInput={(params) => <TextField {...params} label="Lieferbedingung" variant="outlined" />}
        />
      ) : (
        <Typography variant="caption" color="secondary">
          keine Lieferbedingungen hinterlegt
        </Typography>
      )}
    </>
  );
}

export default DeliveryTerm;
