// @ts-nocheck
// Packages
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Interfaces
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';

// Services
import {
  createCustomer,
  getNextFreeCustomerNumberByCompanyId,
  checkDuplicateCustomerNumberByCompanyId,
} from 'src/services/customer';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';

interface Props {
  isCreateDialogOpen: boolean;
  setIsCreateDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customers: CustomerData[];
  setCustomers: React.Dispatch<React.SetStateAction<CustomerData[]>>;
  handleUpdateBaseData: (data: { name: string; value: any }) => void;
}

function CreateCustomerDialog({
  isCreateDialogOpen,
  setIsCreateDialogOpen,
  customers,
  setCustomers,
  handleUpdateBaseData,
}: Props) {
  const [newCustomer, setNewCustomer] = useState({} as CustomerData);
  const { company } = useSelector((state: RootStateOrAny) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isCreateDialogOpen) {
      setNewCustomerNumber();
    }
  }, [isCreateDialogOpen]); // eslint-disable-line

  const setNewCustomerNumber = async () => {
    const newCustomerNumber = await getNextFreeCustomerNumberByCompanyId({
      number: company.maxCustomerNumber,
    });

    setNewCustomer({
      ...newCustomer,
      number: newCustomerNumber,
    });
  };

  function handleClose() {
    setIsCreateDialogOpen(false);
    resetNewCustomer();
  }

  function handleChange(event: { target: { name: any; value: any } }) {
    const { name, value } = event.target;
    setNewCustomer({ ...newCustomer, [name]: value });
  }

  function handleChangeNumber(event: { target: { name: string; value: any } }) {
    const { name, value } = event.target;

    if (!isNaN(value)) {
      setNewCustomer({ ...newCustomer, [name]: value });
    }

    if (value.length === 4) {
      checkNewManualCustomerNumber(value);
    }
  }

  const checkNewManualCustomerNumber = async (manualNumber: string) => {
    const isDuplicate = await checkDuplicateCustomerNumberByCompanyId({
      number: manualNumber,
    });

    if (isDuplicate) {
      dispatch(
        showSnackbarReducer({
          message: `Die Kunden-Nummer ${manualNumber} ist bereits vergeben`,
          severity: 'red',
        }),
      );

      setNewCustomer({ ...newCustomer, number: manualNumber.substring(0, 3) });
    }
  };

  function resetNewCustomer() {
    setNewCustomer({ number: '', name: '', nameShort: '' });
  }

  async function handleSubmit() {
    try {
      const createdCustomer = await createCustomer(newCustomer);

      setCustomers([...customers, createdCustomer].sort((a, b) => (a.name > b.name ? -1 : 1)));
      handleUpdateBaseData({ name: 'customer', value: createdCustomer });

      setIsCreateDialogOpen(false);
      resetNewCustomer();
    } catch (error) {
      if (error) throw error;
    }
  }

  return (
    <Dialog maxWidth="xs" open={isCreateDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <Box textAlign="center">
        <DialogTitle id="form-dialog-title">Kunde hinzufügen</DialogTitle>
      </Box>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="neue Kunden-Nummer (4-stellig)"
              type="text"
              value={newCustomer.number || ''}
              name="number"
              onChange={handleChangeNumber}
              fullWidth
              inputProps={{ maxLength: 4, inputMode: 'numeric' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Name"
              type="text"
              value={newCustomer.name}
              name="name"
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Kürzel"
              type="text"
              value={newCustomer.nameShort}
              name="nameShort"
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <Button onClick={handleClose} color="red" fullWidth variant="contained" size="large">
              Abbrechen
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleSubmit}
              color="green"
              disabled={!newCustomer.name || !newCustomer.nameShort || newCustomer.number.toString().length !== 4}
              fullWidth
              variant="contained"
              size="large"
            >
              Speichern
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" display="block" variant="caption">
              (weitere Details über die Kundenverwaltung ergänzbar)
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CreateCustomerDialog;
