// @ts-nocheck

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    {/* // For now i disabled the StrictMode because of unreachable console errors //
    https://github.com/mui-org/material-ui/issues/13394 // https://material-ui.com/customization/theming/ //
    https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode */}
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
