// @ts-nocheck

// Packages
import React, { useState } from 'react';

// Local Components

// MUI
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import AddressDialog from '../../../AddressDialog';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.lightBlue.main} !important`,
      cursor: 'pointer',
    },
  },
  deleteButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
}));

function ListItem({ addresses, address, handleCreateAddress, handleUpdateAddress }) {
  const classes = useStyles();
  const [openAddressDialog, setOpenAddressDialog] = useState(false);

  return (
    <>
      <Tooltip title="Adresse ändern" placement="bottom-end" followCursor>
        <TableRow hover className={classes.tableRow} key={address._id} onClick={() => setOpenAddressDialog(true)}>
          {address.usage === 'home' && (
            <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>Zentrale</TableCell>
          )}
          {address.usage === 'invoice' && (
            <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>Rechnung</TableCell>
          )}
          {address.usage === 'delivery' && (
            <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>Lieferung</TableCell>
          )}
          <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>{address.street}</TableCell>
          <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>{address.houseNumber}</TableCell>
          <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>{address.zip}</TableCell>
          <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>{address.city}</TableCell>
          <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>{address.country}</TableCell>
          <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>
            {address.isActive ? 'ja' : 'nein'}
          </TableCell>
          <TableCell sx={{ display: { mobile: 'table-cell', desktop: 'none' } }}>
            {address.usage === 'home' && '- Zentrale -'}
            {address.usage === 'invoice' && '- Rechnung -'}
            {address.usage === 'delivery' && '- Lieferung -'} <br />
            {address.street} {address.houseNumber} <br />
            {address.zip} {address.city} <br /> ({address.country})
          </TableCell>
        </TableRow>
      </Tooltip>
      {openAddressDialog && (
        <AddressDialog
          addresses={addresses}
          address={address}
          openAddressDialog={openAddressDialog}
          setOpenAddressDialog={setOpenAddressDialog}
          handleCreateAddress={handleCreateAddress}
          handleUpdateAddress={handleUpdateAddress}
        />
      )}
    </>
  );
}

export default ListItem;
