// @ts-nocheck
// Packages
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Services
import { deletePosition, updatePosition } from 'src/services/position';
import { updatePositionReducer, deletePositionReducer } from 'src/slices/assignmentSlice';

// Global Components
import PositionDialog from 'src/components/PositionDialog';
import Link from 'src/components/Link';

// MUI
import Switch from '@mui/material/Switch';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DragHandleIcon from '@mui/icons-material/DragHandle';

function PositionItem({ assignment, position, draggableProvided, isDragableEnabled, index }) {
  const dispatch = useDispatch();
  const { assignmentId, assignmentType } = useParams();
  const [openPositionDialog, setOpenPositionDialog] = useState(false);

  const { currency, order, customer } = useSelector((state) => state.assignment.assignmentByAssignmentId);

  const handleDeletePosition = async (position) => {
    const updatedPositions = await deletePosition({
      positionId: position._id,
      assignmentId,
      assignmentType,
    });

    dispatch(deletePositionReducer(updatedPositions));
  };

  const handleIsActive = async (position) => {
    const updatedPosition = await updatePosition({
      ...position,
      isActive: !position.isActive,
    });

    dispatch(updatePositionReducer(updatedPosition));
  };

  return (
    <>
      <TableRow key={position._id} hover ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
        {isDragableEnabled() && (
          <TableCell>
            <div {...draggableProvided.dragHandleProps}>
              <DragHandleIcon />
            </div>
          </TableCell>
        )}
        <TableCell>
          {assignmentType === 'packinglist' || assignmentType === 'invoice' ? index + 1 : position.number}
        </TableCell>
        {assignmentType === 'packinglist' && (
          // Better would be the NextLink but there is a rendering issue because of the "same component rendering"
          <TableCell>
            <Link to={`/assignment/details/order/${position.order._id}`}>{position.order.number}</Link>
          </TableCell>
        )}
        <TableCell>{position.description}</TableCell>
        <TableCell>{position.drawNo}</TableCell>
        <TableCell>{position.text}</TableCell>
        <TableCell>{position.material && position.material.name}</TableCell>
        <TableCell>
          {assignmentType === 'packinglist' ? position.quantityByPackingList : position.quantity}{' '}
          {position.unit.shortName}
        </TableCell>
        {assignmentType !== 'packinglist' && (
          <>
            <TableCell>
              {position.price.toFixed(2)} {currency.symbol}
            </TableCell>
            <TableCell>
              {(position.quantity * position.price).toFixed(2)} {currency.symbol}
            </TableCell>
            <TableCell>{position.vatRate}%</TableCell>
            {assignmentType !== 'packinglist' &&
              assignmentType !== 'invoice' &&
              !order &&
              !assignment.deliveryCompleted && (
                <TableCell>
                  <IconButton
                    disabled={!position.isActive}
                    color="primary"
                    onClick={() => setOpenPositionDialog(true)}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton disabled={!position.isActive} onClick={() => handleDeletePosition(position)} size="large">
                    <DeleteIcon />
                  </IconButton>
                  <Switch checked={position.isActive} onChange={() => handleIsActive(position)} />
                </TableCell>
              )}
          </>
        )}
        {assignmentType === 'packinglist' && (
          <TableCell>{position.deliveryCompleted && 'vollständig geliefert'}</TableCell>
        )}
      </TableRow>
      {openPositionDialog && (
        <PositionDialog
          openPositionDialog={openPositionDialog}
          setOpenPositionDialog={setOpenPositionDialog}
          position={position}
          customer={customer}
          assignmentDeliveryDate={undefined}
        />
      )}
    </>
  );
}

export default PositionItem;
