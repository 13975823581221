// Packages
import React, { useEffect, useState } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Interfaces
import { PaymentTerm as PaymentTermData } from 'src/services/interfaces/company.interface';

// MUI
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface Props {
  handleUpdateBaseData: (data: { name: string; value: any }) => void;
}

function PaymentTerm({ handleUpdateBaseData }: Props) {
  const { paymentTerm }: { paymentTerm: PaymentTermData } = useSelector(
    (state: RootStateOrAny) => state.assignment.assignmentForm,
  );
  const { paymentTerms }: { paymentTerms: PaymentTermData[] } = useSelector(
    (state: RootStateOrAny) => state.auth.company,
  );
  const [indexPaymentTerm, setIndexPaymentTerm] = useState(0);

  useEffect(() => {
    paymentTerm && setIndexPaymentTerm(paymentTerms.map((paymentTerm) => paymentTerm.name).indexOf(paymentTerm.name));
  }, [paymentTerm]); // eslint-disable-line

  function handleChangePaymentTerm(event: { target: any }) {
    const { name, value } = event.target;
    handleUpdateBaseData({ name, value });
    setIndexPaymentTerm(paymentTerms.map((paymentTerm) => paymentTerm.name).indexOf(value.name));
  }

  return (
    <>
      {!isEmpty(paymentTerms) ? (
        <Autocomplete
          value={paymentTerms[indexPaymentTerm || 0] || null}
          clearText="Enfernen"
          openText="Oeffnen"
          onChange={(event, paymentTerm) => {
            handleChangePaymentTerm({
              target: { name: 'paymentTerm', value: paymentTerm || '' },
            });
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          options={paymentTerms}
          renderInput={(params) => <TextField {...params} label="Zahlungsbedingung" variant="outlined" />}
        />
      ) : (
        <Typography variant="caption" color="secondary">
          keine Lieferbedingungen hinterlegt
        </Typography>
      )}
    </>
  );
}

export default PaymentTerm;
