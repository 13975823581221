// Packages
import React from 'react';

// Utils
import isEmpty from 'src/utils/isEmpty';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

// React-Icons
import { BsCircleFill, BsCircle, BsBoxSeam, BsFillHexagonFill, BsSquareFill, BsTrophy } from 'react-icons/bs';

// Interfaces
import { WarehouseItem } from 'src/services/interfaces/warehouseItem.interface';

const useStyles = makeStyles((theme) => ({
  symbol: {
    fontSize: '2rem',
    color: theme.palette.blue.main,
  },
  avatar: {
    color: theme.palette.black.main,
    backgroundColor: theme.palette.yellow.main,
    display: 'inline-flex',
    marginLeft: '10px',
  },
  title: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.lightGrey.main,
  },
}));

interface TableRowItemProps {
  item: WarehouseItem;
  handleRestoreWarehouseItem: (data: WarehouseItem) => void;
}

function TableRowItem({ item, handleRestoreWarehouseItem }: TableRowItemProps): JSX.Element {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Grid item>
        <TableRow>
          <TableCell sx={{ width: '200px' }}>
            {/* @ts-ignore */}
            <Button fullWidth variant="contained" color="green" onClick={() => handleRestoreWarehouseItem(item)}>
              wieder Einlagern
            </Button>
          </TableCell>
          <TableCell component="th" scope="row" sx={{ width: '64px' }}>
            {item.category === 'round' && <BsCircleFill className={classes.symbol} />}
            {item.category === 'tube' && <BsCircle className={classes.symbol} />}
            {item.category === 'hexagon' && <BsFillHexagonFill className={classes.symbol} />}
            {item.category === 'flat' && <BsSquareFill className={classes.symbol} />}
            {item.category === 'article' && <BsTrophy className={classes.symbol} />}
            {item.category === 'diverse' && <BsBoxSeam className={classes.symbol} />}
          </TableCell>
          <TableCell align="left">
            <Typography align="left" sx={{ fontWeight: 500, color: 'text.black' }}>
              {item.quantity && (
                <Tooltip title="Stückzahl" placement="bottom-end" followCursor>
                  <Avatar className={classes.avatar}>{item.quantity}</Avatar>
                </Tooltip>
              )}{' '}
              {item.outerDiameter && `Ø${item.outerDiameter}`}
              {item.innerDiameter && `x Ø${item.innerDiameter}`}
              {item.width && `${item.width}`}
              {item.height && ` x ${item.height}`}
              {item.length && ` x ${item.length} mm `}
              {item.material && !isEmpty(item.material) ? (
                <Chip
                  label={`${item.material.name} | ${item.material.shortName} | ${item.material.number} | ${item.material.dinEn}`}
                  variant="outlined"
                />
              ) : (
                ''
              )}
              {item.certificate && `mit Zeugnis`}
              {item.articleNo && `Artikel ${item.articleNo}`}
              {item.articleNo && item.finished !== true && ` (Halb-FertigTeil)`}
              {item.description && `${item.description}`}
            </Typography>
          </TableCell>
        </TableRow>
      </Grid>
    </Grid>
  );
}

interface WarehouseItemsOutsourcedProps {
  handleRestoreWarehouseItem: (data: WarehouseItem) => void;
  outsourcedWarehouseItems: WarehouseItem[];
  outscourcedListOpen: boolean;
  setOutscourcedListOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setWarehouseItemToStore: React.Dispatch<React.SetStateAction<WarehouseItem>>;
  setNumPadOpen: (data: boolean) => void;
  handleSetCategory: any;
}

function WarehouseItemsOutsourced({
  handleRestoreWarehouseItem,
  outsourcedWarehouseItems,
  outscourcedListOpen,
  setOutscourcedListOpen,
  setWarehouseItemToStore,
  setNumPadOpen,
  handleSetCategory,
}: WarehouseItemsOutsourcedProps): JSX.Element {
  const classes = useStyles();

  function resetForm(): void {
    setWarehouseItemToStore({} as WarehouseItem);
    setNumPadOpen(false);
    handleSetCategory('');
  }

  return (
    <Accordion
      expanded={outscourcedListOpen}
      onClick={() => {
        setOutscourcedListOpen(!outscourcedListOpen);
        !outscourcedListOpen && resetForm();
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.title} />} className={classes.title}>
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="button" className={classes.title}>
              zuletzt ausgelagert...
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  {outsourcedWarehouseItems
                    // @ts-ignore
                    .sort((a, b) => new Date(b.dateOutsourced) - new Date(a.dateOutsourced))
                    .map((item, index) => (
                      <TableRowItem key={index} item={item} handleRestoreWarehouseItem={handleRestoreWarehouseItem} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default WarehouseItemsOutsourced;
