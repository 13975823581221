// Packages
import React, { useState, useEffect } from 'react';

// Interfaces
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';
import { Position as PositionData, File as FileData } from 'src/services/interfaces/position.interface';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Local Components
import DeliveryDate from './components/DeliveryDate';
import Files from './components/Files';
import Description from './components/Description';
import DrawNo from './components/DrawNo';
import DrawNoSearch from './components/DrawNoSearch';
import Service from './components/Service';
import SelectMaterial from './components/SelectedMaterial';
import Material from './components/Material';
import Text from './components/Text';
import Price from './components/Price';
import Submit from './components/Submit';
import Revision from './components/Revision';

interface Props {
  position: PositionData;
  openPositionDialog: boolean;
  setOpenPositionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  customer: CustomerData;
}

function PositionDialog({
  position: existingPosition,
  openPositionDialog,
  setOpenPositionDialog,
  customer,
}: Props): JSX.Element {
  const [position, setPosition] = useState({} as PositionData);
  const [revisions, setRevisions] = useState([] as PositionData[]);

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([] as FileData[]);

  useEffect(() => {
    if (existingPosition) {
      setPosition(existingPosition);
    } else {
      setPosition({ ...position, quantity: 1, revision: '0' });
    }
  }, []); // eslint-disable-line

  function handleUpdatePosition(event: { target: { name: any; value: any } }) {
    const { name, value } = event.target;
    setPosition({ ...position, [name]: value });
  }

  function handleClosePositionDialog() {
    setOpenPositionDialog(false);
  }

  return (
    <Dialog maxWidth="md" open={openPositionDialog} onClose={() => handleClosePositionDialog()}>
      <DialogTitle>Position {existingPosition ? 'bearbeiten' : 'hinzufügen'}</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container justifyContent="flex-start" spacing={2}>
          {!existingPosition && (
            <Grid item xs={12}>
              <DrawNoSearch setPosition={setPosition} customer={customer} setRevisions={setRevisions} />
            </Grid>
          )}
          <Grid item xs={12}>
            <DeliveryDate deliveryDate={position.deliveryDate} handleUpdatePosition={handleUpdatePosition} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Description description={position.description} handleUpdatePosition={handleUpdatePosition} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DrawNo position={position} setPosition={setPosition} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Revision
              position={position}
              setPosition={setPosition}
              existingPosition={existingPosition}
              revisions={revisions}
              setRevisions={setRevisions}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Service service={position.service} handleUpdatePosition={handleUpdatePosition} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectMaterial material={position.material} handleUpdatePosition={handleUpdatePosition} />
          </Grid>
          <Grid item xs={12}>
            <Material position={position} setPosition={setPosition} />
          </Grid>
          <Grid item xs={12}>
            <Text text={position.text} handleUpdatePosition={handleUpdatePosition} />
          </Grid>
          <Grid item xs={12}>
            <Price position={position} setPosition={setPosition} handleUpdatePosition={handleUpdatePosition} />
          </Grid>
          <Grid item xs={12}>
            <Files
              position={position}
              filesToUpload={filesToUpload}
              setFilesToUpload={setFilesToUpload}
              filesToDelete={filesToDelete}
              setFilesToDelete={setFilesToDelete}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClosePositionDialog()} color="primary">
          Abbrechen
        </Button>
        <Submit
          position={position}
          existingPosition={existingPosition}
          setOpenPositionDialog={setOpenPositionDialog}
          filesToUpload={filesToUpload}
          filesToDelete={filesToDelete}
        />
      </DialogActions>
    </Dialog>
  );
}

export default PositionDialog;
