// Packages
import React, { useState } from 'react';

// Interfaces
import { Position as PositionData } from 'src/services/interfaces/position.interface';

// MUI
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  position: PositionData;
  setPosition: React.Dispatch<React.SetStateAction<PositionData>>;
  existingPosition: PositionData;
  revisions: PositionData[];
  setRevisions: React.Dispatch<React.SetStateAction<PositionData[]>>;
}

function Revision({ position, setPosition, existingPosition, revisions, setRevisions }: Props): JSX.Element {
  const [isAddForm, setIsAddForm] = useState(false);
  const [newRevision, setNewRevision] = useState('');
  const [error, setError] = useState('');

  function handleClose() {
    setIsAddForm(false);
    setNewRevision('');
  }

  function handleAddNewRevision() {
    if (revisions.some((revision) => revision.revision === newRevision)) {
      setError('Revision bereits vorhanden');
    } else {
      setPosition({
        description: position.description,
        drawNo: position.drawNo,
        service: position.service,
        material: position.material,
        materialProvided: position.materialProvided,
        materialIncluded: position.materialIncluded,
        materialCertificate: position.materialCertificate,
        text: position.text,
        quantity: position.quantity,
        unit: position.unit,
        price: position.price,
        vatRate: position.vatRate,
        articleNumber: position.articleNumber,
        revision: newRevision,
      } as PositionData);
      setRevisions([...revisions, { ...position, revision: newRevision }]);
      setIsAddForm(false);
      setNewRevision('');
      setError('');
    }
  }

  return (
    <Grid container alignItems="center" spacing={1}>
      {position.articleNumber && !existingPosition && (
        <Grid item xs>
          <Grid item xs>
            <Autocomplete
              value={position || null}
              disableClearable
              onChange={(event, position) => {
                setPosition(position);
              }}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.revision}
              options={revisions}
              renderInput={(params) => <TextField {...params} label="Revision" variant="outlined" />}
            />
          </Grid>
        </Grid>
      )}
      {(!position.articleNumber || existingPosition) && (
        <Grid item xs>
          <TextField
            disabled={!!existingPosition}
            onChange={(event) => setPosition({ ...position, revision: event.target.value })}
            value={position.revision}
            label="Revision"
            variant="outlined"
            fullWidth
          />
        </Grid>
      )}
      {position.articleNumber && !existingPosition && (
        <Grid item>
          <IconButton onClick={() => setIsAddForm(!isAddForm)} size="large">
            <AddIcon />
          </IconButton>
        </Grid>
      )}
      {isAddForm && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                onChange={(event) => setNewRevision(event.target.value)}
                // value={newRevision.name}
                value={newRevision}
                label="Revision hinzufügen"
                variant="outlined"
                fullWidth
                error={!!error}
                helperText={error}
              />
            </Grid>

            <Grid item xs={6}>
              <Button onClick={handleClose} fullWidth variant="outlined">
                Abbrechen
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={!newRevision}
                onClick={handleAddNewRevision}
                color="secondary"
                fullWidth
                variant="outlined"
              >
                hinzufügen
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default Revision;
