// @ts-nocheck

// Packages
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

// Utils
import setAuthToken from 'src/utils/setAuthToken';

const cookies = new Cookies();

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    jwtToken: null,
    isAuthenticated: false,
    isCompany: false,
    isUser: false,
    company: {},
    user: {},
  },
  reducers: {
    signInUserReducer: (state, action) => {
      const jwtToken = action.payload;
      setAuthToken(jwtToken);
      cookies.set('jwtTokenUser', jwtToken, { path: '/' });

      state.jwtToken = jwtToken;
      state.isUser = true;
      state.isCompany = false;
      state.isAuthenticated = true;
    },
    signInCompanyReducer: (state, action) => {
      const jwtToken = action.payload;
      setAuthToken(jwtToken);
      cookies.set('jwtTokenCompany', jwtToken, { path: '/' });

      state.jwtToken = jwtToken;
      state.isUser = false;
      state.isCompany = true;
      state.isAuthenticated = true;
      state.user = {};
    },
    signOutReducer: (state) => {
      const jwtTokenUser = cookies.get('jwtTokenUser');
      const jwtTokenCompany = cookies.get('jwtTokenCompany');
      const isUser = jwtTokenCompany && jwtTokenUser;
      const isCompany = jwtTokenCompany && !jwtTokenUser;

      if (isUser) {
        cookies.remove('jwtTokenUser', { path: '/' });
        state.user = {};
        state.isUser = false;
        state.isCompany = true;
      }

      if (isCompany) {
        cookies.remove('jwtTokenCompany', { path: '/' });
        state.isAuthenticated = false;
        state.company = {};
        state.isCompany = false;
      }
    },
    setCurrentCompanyReducer: (state, action) => {
      state.company = action.payload;
    },
    setCurrentUserReducer: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {
  signInUserReducer,
  signInCompanyReducer,
  signOutReducer,
  setCurrentCompanyReducer,
  setCurrentUserReducer,
} = authSlice.actions;

export const auth = (state) => state.auth;

export default authSlice.reducer;
