// Packages
import React from 'react';

// Packages
import { Text, View } from '@react-pdf/renderer';

// Interfaces
import { Company as CompanyData } from 'src/services/interfaces/company.interface';

interface Props {
  company: CompanyData;
  footerHeight: number;
  companyName: CompanyData['companyName'];
  street: CompanyData['street'];
  houseNumber: CompanyData['houseNumber'];
  zip: CompanyData['zip'];
  city: CompanyData['city'];
  tel: CompanyData['tel'];
}

function Footer({ company, footerHeight, companyName, street, houseNumber, zip, city, tel }: Props): JSX.Element {
  return (
    <View
      fixed
      style={{
        height: footerHeight,
        margin: '0 20px',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        bottom: 0,
      }}
    >
      <View
        style={{
          marginBottom: 10,
          flexDirection: 'row',
          borderTop: 1,
          borderColor: 'lightgrey',
        }}
      >
        {/* 1st box */}
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Roboto', fontSize: 7, marginBottom: 3 }}>
            {`${companyName}\n`}
            {street} {`${houseNumber}\n`}
            {zip} {`${city}\n`}
            {tel}
          </Text>
        </View>

        {/* 2nd box */}
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Roboto', fontSize: 7, marginBottom: 3 }}>
            {company.imprintNotes && `${company.imprintNotes}\n`}
            {company.taxNumber && `Steuer-Nr.: ${company.taxNumber}\n`}
            {company.vatNumber && `USt-ID: ${company.vatNumber}`}
          </Text>
        </View>

        {/* 3rd box */}
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Roboto', fontSize: 7, marginBottom: 3 }}>
            {company.additionalNotes && company.additionalNotes}
          </Text>
        </View>

        {/* 4th box */}
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Roboto', fontSize: 7, marginBottom: 3 }}>
            Bankverbindung: {'\n'}
            {company.bankName && `${company.bankName}\n`}
            IBAN: {company.iban && `${company.iban}\n`}
            BIC: {company.bic && `${company.bic}\n`}
          </Text>
        </View>
      </View>
    </View>
  );
}

export default Footer;
