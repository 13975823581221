// @ts-nocheck

// Packages
import React, { useEffect, useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

function Modal({ isCreateInvoiceModal, setIsCreateInvoiceModal, handleCreateInvoice, unbilledOrders, toBeSettled }) {
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [paymentTerm, setPaymentTerm] = useState();

  useEffect(() => {
    const toBeSettledOrderIds = Object.keys(toBeSettled);
    const paymentTermArr = [
      ...new Set(
        unbilledOrders.filter((order) => toBeSettledOrderIds.includes(order._id)).map((order) => order.paymentTerm),
      ),
    ];
    setPaymentTerms(paymentTermArr);
    setPaymentTerm(paymentTermArr[0]);
  }, []); // eslint-disable-line

  return (
    <Dialog
      onClose={() => setIsCreateInvoiceModal(false)}
      aria-labelledby="simple-dialog-title"
      open={isCreateInvoiceModal}
    >
      <DialogTitle id="simple-dialog-title">Rechnung erstellen?</DialogTitle>
      <DialogContent>
        {paymentTerms.length > 1 && (
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Zahlungsbedingung</InputLabel>
            <Select
              fullWidth
              label="Zahlungsbedingung"
              value={paymentTerm}
              onChange={(e) => setPaymentTerm(e.target.value)}
            >
              {paymentTerms.map((paymentTerm) => (
                <MenuItem key={paymentTerm.name} value={paymentTerm}>
                  {paymentTerm.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsCreateInvoiceModal(false)} color="primary">
          Abbrechen
        </Button>
        <Button variant="contained" onClick={() => handleCreateInvoice(paymentTerm)} color="primary">
          Rechnung erstellen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal;
