// @ts-nocheck

// Packages
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Services
import { updatePackingList } from 'src/services/packingList';

// Redux
import { setAssignmentByAssignmentId } from 'src/slices/assignmentSlice';
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// MUI
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

function EditPackingListDialog({ assignment, isPackingListDialogOpen, setIsPackingListDialogOpen }) {
  const dispatch = useDispatch();
  const [packingList, setPackingList] = useState(assignment);

  async function handleUpdatePackingList() {
    try {
      const { data: updatedPackingList } = await updatePackingList({
        _id: packingList._id,
        deliveryCondition: packingList.deliveryCondition,
      });

      dispatch(setAssignmentByAssignmentId(updatedPackingList));
      dispatch(
        showSnackbarReducer({
          message: `Lieferschein wurde aktualisiert!`,
          severity: 'info',
        }),
      );
    } catch (error) {
      if (error) throw error;
    }
  }

  return (
    <Dialog open={isPackingListDialogOpen} onClose={() => setIsPackingListDialogOpen(false)}>
      <DialogTitle>Lieferschein bearbeiten</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Lieferkosten"
              name="deliveryCost"
              value={packingList.deliveryCondition.deliveryCost}
              onChange={(event) => {
                setPackingList({
                  ...packingList,
                  deliveryCondition: {
                    ...packingList.deliveryCondition,
                    deliveryCost: event.target.value,
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Paketdienst/Spedition"
              name="parcelServiceName"
              value={packingList.deliveryCondition.parcelServiceName}
              onChange={(event) => {
                setPackingList({
                  ...packingList,
                  deliveryCondition: {
                    ...packingList.deliveryCondition,
                    parcelServiceName: event.target.value,
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Sendungsnummer"
              name="parcelTrackingNumber"
              value={packingList.deliveryCondition.parcelTrackingNumber}
              onChange={(event) => {
                setPackingList({
                  ...packingList,
                  deliveryCondition: {
                    ...packingList.deliveryCondition,
                    parcelTrackingNumber: event.target.value,
                  },
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => setIsPackingListDialogOpen(false)} color="primary">
          Abbrechen
        </Button>
        <Button
          onClick={() => {
            handleUpdatePackingList();
            setIsPackingListDialogOpen(false);
          }}
          color="primary"
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditPackingListDialog;
