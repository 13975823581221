// @ts-nocheck
// Packages
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useParams } from 'react-router-dom';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Services
import { updatePurchaser } from 'src/services/purchaser';
import { createPurchaser } from 'src/services/purchaser';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Interfaces
import { Purchaser as PurchaserData } from 'src/services/interfaces/purchaser.interface';
import { Salutation as SalutationData } from 'src/services/interfaces/company.interface';

// MUI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
    textAlign: 'center',
  },
  closeButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  submitButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

interface Props {
  purchaser?: PurchaserData;
  purchasers: PurchaserData[];
  setPurchasers: React.Dispatch<React.SetStateAction<PurchaserData[]>>;
  openPurchaserDialog: boolean;
  setOpenPurchaserDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateBaseData: (data: any) => void;
}

function PurchaserDialog({
  openPurchaserDialog,
  setOpenPurchaserDialog,
  purchasers,
  setPurchasers,
  handleUpdateBaseData,
  purchaser,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();

  const purchaserAssignment = useSelector((state: RootStateOrAny) => state.assignment.assignmentForm.purchaser);
  const customerIdAssignment = useSelector((state: RootStateOrAny) => state.assignment.assignmentForm.customer._id);
  const { salutations }: { salutations: SalutationData[] } = useSelector((state: RootStateOrAny) => state.auth.company);
  const { customerId }: { customerId: string } = useParams();
  const [purchaserData, setPurchaserData] = useState({} as PurchaserData);
  const [indexSalutation, setIndexSalutation] = useState(0);
  const purchaserSelected = !isEmpty(purchaser) ? purchaser : purchaserAssignment;

  useEffect(() => {
    if (!isEmpty(purchaserSelected)) {
      setPurchaserData(purchaserSelected);
      setIndexSalutation(salutations.map((salutation) => salutation.id).indexOf(purchaserSelected.salutation.id));
    } else {
      setPurchaserData({ salutation: salutations[0] } as PurchaserData);
    }
    return () => setPurchaserData({} as PurchaserData);
  }, []); // eslint-disable-line

  function handleChangePurchaserData(event: { target: { name: any; value: any; type: any; checked: boolean } }) {
    const { name, value, type, checked } = event.target;
    setPurchaserData({ ...purchaserData, [name]: type !== 'checkbox' ? value : checked });
    if (name === 'salutation') {
      setIndexSalutation(salutations.map((salutation) => salutation.id).indexOf(value.id));
    }
  }

  function handleClosePurchaserDialog() {
    setPurchaserData({} as PurchaserData);
    setOpenPurchaserDialog(false);
  }

  function handleSubmit() {
    !isEmpty(purchaserSelected) ? handleUpdatePurchaser() : handleCreatePurchaser();
    handleClosePurchaserDialog();
  }

  async function handleCreatePurchaser() {
    try {
      const createdPurchaser = await createPurchaser({
        ...purchaserData,
        customer: customerId || customerIdAssignment,
      });
      setPurchasers([...purchasers, createdPurchaser].sort((a, b) => (a.name > b.name ? -1 : 1)));

      customerIdAssignment &&
        handleUpdateBaseData({
          name: 'purchaser',
          value: createdPurchaser,
        });

      dispatch(
        showSnackbarReducer({
          message: 'Bearbeiter wurde erstellt',
          severity: 'green',
        }),
      );
    } catch (error) {
      if (error) throw error;
    }
  }

  async function handleUpdatePurchaser() {
    try {
      await updatePurchaser(purchaserData);

      const index = purchasers.map((purchaser) => purchaser._id).indexOf(purchaserData._id);
      setPurchasers([...purchasers.slice(0, index), purchaserData, ...purchasers.slice(index + 1)]);

      customerIdAssignment && handleUpdateBaseData({ name: 'purchaser', value: purchaserData });

      dispatch(
        showSnackbarReducer({
          message: 'Bearbeiter wurde aktualisiert',
          severity: 'green',
        }),
      );
    } catch (error) {
      if (error) throw error;
    }
  }

  return (
    <Dialog maxWidth="xs" open={openPurchaserDialog} onClose={handleClosePurchaserDialog}>
      <Box textAlign="center">
        <DialogTitle className={classes.title}>
          Bearbeiter {!isEmpty(purchaserSelected) ? 'ändern' : 'hinzufügen'}
        </DialogTitle>
      </Box>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Anrede</InputLabel>
              <Select
                value={salutations[indexSalutation] || ''}
                onChange={handleChangePurchaserData}
                label="Anrede"
                name="salutation"
              >
                {salutations.map((salutation, index) => (
                  // @ts-ignore
                  <MenuItem value={salutation} key={index}>
                    {salutation.shortTitle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Name"
              type="text"
              value={purchaserData.name || ''}
              name="name"
              onChange={handleChangePurchaserData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="E-Mail"
              type="text"
              value={purchaserData.email || ''}
              name="email"
              onChange={handleChangePurchaserData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Telefon-Nr."
              type="text"
              value={purchaserData.tel || ''}
              name="tel"
              onChange={handleChangePurchaserData}
              fullWidth
            />
          </Grid>

          {purchaserData && (
            <Grid item mobile={12}>
              aktiv
              <ListItemIcon>
                <Switch
                  checked={!isEmpty(purchaserData.isActive) ? purchaserData.isActive : true}
                  name="isActive"
                  onChange={handleChangePurchaserData}
                />
              </ListItemIcon>
            </Grid>
          )}

          <Grid item xs={6}>
            <Button fullWidth onClick={handleClosePurchaserDialog} className={classes.closeButton}>
              Abbrechen
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth onClick={handleSubmit} className={classes.submitButton}>
              {!isEmpty(purchaserSelected) ? 'Aktualisieren' : 'Erstellen'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default PurchaserDialog;
