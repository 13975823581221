// @ts-nocheck
// Packages
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Services
import { createPdf } from 'src/services/pdf';
import { getOrdersByDeliveredUnbilledPositions } from 'src/services/order';
import { getCustomersByCompanyId } from 'src/services/customer';
import { createInvoice } from 'src/services/invoice';

// Global Components
import Pdf from 'src/components/Pdf';

// Interfaces
import { PaymentTerm as PaymentTermData } from 'src/services/interfaces/company.interface';
import { Invoice as InvoiceData } from 'src/services/interfaces/invoice.interface';
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';
import { Order as OrderData } from 'src/services/interfaces/order.interface';
import { Position as PositionData } from 'src/services/interfaces/position.interface';

// Local Components

// MUI
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { makeStyles } from '@mui/styles';
import { Modal, Row } from './components';

const useStyles = makeStyles((theme) => ({
  buttonInvoice: {
    color: 'white',
    backgroundColor: theme.palette.green.main,
    minHeight: '54px',
  },
  buttonPartial: {
    padding: '0',
  },
  buttonDelivery: {
    display: 'flex',
    minWidth: '24px',
    paddingLeft: '0',
  },
  typoHeader: {
    fontSize: 'large',
    color: theme.palette.blue.main,
    borderBottom: '8px',
    paddingLeft: '0',
    paddingRight: '24px',
  },
}));

function Invoice() {
  const history = useHistory();
  const classes = useStyles();
  const [unbilledOrders, setUnbilledOrders] = useState([] as OrderData[]);
  const [customers, setCustomers] = useState([] as CustomerData[]);
  const [customer, setCustomer] = useState({} as CustomerData);
  const [toBeSettled, setToBeSettled] = useState({});
  const [isCreateInvoiceModal, setIsCreateInvoiceModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const size = useWindowSize();
  const [showPartial, setShowPartial] = useState(false);

  useEffect(() => {
    getInitialData();
  }, []); // eslint-disable-line

  async function getInitialData() {
    const customers = await getCustomersByCompanyId();
    setCustomers(customers);
  }

  async function handleChangeCustomer(changedCustomer: CustomerData) {
    setCustomer(changedCustomer);

    const orders = await getOrdersByDeliveredUnbilledPositions({
      customerId: changedCustomer._id as string,
    });

    const ordersByDeliveredPositions = orders.filter((order) =>
      order.positions.some((position) => position.deliveryCompleted),
    );

    setUnbilledOrders(ordersByDeliveredPositions);
    setToBeSettled({});
  }

  function handlePositionOnToBeSettled(position: PositionData) {
    // if order id is already in toBeSettled object, then remove position id
    if (toBeSettled[position.order] && toBeSettled[position.order].includes(position._id)) {
      const index = toBeSettled[position.order].indexOf(position._id);

      const data = {
        [position.order]: [
          ...toBeSettled[position.order].slice(0, index),
          ...toBeSettled[position.order].slice(index + 1),
        ],
      };

      if (!data[position.order].length) {
        delete data[position.order];
        setToBeSettled({ ...data });
      } else {
        setToBeSettled({ ...toBeSettled, ...data });
      }
      // if order id is not in toBeSettled object, then add position id
    } else {
      const data = {
        [position.order]: toBeSettled[position.order] ? [...toBeSettled[position.order], position._id] : [position._id],
      };

      setToBeSettled({ ...toBeSettled, ...data });
    }
  }

  function handleOrderOnToBeSettled(order) {
    if (toBeSettled[order._id]) {
      const data = { ...toBeSettled };
      delete data[order._id];
      setToBeSettled({ ...data });
    } else {
      const data = {
        [order._id]: [...order.positions.map((position) => position._id)],
      };
      setToBeSettled({ ...toBeSettled, ...data });
    }
  }

  async function handleCreateInvoice(paymentTerm: PaymentTermData) {
    const data = { toBeSettled, customerId: customer._id, paymentTerm };
    const createdInvoice = await createInvoice(data);
    await handleCreateInvoicePdf(createdInvoice);
    history.push(`/assignment/details/invoice/${createdInvoice._id}`);
  }

  async function handleCreateInvoicePdf(createdInvoice: InvoiceData) {
    try {
      const { company, number, customer, positions, _id, dateCreated, paymentTerm } = createdInvoice;
      const blob = await pdf(
        <Pdf
          company={company}
          number={number}
          customer={customer}
          positions={positions}
          deliveryDate={dateCreated}
          paymentTerm={paymentTerm}
          isInvoice
        />,
      ).toBlob();

      const filename = `Rechnung-${number}.pdf`;
      const file = await new File([blob], filename, {
        lastModified: new Date().getTime(),
      });
      const formData = new FormData();
      formData.append('pdf', file);
      formData.append('invoice', _id);
      await createPdf(formData);
    } catch (error) {
      if (error) throw error;
    }
  }

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function useWindowSize() {
    // +++ copied from: https://usehooks.com/useWindowSize/ +++
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener('resize', handleResize);

      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
  }

  function handleSetAllOrders() {
    let allOrders;
    // eslint-disable-next-line array-callback-return
    unbilledOrders.map((order) => {
      const data = { [order._id]: [...order.positions.map((position) => position._id)] };
      allOrders = { ...allOrders, ...data };
    });
    setToBeSettled(allOrders);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item mobile={12} desktop={6}>
          <Autocomplete
            value={!isEmpty(customer) ? customer : undefined}
            disableClearable
            clearText="Enfernen"
            openText="Oeffnen"
            onChange={(event, customer) => {
              handleChangeCustomer(customer);
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={(option) => `${option.name} (${option.nameShort})`}
            options={customers}
            renderInput={(params) => <TextField {...params} label="Kunde" variant="outlined" />}
          />
        </Grid>
        {!isEmpty(customer) && (
          <Grid item mobile={12} desktop={6}>
            <Grid container justifyContent="flex-end">
              <Button
                fullWidth
                className={classes.buttonInvoice}
                onClick={() => setIsCreateInvoiceModal(true)}
                disabled={isEmpty(toBeSettled)}
                variant="contained"
              >
                Rechnung erstellen
              </Button>
            </Grid>
          </Grid>
        )}

        {!isEmpty(customer) && (
          <Grid item mobile={12} desktop={8}>
            {!isEmpty(unbilledOrders) ? (
              <>
                <Grid container>
                  <Grid item mobile={1} desktop={1}>
                    <Typography className={classes.typoHeader}>
                      <Tooltip title="Alle Rechnugnen aus/abwählen" placement="bottom-end" followCursor>
                        <Checkbox
                          onChange={(event) => {
                            if (event.target.checked) {
                              handleSetAllOrders();
                            } else {
                              setToBeSettled({});
                            }
                          }}
                        />
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item mobile={1} desktop={1} />
                  <Grid item mobile={5.5} desktop={3}>
                    <Typography variant="button" className={classes.typoHeader}>
                      Auftrag
                    </Typography>
                  </Grid>
                  <Grid item mobile={0} desktop={3} sx={{ display: { mobile: 'none', desktop: 'flex' } }}>
                    <Typography variant="button" className={classes.typoHeader}>
                      Bestellung
                    </Typography>
                  </Grid>
                  <Grid item mobile={0} desktop={2} sx={{ display: { mobile: 'none', desktop: 'block' } }}>
                    <Tooltip
                      title={`teilweise gelieferte Aufträge ${showPartial ? 'ausblenden' : 'anzeigen'}`}
                      placement="bottom-end"
                      followCursor
                    >
                      <Button
                        onClick={() => {
                          setShowPartial(!showPartial);
                        }}
                        className={classes.buttonPartial}
                      >
                        <Typography
                          sx={{ width: '100%' }}
                          variant="button"
                          color="text.blue"
                          fontSize="18px"
                          align="left"
                        >
                          geliefert
                        </Typography>
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item mobile={1} desktop={0} sx={{ display: { mobile: 'block', desktop: 'none' } }}>
                    <Button
                      fullwidth
                      onClick={() => {
                        setShowPartial(!showPartial);
                      }}
                      className={classes.buttonDelivery}
                    >
                      <LocalShippingIcon />
                    </Button>
                  </Grid>
                  <Grid item mobile={3.5} desktop={2}>
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <Typography align="right" variant="button" className={classes.typoHeader}>
                          Gesamt
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Box sx={{ maxHeight: size.height - 245, overflow: 'auto' }}>
                  {unbilledOrders
                    .filter((order) => (showPartial ? order : order.deliveryCompleted))
                    .map((order) => (
                      <Row
                        key={order._id}
                        order={order}
                        customer={customer}
                        toBeSettled={toBeSettled}
                        handlePositionOnToBeSettled={handlePositionOnToBeSettled}
                        handleOrderOnToBeSettled={handleOrderOnToBeSettled}
                        expanded={expanded === order._id}
                        handleExpanded={handleExpanded(order._id)}
                        showPartial={showPartial}
                      />
                    ))}
                </Box>
              </>
            ) : (
              <Typography variant="subtitle2" color="secondary">
                Es sind keine vollständig gelieferten Positionen vorhanden
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      {isCreateInvoiceModal && (
        <Modal
          isCreateInvoiceModal={isCreateInvoiceModal}
          setIsCreateInvoiceModal={setIsCreateInvoiceModal}
          handleCreateInvoice={handleCreateInvoice}
          unbilledOrders={unbilledOrders}
          toBeSettled={toBeSettled}
        />
      )}
    </>
  );
}

export default Invoice;
