// Packages
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';
import { signInUserReducer, setCurrentCompanyReducer, setCurrentUserReducer } from 'src/slices/authSlice';

// Services
import { signInUser } from 'src/services/auth';
import { getActiveUsersByCompanyId, updateUser, getCurrentUser } from 'src/services/user';
import { getCurrentCompany } from 'src/services/company';

// Interfaces
import { User as UserData } from 'src/services/interfaces/user.interface';

// Local Components
import Grid from '@mui/material/Grid';
import Modal from './components/Modal';

// MUI

function SignInUser() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAuthenticated, isUser, isCompany }: { isAuthenticated: boolean; isUser: boolean; isCompany: boolean } =
    useSelector((state: RootStateOrAny) => state.auth);
  const [users, setUsers] = useState([] as UserData[]);
  const [errors, setErrors] = useState({ password: '' });
  const [firstPassword, setFirstPassword] = useState('');

  const [signInUserData, setSignInUserData] = useState({
    _id: '',
    password: '',
    passwordStatus: '',
  });

  useEffect(() => {
    if (isAuthenticated && isUser) {
      history.push('/assignment/create');
    } else if (!isAuthenticated) {
      history.push('/sign-in-company');
    }
  }, [history, isAuthenticated, isUser]);

  useEffect(() => {
    if (users.length === 0 && isCompany) {
      handleGetActiveUsersByCompanyId();
    }
  }, [users.length, isAuthenticated, isCompany]); // eslint-disable-line

  useEffect(() => {
    handleGetCurrentCompany();
    handleGetActiveUsersByCompanyId();
  }, []);

  async function handleGetCurrentCompany() {
    const foundCompany = await getCurrentCompany();
    dispatch(setCurrentCompanyReducer(foundCompany));
  }

  async function handleGetCurrentUser() {
    const foundUser = await getCurrentUser();
    dispatch(setCurrentUserReducer(foundUser));
  }

  async function handleSubmit() {
    try {
      const jwtToken = await signInUser(signInUserData);
      dispatch(signInUserReducer(jwtToken));
      dispatch(
        showSnackbarReducer({
          message: 'SignInUser erfolgreich',
          severity: 'success',
        }),
      );
      handleGetCurrentUser();
      history.push('/assignment/create');
    } catch (error) {
      setSignInUserData({ ...signInUserData, password: '' });
      // @ts-ignore
      setErrors(error.response.data);
    }
  }

  async function handleResetPassword() {
    if (signInUserData.password.length === 4) {
      let snackbarMessage;
      let snackbarSeverity = 'success';
      let passwordStatus;

      if (signInUserData.passwordStatus !== 'saved') {
        passwordStatus = '1stInputOK';
        setFirstPassword(signInUserData.password);
      }

      if (signInUserData.passwordStatus === '1stInputOK') {
        if (firstPassword === signInUserData.password) {
          snackbarMessage = 'PIN wurde gespeichert. Zum einloggen neue PIN eingeben.';
          passwordStatus = 'saved';
        } else {
          passwordStatus = 'reseted';
          snackbarMessage = 'PINs sind nicht identisch, bitte nochmal eintippen';
          snackbarSeverity = 'error';
        }
      }

      await updateUser({ ...signInUserData, passwordStatus });
      setSignInUserData({ ...signInUserData, password: '' });
      handleGetActiveUsersByCompanyId();
      dispatch(
        showSnackbarReducer({
          message: snackbarMessage,
          severity: snackbarSeverity,
        }),
      );
    }
  }

  async function handleGetActiveUsersByCompanyId() {
    const foundUsers = await getActiveUsersByCompanyId();
    setUsers(foundUsers);
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={11} />
      {users.map((user: UserData) => (
        <React.Fragment key={user.personnelNumber}>
          {user.isActive && (
            <Grid item xs={2}>
              <Modal
                _id={user._id}
                firstName={user.firstName}
                lastName={user.lastName}
                personnelNumber={user.personnelNumber}
                passwordStatus={user.passwordStatus}
                signInUserData={signInUserData}
                setSignInUserData={setSignInUserData}
                errors={errors}
                handleSubmit={handleSubmit}
                handleResetPassword={handleResetPassword}
              />
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
}

export default SignInUser;
