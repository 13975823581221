// @ts-nocheck

// Packages
import React from 'react';

// Global Components
import { TextField } from 'src/components';

// Local Components

// MUI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

function CompanyData({ handleSubmit, companyData, setCompanyData }) {
  const handleChangeSettings = ({ target }) => {
    setCompanyData({ ...companyData, [target.name]: target.value });
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={9}>
          <TextField
            placeholder="Firmen-Name"
            label="Firmen-Name"
            name="companyName"
            value={companyData.companyName}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            placeholder="Abkürzung des Firmen-Namens"
            label="Abkürzung des Firmen-Namens"
            name="shortName"
            value={companyData.shortName}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            placeholder="Strasse"
            label="Strasse"
            name="street"
            value={companyData.street}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            placeholder="Haus-Nr."
            label="Haus-Nr."
            name="houseNumber"
            value={companyData.houseNumber}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            placeholder="PLZ"
            label="PLZ"
            name="zip"
            value={companyData.zip}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            placeholder="Ort"
            label="Ort"
            name="city"
            value={companyData.city}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            placeholder="Land"
            label="Land"
            name="country"
            value={companyData.country}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            placeholder="Telefon"
            label="Telefon"
            name="tel"
            value={companyData.tel}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            placeholder="Fax"
            label="Fax"
            name="fax"
            value={companyData.fax}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            placeholder="E-Mail"
            label="E-Mail"
            name="eMailContact"
            value={companyData.eMailContact}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            placeholder="Steuernummer"
            label="Steuernummer"
            name="taxNumber"
            value={companyData.taxNumber}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            placeholder="Umsatzsteuer-ID"
            label="Umsatzsteuer-ID"
            name="vatNumber"
            value={companyData.vatNumber}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            placeholder="Name der Bank"
            label="Name der Bank"
            name="bankName"
            value={companyData.bankName}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            placeholder="IBAN"
            label="IBAN"
            name="iban"
            value={companyData.iban}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            placeholder="BIC"
            label="BIC"
            name="bic"
            value={companyData.bic}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            placeholder="Impressum"
            label="Impressum"
            name="imprintNotes"
            value={companyData.imprintNotes}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            placeholder="Zusätzliche Angaben"
            label="Zusätzliche Angaben"
            name="additionalNotes"
            value={companyData.additionalNotes}
            onChange={handleChangeSettings}
            margin={undefined}
            color={undefined}
            type={undefined}
            error={undefined}
            multiline={undefined}
            rowsMax={undefined}
            rows={undefined}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            onClick={() => handleSubmit(companyData, 'Daten wurde erfolgreich gespeichert', 'success')}
            variant="contained"
            color="green"
          >
            Speichern
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default CompanyData;
