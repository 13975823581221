// @ts-nocheck

// Packages
import React, { useState } from 'react';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paper: { padding: '20px', margin: '10px 0 10px 0', backgroundColor: theme.palette.lightGreen.main },
  grid: {
    width: '410px',
    padding: '16px',
    margin: '15px 0 0 15px',
    backgroundColor: theme.palette.black.main,
    color: 'white',
  },
  content: { width: '400px', padding: '30px 0px 10px 0px' },
  typoBold: { fontWeight: 600 },
  typoGrey: { color: theme.palette.lightGrey.main },
  typoBoldGrey: { fontWeight: 600, color: theme.palette.lightGrey.main },
}));

function PartialDeliveryDialog({
  type,
  position,
  handleAddPositionToPackingList,
  handleUpdatePositionOnPackingList,
  isPartialDeliveryDialogOpen,
  setIsPartialDeliveryDialogOpen,
}) {
  const classes = useStyles();
  const [quantityToDeliver, setQuantityToDeliver] = useState(position.quantityAdded || 1);

  function handleChangeQuantity(_, value) {
    setQuantityToDeliver(value);
  }

  function handleSubmit() {
    if (type === 'add') {
      handleAddPositionToPackingList(position, quantityToDeliver);
    }

    if (type === 'update') {
      handleUpdatePositionOnPackingList(position, quantityToDeliver);
    }
  }

  return (
    <Dialog maxWidth="md" open={isPartialDeliveryDialogOpen} onClose={() => setIsPartialDeliveryDialogOpen(false)}>
      <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
        <Grid item className={classes.grid}>
          <Typography variant="h4" align="center">
            Liefer-Menge
          </Typography>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <Typography variant="h4" align="center">
              Pos.{position.number}
            </Typography>
            <Typography variant="body1" className={classes.typoBold}>
              {position.description}
            </Typography>

            <Typography variant="body2" className={classes.typoBoldGrey}>
              nach Zeichnung {position.drawNo} {position.service}
            </Typography>
            <Typography variant="body2" className={classes.typoGrey}>
              {position.text}
            </Typography>

            <Typography variant="body2" className={classes.typoGrey}>
              {position.materialProvided && 'aus kundenseitig beigestelltem Rohmaterial '}
              {position.materialIncluded && 'inkl. Material '} {position.materialCertificate && 'und Zeugnis \n'}
            </Typography>

            {position.material && (
              <Typography
                variant="caption"
                className={classes.typoGrey}
              >{`(${position.material.name} | ${position.material.shortName} | ${position.material.number} | ${position.material.dinEn})`}</Typography>
            )}
          </Paper>
        </Grid>

        {position.quantity - position.quantityDelivered > 1 && (
          <Grid item>
            <DialogContent className={classes.content}>
              <Stack direction="row" alignItems="center">
                <Button
                  color="blue"
                  onClick={() => setQuantityToDeliver(quantityToDeliver - 1)}
                  disabled={quantityToDeliver <= 1}
                >
                  <IndeterminateCheckBoxIcon />
                </Button>

                <Slider
                  marks
                  valueLabelDisplay="on"
                  value={quantityToDeliver}
                  onChange={handleChangeQuantity}
                  min={1}
                  max={position.quantity - position.quantityDelivered}
                  step={1}
                  color={quantityToDeliver < position.quantity - position.quantityDelivered ? 'blue' : 'green'}
                />

                <Button
                  color="blue"
                  onClick={() => setQuantityToDeliver(quantityToDeliver + 1)}
                  disabled={quantityToDeliver >= position.quantity - position.quantityDelivered}
                >
                  <AddBoxIcon color="green" />
                </Button>
              </Stack>
            </DialogContent>
          </Grid>
        )}

        <Grid item>
          <DialogActions>
            {position.quantity - position.quantityDelivered > 1 && (
              <Button
                disabled={!(quantityToDeliver < position.quantity)}
                onClick={() => {
                  handleSubmit();
                  setIsPartialDeliveryDialogOpen(false);
                }}
                variant="contained"
                color="blue"
              >
                {`Teil-Lieferung (${quantityToDeliver}x)`}
              </Button>
            )}
            <Button
              onClick={() => {
                handleAddPositionToPackingList(position, position.quantity - position.quantityDelivered);
                setIsPartialDeliveryDialogOpen(false);
              }}
              variant="contained"
              color="green"
            >
              Vollständig liefern
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default PartialDeliveryDialog;
