// Packages
import React from 'react';

// MUI
import TextField from '@mui/material/TextField';

interface Props {
  description: string;
  handleUpdatePosition: (event: any) => void;
}

function Description({ description, handleUpdatePosition }: Props): JSX.Element {
  return (
    <TextField
      variant="outlined"
      label="Bezeichnung"
      value={description || ''}
      name="description"
      onChange={handleUpdatePosition}
      fullWidth
    />
  );
}

export default Description;
