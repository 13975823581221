// @ts-nocheck

// Packages
import React from 'react';
import { useSelector } from 'react-redux';

// Local Components
import Grid from '@mui/material/Grid';
import BaseData from './components/BaseData';
import Positions from './components/Positions';

// MUI

function AssignmentDetails(): JSX.Element {
  const { assignmentByAssignmentId } = useSelector((state) => state.assignment);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BaseData assignment={assignmentByAssignmentId} />
      </Grid>
      <Grid item xs={12}>
        <Positions assignment={assignmentByAssignmentId} />
      </Grid>
    </Grid>
  );
}

export default AssignmentDetails;
