// Packages
import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Services
import { updateCustomer, createCustomer, getNextFreeCustomerNumberByCompanyId } from 'src/services/customer';
import { checkDuplicateCustomerNumberByCompanyId } from 'src/services/customer';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Interfaces
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';
import {
  Company as CompanyData,
  DeliveryTerm as DeliveryTermData,
  PaymentTerm as PaymentTermData,
} from 'src/services/interfaces/company.interface';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  closeButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  submitButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

interface Props {
  isDialogOpen: boolean;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  existingCustomer?: CustomerData;
  setExistingCustomer?: React.Dispatch<React.SetStateAction<CustomerData>>;
}

function CustomerForm({ isDialogOpen, setIsDialogOpen, existingCustomer, setExistingCustomer }: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState(existingCustomer || ({} as CustomerData));
  const {
    deliveryTerms,
    paymentTerms,
    maxCustomerNumber,
  }: {
    deliveryTerms: DeliveryTermData[];
    paymentTerms: PaymentTermData[];
    maxCustomerNumber: CompanyData['maxCustomerNumber'];
  } = useSelector((state: RootStateOrAny) => state.auth.company);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!existingCustomer) {
      setInitialData();
    }
  }, []); // eslint-disable-line

  async function setInitialData() {
    const freeCustomerNumber = await getNextFreeCustomerNumberByCompanyId({ number: maxCustomerNumber });
    setCustomer({
      ...customer,
      number: freeCustomerNumber,
      defaultDeliveryTerm: deliveryTerms[0],
      defaultPaymentTerm: paymentTerms[0],
    });
  }

  function handleUpdateCustomer(event: { target: { name: any; value: any } }) {
    const { name, value } = event.target;
    setCustomer({ ...customer, [name]: value });
  }

  function handleCloseBaseDataDialog() {
    setCustomer(customer);
    setIsDialogOpen(false);
  }

  async function handleUpdateOrCreateCustomer() {
    try {
      setIsLoading(true);
      const updateCustomerData: CustomerData = {
        ...customer,
        name: customer.name,
        nameShort: customer.nameShort,
        number: customer.number,
        tel: customer.tel,
        fax: customer.fax,
        email: customer.email,
        emailConfirmation: customer.emailConfirmation,
        emailInvoice: customer.emailInvoice,
        defaultDeliveryTerm: customer.defaultDeliveryTerm,
        defaultPaymentTerm: customer.defaultPaymentTerm,
      };

      if (existingCustomer && setExistingCustomer) {
        const updatedCustomer = await updateCustomer(updateCustomerData);
        setExistingCustomer(updatedCustomer);
      } else {
        const createdCustomer = await createCustomer(updateCustomerData);
        history.push(`/customer/details/${createdCustomer._id}`);
      }

      setIsDialogOpen(false);

      dispatch(
        showSnackbarReducer({
          message: 'Stammdaten wurden aktualisiert',
          severity: 'success',
        }),
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error) throw error;
    }
  }

  function handleChangeNumber(event: { target: { name: any; value: any } }) {
    const { name, value } = event.target;

    if (!isNaN(value)) {
      setCustomer({ ...customer, [name]: value });
    }

    if (value.length === 4) {
      checkNewManualCustomerNumber(value);
    }
  }

  const checkNewManualCustomerNumber = async (manualNumber: string) => {
    try {
      let message = '';
      let severity = '';
      const isOldNumber = manualNumber === customer.number;
      const resetedCustomer = {
        ...customer,
        number: manualNumber.substring(0, 3),
      };

      if (isOldNumber) {
        message = `Die Kunden-Nummer entspricht der alten Kunden-Nummer`;
        severity = 'info';

        dispatch(showSnackbarReducer({ message, severity }));
        return;
      }

      const isDuplicate = await checkDuplicateCustomerNumberByCompanyId({
        number: manualNumber,
      });

      if (isDuplicate) {
        message = `Die Kunden-Nummer ${manualNumber} ist bereits vergeben`;
        severity = 'error';

        dispatch(showSnackbarReducer({ message, severity }));
        setCustomer(resetedCustomer);
      }
    } catch (error) {
      if (error) throw error;
    }
  };

  return (
    <Dialog maxWidth="md" open={isDialogOpen} onClose={() => handleCloseBaseDataDialog()}>
      <DialogTitle className={classes.title}>
        <Typography component="div" variant="h5" align="center">
          Kunden-Stammdaten {existingCustomer ? 'bearbeiten' : 'eintragen'}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
          <Grid item xs={12} md={8}>
            <TextField
              variant="outlined"
              label="Firmen-Name"
              type="text"
              value={customer.name}
              name="name"
              onChange={handleUpdateCustomer}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              variant="outlined"
              label="Kürzel"
              type="text"
              value={customer.nameShort}
              name="nameShort"
              onChange={handleUpdateCustomer}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              variant="outlined"
              label="Nummer"
              type="text"
              value={customer.number || ''}
              name="number"
              onChange={handleChangeNumber}
              fullWidth
              inputProps={{ maxLength: 4 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              label="Telefon"
              type="text"
              value={customer.tel}
              name="tel"
              onChange={handleUpdateCustomer}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              label="Fax"
              type="text"
              value={customer.fax}
              name="fax"
              onChange={handleUpdateCustomer}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="zentrale E-Mail"
              type="text"
              value={customer.email}
              name="email"
              onChange={handleUpdateCustomer}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="E-Mail für Auftrags-Versand"
              type="text"
              value={customer.emailConfirmation}
              name="emailConfirmation"
              onChange={handleUpdateCustomer}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="E-Mail für Rechnungs-Versand"
              type="text"
              value={customer.emailInvoice}
              name="emailInvoice"
              onChange={handleUpdateCustomer}
              fullWidth
            />
          </Grid>

          {!isEmpty(deliveryTerms) ? (
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Standard-Lieferbedingung</InputLabel>
                <Select
                  label="Standard-Lieferbedingung"
                  value={
                    customer.defaultDeliveryTerm
                      ? deliveryTerms[
                          customer.defaultDeliveryTerm
                            ? deliveryTerms
                                .map((deliveryTerm) => deliveryTerm.incoterm)
                                .indexOf(customer.defaultDeliveryTerm.incoterm)
                            : 0
                        ]
                      : ''
                  }
                  name="defaultDeliveryTerm"
                  onChange={handleUpdateCustomer}
                >
                  {deliveryTerms.map((deliveryTerm, index) => (
                    // @ts-ignore
                    <MenuItem value={deliveryTerm} key={index}>
                      {deliveryTerm.meaning} ({deliveryTerm.incoterm})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Typography variant="caption" color="secondary">
              keine Lieferbedingungen hinterlegt
            </Typography>
          )}

          {!isEmpty(paymentTerms) ? (
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Standard-Zahlungsbedingung</InputLabel>
                <Select
                  label="Standard-Zahlungsbedingung"
                  value={
                    customer.defaultPaymentTerm
                      ? paymentTerms[
                          customer.defaultPaymentTerm
                            ? paymentTerms
                                .map((paymentTerm) => paymentTerm.name)
                                .indexOf(customer.defaultPaymentTerm.name)
                            : 0
                        ]
                      : ''
                  }
                  name="defaultPaymentTerm"
                  onChange={handleUpdateCustomer}
                >
                  {paymentTerms.map((paymentTerm, index) => (
                    // @ts-ignore
                    <MenuItem value={paymentTerm} key={index}>
                      {paymentTerm.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Typography variant="caption" color="secondary">
              keine Zahlungsbedingungen hinterlegt
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          fullWidth
          onClick={() => handleCloseBaseDataDialog()}
          variant="contained"
          className={classes.closeButton}
        >
          Abbrechen
        </Button>
        <Button
          disabled={isLoading || !customer.name}
          fullWidth
          onClick={handleUpdateOrCreateCustomer}
          variant="contained"
          className={classes.submitButton}
        >
          {existingCustomer ? 'Aktualisieren' : 'Speichern'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomerForm;
