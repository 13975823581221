// Packages
import React from 'react';

// Packages
import { Route, Redirect } from 'react-router-dom';
import { useSelector, RootStateOrAny } from 'react-redux';

function PrivateRoute({ component: Component, ...rest }: any) {
  const { isAuthenticated, isUser } = useSelector((state: RootStateOrAny) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated && isUser ? <Component {...props} /> : <Redirect to="/sign-in-user" />)}
    />
  );
}

export default PrivateRoute;
