// Packages
import React from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';

// MUI
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

// Redux
import { closeSnackbarReducer } from 'src/slices/snackbarSlice';

function ShowSnackbar() {
  const dispatch = useDispatch();
  const { message, severity, permanent } = useSelector((state: RootStateOrAny) => state.snackbar);

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(closeSnackbarReducer());
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return permanent ? (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={Boolean(message)}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  ) : (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={Boolean(message)}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default ShowSnackbar;
