// Packages
import React from 'react';

// MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';

// React-Icons
import { BsCircleFill, BsCircle, BsBoxSeam, BsFillHexagonFill, BsSquareFill, BsTrophy } from 'react-icons/bs';
import { BiSearchAlt2 } from 'react-icons/bi';
import { CgSoftwareDownload } from 'react-icons/cg';

import { WarehouseItem } from 'src/services/interfaces/warehouseItem.interface';
import { WarehouseCategoryData } from '../../_Warehouse';

const useStyles = makeStyles((theme) => ({
  red: { color: theme.palette.red.main },
  grey: { color: theme.palette.lightGrey.main },
  blue: { color: theme.palette.blue.main },
}));

interface Props {
  tabValue: number;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  category: WarehouseCategoryData;
  handleSetCategory: (category: WarehouseCategoryData) => void;
  handleResetWarehouseItemToStore: () => void;
  handleResetWarehouseItemToFind: () => void;
  warehouseItemToStore: WarehouseItem;
}

function WarehouseItemFind({
  tabValue,
  setTabValue,
  category,
  handleSetCategory,
  handleResetWarehouseItemToStore,
  handleResetWarehouseItemToFind,
  warehouseItemToStore,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Grid container spacing={2} sx={{ display: { mobile: 'none', desktop: 'block' } }}>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={6}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography
                    variant="button"
                    className={
                      category === 'round' || category === 'tube' || category === 'hexagon' || category === 'flat'
                        ? classes.red
                        : classes.grey
                    }
                  >
                    Rohmaterial
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <IconButton
                    onClick={() => {
                      !warehouseItemToStore.isOutsourced && handleResetWarehouseItemToStore();
                      handleResetWarehouseItemToFind();
                      handleSetCategory('round');
                    }}
                  >
                    <BsCircleFill fontSize="5rem" className={category === 'round' ? classes.red : classes.grey} />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <IconButton
                    onClick={() => {
                      !warehouseItemToStore.isOutsourced && handleResetWarehouseItemToStore();
                      handleResetWarehouseItemToFind();
                      handleSetCategory('tube');
                    }}
                  >
                    <BsCircle fontSize="5rem" className={category === 'tube' ? classes.red : classes.grey} />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <IconButton
                    onClick={() => {
                      !warehouseItemToStore.isOutsourced && handleResetWarehouseItemToStore();
                      handleResetWarehouseItemToFind();
                      handleSetCategory('hexagon');
                    }}
                  >
                    <BsFillHexagonFill
                      fontSize="5rem"
                      className={category === 'hexagon' ? classes.red : classes.grey}
                    />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <IconButton
                    onClick={() => {
                      !warehouseItemToStore.isOutsourced && handleResetWarehouseItemToStore();
                      handleResetWarehouseItemToFind();
                      handleSetCategory('flat');
                    }}
                  >
                    <BsSquareFill fontSize="5rem" className={category === 'flat' ? classes.red : classes.grey} />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="button" className={category === 'round' ? classes.red : classes.grey}>
                    Rund
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="button" className={category === 'tube' ? classes.red : classes.grey}>
                    Rohr
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="button" className={category === 'hexagon' ? classes.red : classes.grey}>
                    Sechskant
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="button" className={category === 'flat' ? classes.red : classes.grey}>
                    Flach
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="button" className={category === 'article' ? classes.red : classes.grey}>
                  Artikel
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <IconButton
                  onClick={() => {
                    !warehouseItemToStore.isOutsourced && handleResetWarehouseItemToStore();
                    handleSetCategory('article');
                  }}
                >
                  <BsTrophy fontSize="5rem" className={category === 'article' ? classes.red : classes.grey} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="button" className={category === 'diverse' ? classes.red : classes.grey}>
                  Diverses
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <IconButton
                  onClick={() => {
                    !warehouseItemToStore.isOutsourced && handleResetWarehouseItemToStore();
                    handleSetCategory('diverse');
                  }}
                >
                  <BsBoxSeam fontSize="5rem" className={category === 'diverse' ? classes.red : classes.grey} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="button" className={classes.blue}>
                  {tabValue === 0 ? 'Finden' : 'Einlagern'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <IconButton onClick={() => (tabValue === 0 ? setTabValue(1) : setTabValue(0))}>
                  {tabValue === 0 ? (
                    <BiSearchAlt2 fontSize="5rem" className={classes.blue} />
                  ) : (
                    <CgSoftwareDownload fontSize="5rem" className={classes.blue} />
                  )}
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WarehouseItemFind;
