// Packages
import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import moment from 'moment';

// Interfaces
import { Company as CompanyData, DeliveryTerm as DeliveryTermData } from 'src/services/interfaces/company.interface';
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';
import { Purchaser as PurchaserData } from 'src/services/interfaces/purchaser.interface';

interface Props {
  companyName: CompanyData['companyName'];
  street: CompanyData['street'];
  houseNumber: CompanyData['houseNumber'];
  zip: CompanyData['zip'];
  city: CompanyData['city'];
  tel: CompanyData['tel'];
  country: CompanyData['country'];
  eMailContact: CompanyData['eMailContact'];
  customer: CustomerData;
  purchaser: PurchaserData;
  customersNoForOffer: string;
  customersNoForOrder: string;
  deliveryDate: string;
  deliveryTerm: DeliveryTermData;
  number: string;
  revision: number;
  isOffer: boolean;
  isOrder: boolean;
  isPackingList: boolean;
  isInvoice: boolean;
}

function Header({
  companyName,
  street,
  houseNumber,
  zip,
  city,
  tel,
  country,
  eMailContact,
  customer,
  purchaser,
  customersNoForOffer,
  customersNoForOrder,
  deliveryDate,
  deliveryTerm,
  number,
  revision,
  isOffer,
  isOrder,
  isPackingList,
  isInvoice,
}: Props): JSX.Element {
  function getCustomerAddress() {
    if (
      isPackingList &&
      customer.addresses &&
      customer.addresses.map((address) => address.usage).includes('delivery')
    ) {
      return customer.addresses.filter((address) => address.usage === 'delivery')[0];
    }
    if (isInvoice && customer.addresses && customer.addresses.map((address) => address.usage).includes('invoice')) {
      return customer.addresses.filter((address) => address.usage === 'invoice')[0];
    }
    return customer.addresses && customer.addresses.filter((address) => address.usage === 'home')[0];
  }

  return (
    <>
      <View
        style={{
          margin: 10,
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'right',
        }}
      >
        <Text style={{ fontFamily: 'Roboto', fontSize: 10, fontWeight: 500, marginBottom: 5 }}>
          {companyName} {'\n'}
        </Text>
        <Text style={{ fontFamily: 'Roboto', fontSize: 10, marginBottom: 5 }}>
          {street} {houseNumber} {'\n'}
          {zip} {city} {'\n'}
          {country} {'\n'}
        </Text>
        <Text style={{ fontFamily: 'Roboto', fontSize: 10, marginBottom: 5 }}>
          {tel} {'\n'}
          {eMailContact && `${eMailContact}\n`}
        </Text>
      </View>

      {/* leftSide: Customer | rightSide: AssignmentData */}
      <View
        style={{
          margin: 0,
          padding: 0,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {/* Customer (sender & consignee) */}
        <View
          style={{
            margin: 10,
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            width: '65%',
          }}
        >
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: 5,
              textDecoration: 'underline',
              marginBottom: 3,
            }}
          >
            {`${companyName} ${street && `, ${street}`} ${houseNumber && `, ${houseNumber}`} ${zip && `, ${zip}`} ${
              city && `, ${city}`
            } ${country && `, ${country}`}`}
          </Text>
          <Text style={{ fontFamily: 'Roboto', fontSize: 10, fontWeight: 500, marginBottom: 5 }}>
            {customer.name && `${customer.name}\n`}
          </Text>
          {customer.addresses && customer.addresses.length > 0 && (
            <Text style={{ fontFamily: 'Roboto', fontSize: 10, marginBottom: 5 }}>
              {/* @ts-ignore */}
              {getCustomerAddress().street && `${getCustomerAddress().street} `}
              {/* @ts-ignore */}
              {getCustomerAddress().houseNumber && `${getCustomerAddress().houseNumber}\n`}
              {/* @ts-ignore */}
              {getCustomerAddress().zip && `${getCustomerAddress().zip} `}
              {/* @ts-ignore */}
              {getCustomerAddress().city && `${getCustomerAddress().city}\n`}
              {/* @ts-ignore */}
              {getCustomerAddress().country && `${getCustomerAddress().country}\n`}
            </Text>
          )}
        </View>

        {/* AssignmentData */}
        <View
          style={{
            margin: 10,
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            width: '35%',
          }}
        >
          {/* Offer and Order Assignment */}
          {(isOffer || isOrder) && (
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                <Text style={{ fontFamily: 'Roboto', fontSize: 10, marginBottom: 5 }}>
                  {customersNoForOffer && 'Anfrage-Nr.: \n'}
                  {customersNoForOrder && 'Bestell-Nr.: \n'}
                  {purchaser && `${purchaser.salutation.gender === 'Frau' ? 'Bearbeiterin' : 'Bearbeiter'}: `}
                  {deliveryDate && `Lieferdatum: \n`}
                  {deliveryTerm && `Lieferung: \n`}
                  Kunden-Nr.: {'\n'}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'column', width: '65%' }}>
                <Text style={{ fontFamily: 'Roboto', fontSize: 10, marginBottom: 5 }}>
                  {(customersNoForOrder && `${customersNoForOrder}\n`) ||
                    (customersNoForOffer && `${customersNoForOffer}\n`)}
                  {purchaser && `${purchaser.salutation.shortTitle} ${purchaser.name} ${'\n'}`}
                  {deliveryDate && `${moment(deliveryDate).format('DD.MM.YYYY')}\n`}
                  {deliveryTerm && `${deliveryTerm.meaning}\n`}
                  {`${customer.number}\n`}
                </Text>
              </View>
            </View>
          )}

          {/* Invoice Assignment */}
          {isInvoice && (
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                <Text style={{ fontFamily: 'Roboto', fontSize: 10, marginBottom: 5 }}>
                  Datum: {'\n'}
                  Kunden-Nr.: {'\n'}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'column', width: '65%' }}>
                <Text style={{ fontFamily: 'Roboto', fontSize: 10, marginBottom: 5 }}>
                  {`${moment(deliveryDate).format('DD.MM.YYYY')}\n`}
                  {`${customer.number}\n`}
                </Text>
              </View>
            </View>
          )}

          {/* PackingList Assignment */}
          {isPackingList && (
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                <Text style={{ fontFamily: 'Roboto', fontSize: 10, marginBottom: 5 }}>
                  Lieferdatum: {'\n'}
                  Kunden-Nr.: {'\n'}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'column', width: '65%' }}>
                <Text style={{ fontFamily: 'Roboto', fontSize: 10, marginBottom: 5 }}>
                  {`${moment(deliveryDate).format('DD.MM.YYYY')}\n`}
                  {`${customer.number}\n`}
                </Text>
              </View>
            </View>
          )}

          <Text
            style={{ fontFamily: 'Roboto', fontSize: 10, fontWeight: 500, marginBottom: 5 }}
            render={({ pageNumber, totalPages }) => `Seite ${pageNumber} / ${totalPages}`}
            fixed
          />
        </View>
      </View>

      {/* Title */}
      <View
        style={{
          margin: 10,
          padding: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Text
          style={{
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: 15,
            marginBottom: 10,
          }}
        >
          {isOffer && `Angebot  ${number}`}
          {isOrder && `Auftrag  ${number}`}
          {isPackingList && `Lieferschein ${number}`}
          {isInvoice && `Rechnung ${number}`}
          {(isOffer || isOrder) && revision >= 1 && `-${revision}`}
        </Text>
      </View>
    </>
  );
}

export default Header;
