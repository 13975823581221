// Packages
import React from 'react';

// Packages
import { Text, View } from '@react-pdf/renderer';

// Interfaces
import { Position as PositionData } from 'src/services/interfaces/position.interface';
import { PackingList as PackingListData } from 'src/services/interfaces/packingList.interface';

interface Props {
  positions: PositionData[];
  deliveryCondition: PackingListData['deliveryCondition'];
}

function Positions({ positions, deliveryCondition }: Props): JSX.Element {
  return (
    <>
      <View
        style={{
          margin: 10,
          padding: 10,
          flexDirection: 'row',
          borderBottom: 1,
          borderBottomColor: 'lightgrey',
        }}
      >
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: 10,
            fontWeight: 600,
            width: '5%',
            textAlign: 'left',
          }}
        >
          Pos
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: 10,
            fontWeight: 600,
            width: '85%',
            textAlign: 'left',
          }}
        >
          Artikel
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: 10,
            fontWeight: 600,
            width: '10%',
            textAlign: 'right',
          }}
        >
          Menge
        </Text>
      </View>
      <View
        style={{
          margin: '0 10 0 10',
          padding: '0 10 0 10',
          flexDirection: 'column',
        }}
      >
        {positions.map((position, index) => {
          const {
            number,
            unit,
            description,
            drawNo,
            service,
            text,
            material,
            materialProvided,
            materialIncluded,
            materialCertificate,
            order,
            // @ts-ignore
            quantityByPackingList,
          } = position;

          return (
            <View wrap={false} key={position.number + index} style={{ flexDirection: 'row', marginBottom: 10 }}>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: 10,
                  fontWeight: 600,
                  width: '5%',
                  textAlign: 'left',
                }}
              >
                {index + 1}
              </Text>
              <View style={{ width: '85%' }}>
                <Text style={{ fontFamily: 'Roboto', fontSize: 10, fontWeight: 600 }}>{description}</Text>
                {!position.isDeliveryCost && (
                  <Text style={{ fontFamily: 'Roboto', fontSize: 10 }}>
                    nach Zeichnung {drawNo} {service}
                  </Text>
                )}
                {!position.isDeliveryCost && (
                  <>
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: 10,
                        fontWeight: 300,
                      }}
                    >
                      {`${text}\n`}
                      {materialProvided && 'aus kundenseitig beigestelltem Rohmaterial '}
                      {materialIncluded && 'inkl. Material '}
                      {materialCertificate && 'und Zeugnis \n'}
                    </Text>
                    {material && (
                      <Text
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: 10,
                          fontWeight: 300,
                        }}
                      >{`(Werkstoff: ${material.name} | ${material.shortName} | ${material.number} | ${material.dinEn})`}</Text>
                    )}
                  </>
                )}
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: 10,
                    fontWeight: 300,
                    color: 'red',
                  }}
                >
                  zu ihrer Bestell-Nr. {order.customersNoForOrder}, Pos. {number}{' '}
                  {order.purchaser && `(bestellt von ${order.purchaser.salutation.shortTitle} ${order.purchaser.name})`}
                </Text>
              </View>
              <Text style={{ fontFamily: 'Roboto', fontSize: 10, width: '10%', textAlign: 'right' }}>
                {quantityByPackingList} {unit.shortName}
              </Text>
            </View>
          );
        })}

        {/* Signature fields */}
        <View wrap={false} style={{ borderTop: 1, paddingTop: 20 }}>
          <Text style={{ fontFamily: 'Roboto', fontSize: 10 }}>
            Lieferbedingung: {deliveryCondition.deliveryTerm.deliveryService ? 'Paketversand' : 'ab Werk (Abholung)'}
          </Text>
          <Text style={{ fontFamily: 'Roboto', fontSize: 10, marginBottom: 20 }}>
            Alle von uns gelieferten Artikel unterliegen dem verlängerten Eigentumsvorbehalt. Die Reklamationsfrist
            beträgt 14 Tage.
          </Text>
          <Text style={{ fontFamily: 'Roboto', fontSize: 10, marginBottom: 30 }}>
            {deliveryCondition.deliveryTerm.deliveryService
              ? 'Sendung verpackt durch:'
              : 'Hiermit wird der ordnungsgemäße Erhalt der Lieferung bestätigt:'}
          </Text>
          <Text style={{ fontFamily: 'Roboto', fontSize: 10 }}>-----------------------------------</Text>
          <Text style={{ fontFamily: 'Roboto', fontSize: 10 }}>Unterschrift</Text>
        </View>
        {/* Signature fields END */}
      </View>
    </>
  );
}

export default Positions;
