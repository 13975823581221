// Packages
import React, { useState } from 'react';

// MUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// Services
import { getPositionsByCustomerAndDrawNo } from 'src/services/position';

// Interfaces
import { Position as PositionData } from 'src/services/interfaces/position.interface';
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';

interface Props {
  setPosition: React.Dispatch<React.SetStateAction<PositionData>>;
  customer: CustomerData;
  setRevisions: React.Dispatch<React.SetStateAction<PositionData[]>>;
}

function DrawNo({ setPosition, customer, setRevisions }: Props): JSX.Element {
  const [positionsByDrawNo, setPositionsByDrawNo] = useState([] as PositionData[]);

  async function handleSearchPositionsByDrawNo(event: { target: { value: any } }) {
    const { value } = event.target;
    const foundPositionsByDrawNo = await getPositionsByCustomerAndDrawNo({
      customerId: customer._id as string,
      drawNo: value,
    });

    setPositionsByDrawNo(foundPositionsByDrawNo);
  }

  return (
    <Autocomplete
      clearText="Enfernen"
      openText="Oeffnen"
      onChange={(_, position) => {
        if (position) {
          setPosition({
            description: position.description,
            drawNo: position.drawNo,
            service: position.service,
            material: position.material,
            materialProvided: position.materialProvided,
            materialIncluded: position.materialIncluded,
            materialCertificate: position.materialCertificate,
            text: position.text,
            quantity: position.quantity,
            unit: position.unit,
            price: position.price,
            vatRate: position.vatRate,
            articleNumber: position.articleNumber,
            revision: position.revision,
            files: position.files,
          } as PositionData);

          setRevisions(
            positionsByDrawNo.filter((positionByDrawNo) => positionByDrawNo.articleNumber === position.articleNumber),
          );
        } else {
          setPosition({} as PositionData);
        }
      }}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      getOptionLabel={(option) => `${option.drawNo} - ${option.description}`}
      // get options by unique articleNumber with latest dateCreated
      options={Object.entries(
        positionsByDrawNo.reduce((acc, curr) => {
          // @ts-ignore
          acc[curr.articleNumber] = { ...curr };
          return acc;
        }, {}),
        // @ts-ignore
      ).map(([articleNumber, value]) => ({ articleNumber, ...value }))}
      renderInput={(params) => (
        <TextField
          onChange={handleSearchPositionsByDrawNo}
          {...params}
          label="Suche nach Zeichnungsnummer"
          variant="outlined"
        />
      )}
    />
  );
}

export default DrawNo;
