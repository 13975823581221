// @ts-nocheck
// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Moment from 'react-moment';
// import { pdf, PDFViewer } from '@react-pdf/renderer';
import { pdf } from '@react-pdf/renderer';

// Services
import { getOrderByOrderId } from 'src/services/order';
import { getOfferByOfferId, convertOfferToOrder } from 'src/services/offer';
import { getPackingListByPackingListId } from 'src/services/packingList';
import { getInvoiceByInvoiceId } from 'src/services/invoice';
import { createPdf, sendPdf, getPdfsByAssignmentId } from 'src/services/pdf';

// Redux
import {
  setAssignmentByAssignmentId,
  resetAssignmentByAssignmentId,
  setPdfsByAssignmentIdReducer,
} from 'src/slices/assignmentSlice';
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Global Components
import Link from 'src/components/Link';
import Pdf from 'src/components/Pdf';

// MUI
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Link as MuiLink } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Tooltip from '@mui/material/Tooltip';

// Local Components
import { EditPackingListDialog } from './components';

function BaseData({ assignment }) {
  const [isPackingListDialogOpen, setIsPackingListDialogOpen] = useState(false);
  const { assignmentId, assignmentType }: { assignmentId: string; assignmentType: string } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const isOrder = assignmentType === 'order';
  const isOffer = assignmentType === 'offer';
  const isPackingList = assignmentType === 'packinglist';
  const isInvoice = assignmentType === 'invoice';

  const [isLoading, setIsLoading] = useState(true);
  const [anchorPdfMenuEl, setAnchorPdfMenuEl] = useState(null);
  const {
    offer,
    customersNoForOffer,
    customersNoForOrder,
    order,
    number,
    numberLetter,
    customer,
    purchaser,
    deliveryDate,
    deliveryTerm,
    paymentTerm,
    _id,
    positions,
    zeroVatRate,
    currency,
    dateCreated,
    deliveryCondition,
    deliveryCompleted,
  } = assignment;
  const { pdfsByAssignmentId } = useSelector((state: RootStateOrAny) => state.assignment);
  const { company } = useSelector((state: RootStateOrAny) => state.auth);
  const offerHasOrder = isOffer && order;

  useEffect(() => {
    getInitialData();
    return () => {
      dispatch(resetAssignmentByAssignmentId());
    };
  }, [assignmentType]); // eslint-disable-line

  const handlePdfMenuModifiedClick = (event: { currentTarget: React.SetStateAction<null> }) => {
    setAnchorPdfMenuEl(event.currentTarget);
  };

  const handlePdfMenuModifiedClose = () => {
    setAnchorPdfMenuEl(null);
  };

  const getInitialData = async () => {
    try {
      let foundAssignment;
      if (isOrder) foundAssignment = await getOrderByOrderId({ assignmentId });
      if (isOffer) foundAssignment = await getOfferByOfferId({ assignmentId });
      if (isPackingList) foundAssignment = await getPackingListByPackingListId({ assignmentId });
      if (isInvoice) foundAssignment = await getInvoiceByInvoiceId({ assignmentId });

      if (foundAssignment && (isOffer || isOrder || isPackingList)) {
        const foundPdfs = await getPdfsByAssignmentId({
          [assignmentType]: assignmentId,
        });
        dispatch(
          setAssignmentByAssignmentId({
            ...foundAssignment,
            pdfs: foundPdfs,
          }),
        );
        dispatch(setPdfsByAssignmentIdReducer(foundPdfs));
      }

      if (foundAssignment && isInvoice) {
        const foundPdfs = await getPdfsByAssignmentId({
          [assignmentType]: assignmentId,
        });
        dispatch(setPdfsByAssignmentIdReducer(foundPdfs));

        dispatch(
          setAssignmentByAssignmentId({
            ...foundAssignment,
            // @ts-ignore
            currency: foundAssignment.positions[0].order.currency,
          }),
        );
      }

      setIsLoading(false);
    } catch (error) {
      if (error) throw error;
    }
  };

  async function handleConvertOfferToOrder() {
    try {
      const createdOrder = await convertOfferToOrder({
        customer,
        purchaser,
        deliveryDate,
        deliveryTerm,
        paymentTerm,
        _id,
        positions,
        customersNoForOffer,
        customersNoForOrder,
        numberLetter,
        zeroVatRate,
        currency,
      });

      dispatch(
        showSnackbarReducer({
          message: 'Auftrag wurde aus dem Angebot erfolgreich erstellt',
          severity: 'success',
        }),
      );

      history.push(`/assignment/edit/order/${createdOrder._id}`);
    } catch (error) {
      if (error) throw error;
    }
  }

  return !isLoading ? (
    <>
      <Card>
        {/* <PDFViewer style={{ height: 1000, width: 1000 }}>
            <Pdf
              company={company}
              number={number}
              revision={pdfsByAssignmentId.length}
              customer={customer}
              positions={positions}
              purchaser={purchaser}
              deliveryDate={deliveryDate}
              deliveryTerm={deliveryTerm}
              paymentTerm={paymentTerm}
              customersNoForOffer={customersNoForOffer}
              customersNoForOrder={customersNoForOrder}
              isOffer={isOffer}
              isOrder={isOrder}
              isPackingList={isPackingList}
              isInvoice={isInvoice}
              deliveryCondition={deliveryCondition}
            />
          </PDFViewer> */}

        <CardHeader
          title={
            <Grid container alignItems="center">
              <Grid item>
                {isOrder && 'Auftrag: '}
                {isOffer && 'Angebot: '}
                {isPackingList && 'Lieferschein: '}
                {isInvoice && 'Rechnung: '}
                {number}
              </Grid>
              {!isPackingList && !isInvoice && !offerHasOrder && !deliveryCompleted && (
                <Link to={`/assignment/edit/${assignmentType}/${_id}`}>
                  <IconButton color="primary" size="large">
                    <EditIcon />
                  </IconButton>
                </Link>
              )}
              {isPackingList && deliveryCondition.deliveryTerm.deliveryService && (
                <IconButton onClick={() => setIsPackingListDialogOpen(true)} color="primary" size="large">
                  <EditIcon />
                </IconButton>
              )}
              <Grid item>
                <>
                  <IconButton color="primary" onClick={handlePdfMenuModifiedClick} size="large">
                    <PictureAsPdfIcon>PDF</PictureAsPdfIcon>{' '}
                  </IconButton>
                  <Menu
                    id="modified-menu"
                    anchorEl={anchorPdfMenuEl}
                    keepMounted
                    open={Boolean(anchorPdfMenuEl)}
                    onClose={handlePdfMenuModifiedClose}
                  >
                    {(isOffer || isOrder) && (
                      <MenuItem
                        onClick={async () => {
                          const blob = await pdf(
                            <Pdf
                              company={company}
                              number={number}
                              revision={pdfsByAssignmentId.length}
                              customer={customer}
                              positions={positions}
                              purchaser={purchaser}
                              deliveryDate={deliveryDate}
                              deliveryTerm={deliveryTerm}
                              paymentTerm={paymentTerm}
                              customersNoForOffer={customersNoForOffer}
                              customersNoForOrder={customersNoForOrder}
                              isOffer={isOffer}
                              isOrder={isOrder}
                              isPackingList={isPackingList}
                            />,
                          ).toBlob();

                          const revision = pdfsByAssignmentId.length;
                          let filename;

                          if (isOffer) {
                            filename = revision > 0 ? `Angebot-${number}-${revision}.pdf` : `Angebot-${number}.pdf`;
                          }

                          if (isOrder) {
                            filename = revision > 0 ? `Auftrag-${number}-${revision}.pdf` : `Auftrag-${number}.pdf`;
                          }

                          const file = await new File([blob], filename, {
                            lastModified: new Date().getTime(),
                          });

                          const formData = new FormData();
                          formData.append('pdf', file);
                          formData.append(assignmentType, _id);
                          const createdPdf = await createPdf(formData);

                          dispatch(setPdfsByAssignmentIdReducer([...pdfsByAssignmentId, createdPdf]));

                          dispatch(
                            showSnackbarReducer({
                              message: 'PDF erfolgreich erstellt!',
                              severity: 'success',
                            }),
                          );
                        }}
                      >
                        <Grid container spacing={0}>
                          <Grid item xs={12} md={3}>
                            <AddCircleIcon fontSize="small" color="secondary" />
                          </Grid>
                          <Grid item xs={12} md={9}>
                            PDF erstellen
                          </Grid>
                        </Grid>
                      </MenuItem>
                    )}

                    {pdfsByAssignmentId.length > 0 && !isPackingList && !isInvoice && <Divider />}

                    {pdfsByAssignmentId.map(
                      (pdf: {
                        _id: React.Key | null | undefined;
                        S3Data: { key: string; Location: string | undefined };
                      }) => (
                        <MenuItem key={pdf._id}>
                          <Grid container justifyContent="space-between">
                            <Grid item xs>
                              <Box mr={5}>{pdf.S3Data.key.split('/')[pdf.S3Data.key.split('/').length - 1]}</Box>
                            </Grid>

                            <Grid item>
                              <Grid container spacing={1}>
                                <Grid item>
                                  <Tooltip title="Vorschau">
                                    <MuiLink
                                      href={pdf.S3Data.Location}
                                      style={{
                                        textDecoration: 'none',
                                        outline: 0,
                                      }}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <VisibilityIcon fontSize="small" />
                                    </MuiLink>
                                  </Tooltip>
                                </Grid>

                                <Grid item>
                                  <Tooltip title="Versenden per E-Mail">
                                    <MailOutlineIcon
                                      fontSize="small"
                                      onClick={async () => {
                                        await sendPdf({
                                          pdfData: pdf.S3Data,
                                          customerId: customer._id,
                                        });

                                        dispatch(
                                          showSnackbarReducer({
                                            message: 'E-Mail erfolgreich verschickt!',
                                            severity: 'success',
                                          }),
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ),
                    )}
                  </Menu>
                </>
              </Grid>
              {!offerHasOrder && !isInvoice && !isOrder && !isPackingList && (
                <Button variant="outlined" color="secondary" onClick={handleConvertOfferToOrder}>
                  Auftrag erstellen
                </Button>
              )}
            </Grid>
          }
        />
        <Divider />
        <CardContent>
          <Typography>
            erstellt am: <Moment format="DD.MM.YYYY">{dateCreated}</Moment>
          </Typography>
          <Typography>
            {isOffer && order && (
              <>
                Auftrag: <Link to={`/assignment/details/order/${order._id}`}>{order.number}</Link>
              </>
            )}

            {isOrder && offer && (
              <>
                Angebot: <Link to={`/assignment/details/offer/${offer._id}`}>{offer.number}</Link>
              </>
            )}
          </Typography>
          <Typography>Kunde: {customer && customer.name}</Typography>
          {customersNoForOffer && <Typography>Anfrage-Nr.: {customersNoForOffer}</Typography>}
          {customersNoForOrder && <Typography>Bestell-Nr.: {customersNoForOrder}</Typography>}
          {purchaser && <Typography>Bearbeiter: {purchaser.name}</Typography>}
          {deliveryDate && (
            <Typography>
              Lieferdatum: <Moment format="DD.MM.YYYY">{deliveryDate}</Moment>
            </Typography>
          )}
          {deliveryTerm && <Typography>Lieferbedingung: {deliveryTerm.meaning}</Typography>}
          {paymentTerm && <Typography>Zahlungsbedingung: {paymentTerm.name}</Typography>}
          {zeroVatRate && <Typography color="secondary">EU-Lieferung mit 0% MwSt</Typography>}
          {currency && <Typography>Währung: {currency.name}</Typography>}

          {deliveryCondition && (
            <>
              <Typography>
                Lieferbedingung: {deliveryCondition.deliveryTerm.meaning} ({deliveryCondition.deliveryTerm.incoterm})
              </Typography>

              {deliveryCondition.deliveryCost > 0 && (
                <Typography>Lieferkosten: {deliveryCondition.deliveryCost.toFixed(2)} €</Typography>
              )}
              {deliveryCondition.parcelServiceName && (
                <Typography>Paketdienst/Spedition: {deliveryCondition.parcelServiceName}</Typography>
              )}
              {deliveryCondition.parcelTrackingNumber && (
                <Typography>Sendungs-Nummer: {deliveryCondition.parcelTrackingNumber}</Typography>
              )}
            </>
          )}
        </CardContent>
      </Card>

      {isPackingList && isPackingListDialogOpen && (
        <EditPackingListDialog
          assignment={assignment}
          isPackingListDialogOpen={isPackingListDialogOpen}
          setIsPackingListDialogOpen={setIsPackingListDialogOpen}
        />
      )}
    </>
  ) : null;
}

export default BaseData;
