// Packages
import React from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';

// MUI
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';

interface Props {
  handleUpdateBaseData: (data: { name: string; value: any }) => void;
}

function EuDelivery({ handleUpdateBaseData }: Props) {
  const { zeroVatRate } = useSelector((state: RootStateOrAny) => state.assignment.assignmentForm);

  return (
    <FormControl>
      <FormControlLabel
        name="zeroVatRate"
        control={
          <Switch
            color="primary"
            checked={zeroVatRate}
            onChange={({ target }) => {
              handleUpdateBaseData({ name: target.name, value: !zeroVatRate });
            }}
          />
        }
        label="EU-Lieferung"
        labelPlacement="start"
      />
    </FormControl>
  );
}

export default EuDelivery;
