// @ts-nocheck

// Packages
import { createSlice } from '@reduxjs/toolkit';

export const assignmentSlice = createSlice({
  name: 'assignment',
  initialState: {
    assignmentForm: {
      customer: {},
      purchaser: {},
      deliveryDate: null,
      deliveryTerm: '',
      paymentTerm: '',
      customersNoForOffer: '',
      customersNoForOrder: '',
      numberLetter: '',
      positions: [],
      zeroVatRate: false,
      currency: '',
      pdf: null,
      pdfDeleted: false,
    },
    assignmentByAssignmentId: {},
    pdfsByAssignmentId: [],

    offersAll: [],
    ordersAll: [],
  },
  reducers: {
    updatePdfReducer: (state, action) => {
      state.assignmentForm.pdf = action.payload;
    },
    updateAssignmentFormReducer: (state, action) => {
      state.assignmentForm[action.payload.name] = action.payload.value;
    },
    setPdfsByAssignmentIdReducer: (state, action) => {
      state.pdfsByAssignmentId = action.payload;
    },
    setAssignmentFormReducer: (state, action) => {
      state.assignmentForm.customer = action.payload.customer;
      state.assignmentForm.purchaser = action.payload.purchaser;
      state.assignmentForm.deliveryDate = action.payload.deliveryDate;
      state.assignmentForm.deliveryTerm = action.payload.deliveryTerm;
      state.assignmentForm.paymentTerm = action.payload.paymentTerm;
      state.assignmentForm.customersNoForOffer = action.payload.customersNoForOffer;
      state.assignmentForm.customersNoForOrder = action.payload.customersNoForOrder;
      state.assignmentForm.numberLetter = action.payload.numberLetter;
      state.assignmentForm.zeroVatRate = action.payload.zeroVatRate;
      state.assignmentForm.currency = action.payload.currency;
      state.assignmentForm.pdf = action.payload.pdf;
      state.assignmentForm.pdfDeleted = false;
    },
    resetAssignmentFormReducer: (state) => {
      state.assignmentForm.customer = {};
      state.assignmentForm.purchaser = {};
      state.assignmentForm.deliveryDate = null;
      state.assignmentForm.deliveryTerm = '';
      state.assignmentForm.paymentTerm = '';
      state.assignmentForm.customersNoForOffer = '';
      state.assignmentForm.customersNoForOrder = '';
      state.assignmentForm.numberLetter = '';
      state.assignmentForm.positions = [];
      state.assignmentForm.zeroVatRate = false;
      state.assignmentForm.currency = '';
      state.assignmentForm.pdf = null;
      state.assignmentForm.pdfDeleted = false;
    },
    resetOffersAll: (state) => {
      state.offersAll = [];
    },
    resetOrdersAll: (state) => {
      state.ordersAll = [];
    },
    setAssignmentByAssignmentId: (state, action) => {
      state.assignmentByAssignmentId = action.payload;
    },
    resetAssignmentByAssignmentId: (state) => {
      state.assignmentByAssignmentId = [];
    },
    setAllOffers: (state, action) => {
      state.offersAll = action.payload;
    },
    setAllOrders: (state, action) => {
      state.ordersAll = action.payload;
    },
    addPositionReducer: (state, action) => {
      state.assignmentByAssignmentId.positions = [...state.assignmentByAssignmentId.positions, action.payload];
    },
    updatePositionReducer: (state, action) => {
      const { _id } = action.payload;

      const positionIds = state.assignmentByAssignmentId.positions.map((position) => position._id);
      const positionIndex = positionIds.indexOf(_id);

      state.assignmentByAssignmentId.positions[positionIndex] = action.payload;
    },
    deletePositionReducer: (state, action) => {
      state.assignmentByAssignmentId.positions = action.payload;
    },
    dragPositionReducer: (state, action) => {
      state.assignmentByAssignmentId.positions = action.payload;
    },
  },
});

export const {
  updatePdfReducer,
  updateAssignmentFormReducer,
  setPdfsByAssignmentIdReducer,
  setAssignmentFormReducer,
  resetAssignmentFormReducer,
  resetOffersAll,
  resetOrdersAll,
  setAssignmentByAssignmentId,
  resetAssignmentByAssignmentId,
  setAllOffers,
  setAllOrders,
  addPositionReducer,
  updatePositionReducer,
  deletePositionReducer,
  dragPositionReducer,
} = assignmentSlice.actions;

export const order = (state) => state.order;

export default assignmentSlice.reducer;
