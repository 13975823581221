// @ts-nocheck

//  Packages
import React, { useState } from 'react';
import clsx from 'clsx';

// MUI
import { List, ListItem, ListItemText, Typography, Paper } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '&$selected': {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.white.main,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.white.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.lightBlue.main,
      color: theme.palette.black.main,
    },
    paddingRight: '0',
  },
  selected: {},
  button: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.blue.main,
    borderRadius: '10px',
    width: '40px',
    '&:hover': {
      backgroundColor: theme.palette.lightBlue.main,
    },
  },
  buttonActive: {
    boxShadow: `0 0 0 0.5rem ${theme.palette.lightGreen.main}`,
  },
  typoBold: { fontWeight: 600 },
}));

function UndeliveredOrders({ undeliveredOrders, handleGetUndeliveredPositions, showIcons, setShowIcons }) {
  const classes = useStyles();
  const [showNumbers, setShowNumbers] = useState(false);
  const [selectedListItem, setSelectedListItem] = React.useState(1);
  const [orderIdForActiveButtonStyling, setOrderIdForActiveButtonStyling] = useState('');

  const handleListItemClick = (index) => {
    setSelectedListItem(index);
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Tooltip
          title={showNumbers ? 'Positions-Text anzeigen' : 'nur Nummern anzeigen'}
          placement="bottom-end"
          followCursor
        >
          <Button
            onClick={() => {
              setShowNumbers(!showNumbers);
              setShowIcons(!showIcons);
            }}
          >
            <Typography variant="h6" color="text.blue" align="center">
              AUFTRÄGE
            </Typography>
          </Button>
        </Tooltip>
      </Grid>

      {!showNumbers ? (
        <Paper>
          <List disablePadding>
            {undeliveredOrders.map((undeliveredOrder) => (
              <Tooltip
                title={`${undeliveredOrder.positions.length} offene Pos.`}
                placement="bottom-end"
                followCursor
                key={undeliveredOrder._id}
              >
                <ListItem
                  button
                  onClick={() => {
                    handleListItemClick(undeliveredOrder._id);
                    handleGetUndeliveredPositions(undeliveredOrder._id);
                  }}
                  selected={selectedListItem === undeliveredOrder._id}
                  classes={{ root: classes.root, selected: classes.selected }}
                >
                  <ListItemText
                    primary={`${undeliveredOrder.numberLetter || ''} ${undeliveredOrder.number}  ${
                      undeliveredOrder.customersNoForOrder ? `- ${undeliveredOrder.customersNoForOrder}` : ''
                    }`}
                  />
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </Paper>
      ) : (
        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
          {undeliveredOrders.map((undeliveredOrder) => {
            return (
              <Grid item mobile={3} desktop={3} key={undeliveredOrder._id}>
                <Grid container justifyContent="center" alignItems="center">
                  <Tooltip
                    title={`${undeliveredOrder.positions.length} offene Pos.`}
                    placement="bottom-end"
                    followCursor
                  >
                    <Button
                      variant="contained"
                      className={clsx(
                        classes.button,
                        orderIdForActiveButtonStyling === undeliveredOrder._id && classes.buttonActive,
                      )}
                      onClick={() => {
                        setOrderIdForActiveButtonStyling(undeliveredOrder._id);
                        handleListItemClick(undeliveredOrder._id);
                        handleGetUndeliveredPositions(undeliveredOrder._id);
                      }}
                    >
                      <Typography variant="h4" className={classes.typoBold}>
                        {undeliveredOrder.numberLetter || '?'}
                      </Typography>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
}

export default UndeliveredOrders;
