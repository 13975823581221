// Packages
import React, { useState } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Services
import { createWarehouseItem, updateWarehouseItem } from 'src/services/warehouseItem';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Interfaces
import { WarehousePlace } from 'src/services/interfaces/warehousePlace.interface';
import { WarehouseItem } from 'src/services/interfaces/warehouseItem.interface';
import { WarehouseCategoryData } from '../../_Warehouse';

const useStyles = makeStyles((theme) => ({
  certificate: {
    height: '56px',
  },
  buttonClear: {
    fontSize: '22px',
    height: '56px',
    padding: '0',
    color: theme.palette.black.main,
    backgroundColor: theme.palette.yellow.main,
    '&:hover': {
      backgroundColor: theme.palette.yellow.main,
    },
  },
  buttonSubmit: {
    fontSize: '22px',
    height: '56px',
    padding: '0',
    color: theme.palette.white.main,
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

interface Props {
  handleGetOutsourcedWarehouseItems: () => Promise<void>;
  warehousePlaces: WarehousePlace[];
  outsourcedWarehouseItems?: WarehouseItem[];
  setOutsourcedWarehouseItems?: React.Dispatch<React.SetStateAction<WarehouseItem[]>> | undefined;
  setIsEditWarehouseItemDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  category: WarehouseCategoryData;
  warehouseItemToStore: WarehouseItem;
  setWarehouseItemToStore: React.Dispatch<React.SetStateAction<WarehouseItem>>;
  handleChangeWarehouseItemToStore: Function;
  handleOpenNumPad: (data: string) => void;
  setNumPadOpen: (data: boolean) => void;
  //
  setTabValue: any;
  tabValue: any;
  handleSetCategory: any;
  handleResetWarehouseItemToStore: any;
  handleResetWarehouseItemToFind: any;
  //
  categories: {
    value: string;
    name: string;
  }[];
}

function WarehouseItemForm({
  handleGetOutsourcedWarehouseItems,
  warehousePlaces,
  warehouseItemToStore,
  setWarehouseItemToStore,
  handleChangeWarehouseItemToStore,
  handleOpenNumPad,
  setNumPadOpen,
  category,
  handleSetCategory,
  categories,
}: Props): JSX.Element {
  const classes = useStyles();
  const { company } = useSelector((state: RootStateOrAny) => state.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  function resetForm(): void {
    setWarehouseItemToStore({} as WarehouseItem);
    setNumPadOpen(false);
    handleSetCategory('');
  }

  async function handleSubmit(): Promise<void> {
    try {
      setIsLoading(true);

      if (!warehouseItemToStore.isOutsourced) {
        await createWarehouseItem({ warehouseItem: { ...warehouseItemToStore, category } });
      } else {
        await updateWarehouseItem({ ...warehouseItemToStore, isOutsourced: false, dateOutsourced: null });
        handleGetOutsourcedWarehouseItems();
      }
      dispatch(
        showSnackbarReducer({
          message: 'Artikel erfolgreich eingelagert',
          severity: 'success',
        }),
      );
      setIsLoading(false);
      setNumPadOpen(false);
      handleSetCategory('');
      resetForm();
    } catch (error) {
      setIsLoading(false);
      if (error) throw error;
    }
  }

  function isFormDisabled(): boolean {
    return (
      // General
      isLoading ||
      isEmpty(category) ||
      isEmpty(warehouseItemToStore.quantity) ||
      isEmpty(warehouseItemToStore.place) ||
      isEmpty(warehouseItemToStore.space) ||
      // Conditionals
      (category === 'round' &&
        (isEmpty(warehouseItemToStore.outerDiameter) ||
          isEmpty(warehouseItemToStore.length) ||
          isEmpty(warehouseItemToStore.material))) ||
      (category === 'tube' &&
        (isEmpty(warehouseItemToStore.outerDiameter) ||
          isEmpty(warehouseItemToStore.innerDiameter) ||
          isEmpty(warehouseItemToStore.length) ||
          isEmpty(warehouseItemToStore.material))) ||
      (category === 'hexagon' &&
        (isEmpty(warehouseItemToStore.width) ||
          isEmpty(warehouseItemToStore.length) ||
          isEmpty(warehouseItemToStore.material))) ||
      (category === 'flat' &&
        (isEmpty(warehouseItemToStore.width) ||
          isEmpty(warehouseItemToStore.length) ||
          isEmpty(warehouseItemToStore.height) ||
          isEmpty(warehouseItemToStore.material))) ||
      (category === 'article' && isEmpty(warehouseItemToStore.articleNo)) ||
      (category === 'diverse' && isEmpty(warehouseItemToStore.description))
    );
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: { mobile: 'block', desktop: 'none' } }}>
        <FormControl variant="outlined" fullWidth color="primary">
          <InputLabel>Kategorie</InputLabel>
          <Select
            label="Kategorie"
            value={category}
            name="category"
            onChange={(event) => {
              handleSetCategory(event.target.value);
            }}
          >
            {categories.map((category) => {
              const { value, name } = category;
              return <MenuItem value={value}>{name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid>

      {category && (
        <Grid container spacing={2}>
          {(category === 'round' || category === 'tube') && (
            <Grid item xs={category === 'tube' ? 4 : 6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Außen-Ø (mm)"
                type="number"
                value={warehouseItemToStore.outerDiameter || ''}
                name="outerDiameter"
                onChange={({ target }) => {
                  handleChangeWarehouseItemToStore({ name: target.name, value: target.value });
                }}
                inputProps={{
                  inputMode: 'numeric',
                  onClick: () => handleOpenNumPad('outerDiameter'),
                }}
              />
            </Grid>
          )}
          {category === 'tube' && (
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Innen-Ø (mm)"
                type="number"
                value={warehouseItemToStore.innerDiameter || ''}
                name="innerDiameter"
                onChange={({ target }) => {
                  handleChangeWarehouseItemToStore({ name: target.name, value: target.value });
                }}
                inputProps={{ onClick: () => handleOpenNumPad('innerDiameter') }}
              />
            </Grid>
          )}
          {(category === 'flat' || category === 'hexagon') && (
            <Grid item xs={category === 'flat' ? 4 : 6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Breite (mm)"
                type="number"
                value={warehouseItemToStore.width || ''}
                name="width"
                onChange={({ target }) => {
                  handleChangeWarehouseItemToStore({ name: target.name, value: target.value });
                }}
                inputProps={{ onClick: () => handleOpenNumPad('width') }}
              />
            </Grid>
          )}
          {category === 'flat' && (
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Höhe (mm)"
                type="number"
                value={warehouseItemToStore.height || ''}
                name="height"
                onChange={({ target }) => {
                  handleChangeWarehouseItemToStore({ name: target.name, value: target.value });
                }}
                inputProps={{ onClick: () => handleOpenNumPad('height') }}
              />
            </Grid>
          )}
          {category !== 'article' && category !== 'diverse' && (
            // eslint-disable-next-line no-constant-condition
            <Grid item xs={category === 'tube' || category === 'flat' ? 4 : 6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Länge (mm)"
                type="number"
                value={warehouseItemToStore.length || ''}
                name="length"
                onChange={({ target }) => {
                  handleChangeWarehouseItemToStore({ name: target.name, value: target.value });
                }}
                inputProps={{ onClick: () => handleOpenNumPad('length') }}
              />
            </Grid>
          )}
          {category !== 'article' && category !== 'diverse' && (
            <>
              <Grid item xs={12} md={9}>
                <Autocomplete
                  value={!isEmpty(warehouseItemToStore.material) ? warehouseItemToStore.material : null}
                  clearText="Enfernen"
                  openText="Oeffnen"
                  onChange={(event, material) => {
                    handleChangeWarehouseItemToStore({ name: 'material', value: material });
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  getOptionLabel={(option) =>
                    `${option.number} | ${option.shortName} | ${option.name} | ${option.dinEn}`
                  }
                  options={company.materials}
                  renderInput={(params) => <TextField {...params} label="Werkstoff" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  className={classes.certificate}
                  disabled={isEmpty(warehouseItemToStore.material)}
                  // @ts-ignore
                  color="green"
                  fullWidth
                  variant="contained"
                >
                  Zeugnis-Upload
                </Button>
              </Grid>
            </>
          )}
          {category === 'article' && (
            <>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  label="Artikel-Nr."
                  type="text"
                  value={warehouseItemToStore.articleNo || ''}
                  name="articleNo"
                  onChange={({ target }) => {
                    handleChangeWarehouseItemToStore({ name: target.name, value: target.value });
                  }}
                  inputProps={{ onClick: () => handleOpenNumPad('articleNo') }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={warehouseItemToStore.finished}
                      color="primary"
                      name="finished"
                      onChange={(event) => {
                        setWarehouseItemToStore({ ...warehouseItemToStore, finished: event.target.checked });
                      }}
                    />
                  }
                  label="Fertig-Teil"
                  labelPlacement="start"
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Bezeichnung"
              type="text"
              value={warehouseItemToStore.description || ''}
              name="description"
              onChange={({ target }) => {
                handleChangeWarehouseItemToStore({ name: target.name, value: target.value });
              }}
              inputProps={{ onClick: () => setNumPadOpen(false) }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Menge"
              type="number"
              value={warehouseItemToStore.quantity || ''}
              name="quantity"
              onChange={({ target }) => {
                handleChangeWarehouseItemToStore({ name: target.name, value: target.value });
              }}
              inputProps={{ onClick: () => handleOpenNumPad('quantity') }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={warehouseItemToStore.place || undefined} // warehousePlaces[0]
              disableClearable
              clearText="Enfernen"
              openText="Oeffnen"
              onChange={(event, place) => {
                handleChangeWarehouseItemToStore({ name: 'place', value: place });
              }}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.name}
              options={warehousePlaces}
              renderInput={(params) => <TextField {...params} label="Lager-Ort" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {warehouseItemToStore.place && (
              <Autocomplete
                value={!isEmpty(warehouseItemToStore.space) ? warehouseItemToStore.space : undefined}
                disableClearable
                clearText="Enfernen"
                openText="Oeffnen"
                onChange={(event, space) => {
                  handleChangeWarehouseItemToStore({ name: 'space', value: space });
                }}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                getOptionLabel={(option) => option.name}
                options={
                  warehousePlaces.filter((warehousePlace) => warehousePlace._id === warehouseItemToStore.place._id)[0]
                    .spaces
                }
                renderInput={(params) => <TextField {...params} label="Lager-Platz" variant="outlined" />}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Button
              onClick={resetForm}
              // @ts-ignore
              className={classes.buttonClear}
              fullWidth
              variant="contained"
            >
              {warehouseItemToStore.isOutsourced ? 'Abbrechen' : 'Felder leeren'}
            </Button>
          </Grid>

          <Grid item xs={12} md={6}>
            <Button
              className={classes.buttonSubmit}
              disabled={isFormDisabled()}
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              // @ts-ignore
            >
              {warehouseItemToStore.isOutsourced ? 'Wieder einlagern' : 'Einlagern'}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default WarehouseItemForm;
