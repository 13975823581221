// Packages
import { configureStore } from '@reduxjs/toolkit';

// Slices
import authReducer from 'src/slices/authSlice';
import snackbarReducer from 'src/slices/snackbarSlice';
import assignmentReducer from 'src/slices/assignmentSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    snackbar: snackbarReducer,
    assignment: assignmentReducer,
  },
});
