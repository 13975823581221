// @ts-nocheck
// Packages
import React, { useEffect, useState } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';

// Services
import { getOrderLettersByCustomerId } from 'src/services/order';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

interface Props {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateBaseData: (data: { name: string; value: any }) => void;
}

function OrderLettersDialog({ openDialog, setOpenDialog, handleUpdateBaseData }: Props) {
  const { customer } = useSelector((state: RootStateOrAny) => state.assignment.assignmentForm);
  const [letters, setLetters] = useState([] as Array<string>);

  useEffect(() => {
    getInitialData();
  }, [customer]); // eslint-disable-line

  const getInitialData = async () => {
    const orderLetters = await getOrderLettersByCustomerId({ customerId: customer._id });
    setLetters(orderLetters);
  };

  function handleClose() {
    setOpenDialog(false);
  }

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <Box textAlign="center">
        <DialogTitle id="form-dialog-title">Buchstabe auswählen</DialogTitle>
      </Box>
      <DialogContent>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
          {letters.map((letter) => (
            <Grid item mobile={4} desktop={2}>
              <Grid container justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  color="green"
                  onClick={() => {
                    handleUpdateBaseData({ name: 'numberLetter', value: letter });
                    setOpenDialog(false);
                  }}
                >
                  <Typography variant="h4">{letter}</Typography>
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default OrderLettersDialog;
