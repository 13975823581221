// @ts-nocheck

// Packages
import React, { useState } from 'react';

// Local Components

// MUI
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

// Utils
import isEmpty from 'src/utils/isEmpty';
import CurrencyDialog from './components/_CurrencyDialog';

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
  editIcon: {
    color: theme.palette.yellow.main,
  },
  deleteIcon: {
    color: theme.palette.red.main,
  },
  cell: {
    borderBottom: 0,
  },
}));

function Currencies({ handleSubmit, companyData, showLastItemMessage }) {
  const classes = useStyles();
  const [openCurrencyDialog, setOpenCurrencyDialog] = useState(false);
  const [editCurrency, setEditCurrency] = useState();

  function handleCreateCurrency(currencyData) {
    const updatedCompanyData = {
      ...companyData,
      currencies: companyData.currencies ? [...companyData.currencies, currencyData] : [currencyData],
    };

    const message = 'Neue Währung wurde erstellt';
    const severity = 'success';

    handleSubmit(updatedCompanyData, message, severity);
  }

  function handleUpdateCurrency(currencyData, currencyIndex) {
    const updatedCompanyData = {
      ...companyData,
      currencies: [
        ...companyData.currencies.slice(0, currencyIndex),
        currencyData,
        ...companyData.currencies.slice(currencyIndex + 1),
      ],
    };

    const message = 'Währung wurde aktualisiert';
    const severity = 'success';

    handleSubmit(updatedCompanyData, message, severity);
  }

  function handleDeleteCurrency(currencyData) {
    const isLastItem = companyData.currencies.length < 2;

    if (isLastItem) {
      showLastItemMessage();
    } else {
      const index = companyData.currencies.map((currency) => currency.name).indexOf(currencyData.name);

      const updatedCompanyData = {
        ...companyData,
        currencies: [...companyData.currencies.slice(0, index), ...companyData.currencies.slice(index + 1)],
      };

      const message = 'Währung wurde gelöscht';
      const severity = 'info';

      handleSubmit(updatedCompanyData, message, severity);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            {!isEmpty(companyData.currencies) && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Währung</TableCell>
                    <TableCell>Symbol</TableCell>
                    <TableCell>
                      <Tooltip title="Währung hinzufügen" placement="bottom-end" followCursor>
                        <Button
                          fullWidth
                          variant="contained"
                          className={classes.addButton}
                          onClick={() => {
                            setEditCurrency({});
                            setOpenCurrencyDialog(true);
                          }}
                          size="large"
                        >
                          <AddCircleIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {companyData.currencies.map((currency, index) => (
                    <TableRow hover key={index}>
                      <TableCell className={classes.cell}>{currency.name}</TableCell>
                      <TableCell className={classes.cell}>{currency.symbol}</TableCell>
                      <TableCell className={classes.cell}>
                        <Tooltip title="Währung bearbeiten" placement="bottom-end" followCursor>
                          <IconButton
                            className={classes.editIcon}
                            onClick={() => {
                              setEditCurrency(currency);
                              setOpenCurrencyDialog(true);
                            }}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Währung löschen" placement="bottom-end" followCursor>
                          <IconButton
                            onClick={() => handleDeleteCurrency(currency)}
                            size="large"
                            className={classes.deleteIcon}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          {openCurrencyDialog && (
            <CurrencyDialog
              openCurrencyDialog={openCurrencyDialog}
              setOpenCurrencyDialog={setOpenCurrencyDialog}
              handleCreateCurrency={handleCreateCurrency}
              editCurrency={editCurrency}
              handleUpdateCurrency={handleUpdateCurrency}
              companyData={companyData}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Currencies;
