// @ts-nocheck

// Packages
import React from 'react';

// Views
import { App as AppLayout } from 'src/layouts';
import { default as AssignmentEditView } from './_AssignmentEdit';

// Layouts

function AssignmentEdit() {
  return (
    <AppLayout>
      <AssignmentEditView />
    </AppLayout>
  );
}

export default AssignmentEdit;
