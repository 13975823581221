// @ts-nocheck
// Packages
import React, { useState } from 'react';

// Services
import { updateWarehouseSpace, deleteWarehouseSpace } from 'src/services/warehouseSpace';

// Interfaces
import { WarehouseSpace } from 'src/services/interfaces/warehouseSpace.interface';
import { WarehousePlace } from 'src/services/interfaces/warehousePlace.interface';

// MUI
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  abortButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  greenButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
  yellowIcon: {
    color: theme.palette.yellow.main,
  },
  redIcon: {
    color: theme.palette.red.main,
  },
  box: {
    '&:hover': {
      backgroundColor: theme.palette.lightBlue.main,
    },
  },
}));

interface Props {
  space: WarehouseSpace;
  warehousePlaces: WarehousePlace[];
  setWarehousePlaces: React.Dispatch<React.SetStateAction<WarehousePlace[]>>;
}

function SpacesItem({ space, warehousePlaces, setWarehousePlaces }: Props): JSX.Element {
  const classes = useStyles();
  const [isSpaceEditDialogOpen, setIsSpaceEditDialogOpen] = useState(false);
  const [editedSpace, setEditedSpace] = useState<string>('');

  async function handleUpdateWarehouseSpace(event: { preventDefault: () => void }): Promise<void> {
    event.preventDefault();

    const updatedWarehouseSpace = await updateWarehouseSpace({
      warehouseSpaceId: space._id,
      name: editedSpace,
    });

    const warehousePlaceIndex = warehousePlaces
      .map((warehousePlace) => warehousePlace._id)
      // @ts-ignore
      .indexOf(updatedWarehouseSpace.place);

    const warehouseSpaceIndex = warehousePlaces[warehousePlaceIndex].spaces
      .map((space) => space._id)
      .indexOf(updatedWarehouseSpace._id);

    setWarehousePlaces([
      ...warehousePlaces.slice(0, warehousePlaceIndex),
      {
        ...warehousePlaces[warehousePlaceIndex],
        spaces: [
          ...warehousePlaces[warehousePlaceIndex].spaces.slice(0, warehouseSpaceIndex),
          updatedWarehouseSpace,
          ...warehousePlaces[warehousePlaceIndex].spaces.slice(warehouseSpaceIndex + 1),
        ],
      },
      ...warehousePlaces.slice(warehousePlaceIndex + 1),
    ]);

    setIsSpaceEditDialogOpen(false);
    setEditedSpace('');
  }

  async function handleDeleteWarehouseSpace(): Promise<void> {
    const deletedWarehouseSpace = await deleteWarehouseSpace({
      warehouseSpaceId: space._id,
    });

    const warehousePlaceIndex = warehousePlaces
      .map((warehousePlace) => warehousePlace._id)
      // @ts-ignore
      .indexOf(deletedWarehouseSpace.place);

    const warehouseSpaceIndex = warehousePlaces[warehousePlaceIndex].spaces
      .map((space) => space._id)
      .indexOf(deletedWarehouseSpace._id);

    setWarehousePlaces([
      ...warehousePlaces.slice(0, warehousePlaceIndex),
      {
        ...warehousePlaces[warehousePlaceIndex],
        spaces: [
          ...warehousePlaces[warehousePlaceIndex].spaces.slice(0, warehouseSpaceIndex),
          ...warehousePlaces[warehousePlaceIndex].spaces.slice(warehouseSpaceIndex + 1),
        ],
      },
      ...warehousePlaces.slice(warehousePlaceIndex + 1),
    ]);
  }

  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.grid}>
        <Grid item mobile={8} sx={{ paddingLeft: '12px' }}>
          {space.name}
        </Grid>

        <Grid item mobile={4}>
          <Tooltip title={`Lagerplatz ${space.name} bearbeiten`} placement="bottom-end" followCursor>
            <IconButton
              className={classes.yellowIcon}
              onClick={(event) => {
                event.stopPropagation();
                setIsSpaceEditDialogOpen(true);
                setEditedSpace(space.name);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={`Lagerplatz ${space.name} löschen`} placement="bottom-end" followCursor>
            <IconButton
              className={classes.redIcon}
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteWarehouseSpace;
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Dialog open={isSpaceEditDialogOpen} onClose={() => setIsSpaceEditDialogOpen(false)}>
        <Box textAlign="center">
          <DialogTitle className={classes.dialogTitle}>Lagerplatz bearbeiten</DialogTitle>
        </Box>

        <DialogContent>
          <Grid container spacing={3}>
            <Grid item mobile={12}>
              <TextField
                label="Name des Lagerorts"
                onChange={(event) => setEditedSpace(event.target.value)}
                fullWidth
                variant="outlined"
                value={editedSpace}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item mobile={6}>
            <Button
              fullWidth
              variant="contained"
              className={classes.abortButton}
              onClick={() => setIsSpaceEditDialogOpen(false)}
            >
              Abbrechen
            </Button>
          </Grid>
          <Grid item mobile={6}>
            <Button fullWidth variant="contained" className={classes.greenButton} onClick={handleUpdateWarehouseSpace}>
              Speichern
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SpacesItem;
