// Packages
import axios from 'axios';

// Interfaces
import { WarehouseSpace as WarehouseSpaceData } from 'src/services/interfaces/warehouseSpace.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/warehousespace`;

export function createWarehouseSpace(data: { name: string; placeId: any }) {
  return axios.post(`${apiURI}/create-warehousespace`, data).then((response) => response.data as WarehouseSpaceData);
}

export function deleteWarehouseSpace(data: { warehouseSpaceId: string }) {
  return axios
    .post(`${apiURI}/delete-warehousespace-by-id`, data)
    .then((response) => response.data as WarehouseSpaceData);
}

export function updateWarehouseSpace(data: { warehouseSpaceId: string; name: string }) {
  return axios
    .post(`${apiURI}/update-warehousespace-by-id`, data)
    .then((response) => response.data as WarehouseSpaceData);
}
