// @ts-nocheck
// Packages
import axios from 'axios';

// Interfaces
import { PackingList as PackingListData } from 'src/services/interfaces/packingList.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/packinglist`;

export function createPackingList(data) {
  return axios.post(`${apiURI}/create-packing-list`, data);
}

export function getPackingListByPackingListId(data: { assignmentId: string }) {
  return axios
    .post(`${apiURI}/get-packinglist-by-packinglist-id`, data)
    .then((response) => response.data as PackingListData);
}

export function updatePackingList(data) {
  return axios.post(`${apiURI}/update-packinglist`, data);
}
