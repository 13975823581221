// @ts-nocheck
// Packages
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Interfaces
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';

// Services
import { updateCustomer } from 'src/services/customer';
import { checkDuplicateCustomerNumberByCompanyId } from 'src/services/customer';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';

interface Props {
  isUpdateDialogOpen: boolean;
  setIsUpdateDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customers: CustomerData[];
  setCustomers: React.Dispatch<React.SetStateAction<CustomerData[]>>;
  handleUpdateBaseData: (data: { name: string; value: any }) => void;
}

function UpdateCustomerDialog({
  isUpdateDialogOpen,
  setIsUpdateDialogOpen,
  customers,
  setCustomers,
  handleUpdateBaseData,
}: Props) {
  const dispatch = useDispatch();
  const { customer } = useSelector((state: RootStateOrAny) => state.assignment.assignmentForm);
  const [updatedCustomer, setUpdatedCustomer] = useState({} as CustomerData);

  useEffect(() => {
    isUpdateDialogOpen && setUpdatedCustomer(customer);
  }, [isUpdateDialogOpen]); // eslint-disable-line

  function handleClose() {
    setIsUpdateDialogOpen(false);
    resetUpdatedCustomer();
  }

  function handleChange(event: { target: { name: any; value: any } }) {
    const { name, value } = event.target;
    setUpdatedCustomer({ ...updatedCustomer, [name]: value });
  }

  function handleChangeNumber(event: { target: { name: any; value: any } }) {
    const { name, value } = event.target;

    if (!isNaN(value)) {
      setUpdatedCustomer({ ...updatedCustomer, [name]: value });
    }

    if (value.length === 4) {
      checkNewManualCustomerNumber(value);
    }
  }

  const checkNewManualCustomerNumber = async (manualNumber: string) => {
    try {
      let message = '';
      let severity = '';
      const isOldNumber = manualNumber === customer.number;
      const resetedCustomer = {
        ...updatedCustomer,
        number: manualNumber.substring(0, 3),
      };

      if (isOldNumber) {
        message = `Die Kunden-Nummer entspricht der alten Kunden-Nummer`;
        severity = 'info';

        dispatch(showSnackbarReducer({ message, severity }));
        return;
      }

      const isDuplicate = await checkDuplicateCustomerNumberByCompanyId({
        number: manualNumber,
      });

      if (isDuplicate) {
        message = `Die Kunden-Nummer ${manualNumber} ist bereits vergeben`;
        severity = 'error';

        dispatch(showSnackbarReducer({ message, severity }));
        setUpdatedCustomer(resetedCustomer);
      }
    } catch (error) {
      if (error) throw error;
    }
  };

  function resetUpdatedCustomer() {
    setUpdatedCustomer({} as CustomerData);
  }

  async function handleSubmit() {
    try {
      await updateCustomer(updatedCustomer);

      const index = customers.map((customer) => customer._id).indexOf(customer._id);

      setCustomers([...customers.slice(0, index), { ...updatedCustomer }, ...customers.slice(index + 1)]);

      handleUpdateBaseData({ name: 'customer', value: updatedCustomer });
      setIsUpdateDialogOpen(false);
      resetUpdatedCustomer();
      dispatch(
        showSnackbarReducer({
          message: `Kunde wurde aktualisiert`,
          severity: 'green',
        }),
      );
    } catch (error) {
      if (error) throw error;
    }
  }

  return (
    <Dialog maxWidth="xs" open={isUpdateDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <Box textAlign="center">
        <DialogTitle id="form-dialog-title">Kunde bearbeiten</DialogTitle>
      </Box>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Nummer"
              type="text"
              value={updatedCustomer.number || ''}
              name="number"
              onChange={handleChangeNumber}
              fullWidth
              inputProps={{ maxLength: 4 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Name"
              type="text"
              value={updatedCustomer.name || ''}
              name="name"
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Kürzel"
              type="text"
              value={updatedCustomer.nameShort || ''}
              name="nameShort"
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <Button onClick={handleClose} color="red" fullWidth variant="contained" size="large">
              Abbrechen
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleSubmit}
              color="green"
              disabled={
                !updatedCustomer.name || !updatedCustomer.nameShort || updatedCustomer.number.toString().length !== 4
              }
              fullWidth
              variant="contained"
              size="large"
            >
              Speichern
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateCustomerDialog;
