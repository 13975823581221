// Packages
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Local Components
import { getCustomerByCustomerId } from 'src/services/customer';
import { getPurchasersByCustomerId } from 'src/services/purchaser';

// Interfaces
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';

// MUI
import Grid from '@mui/material/Grid';
import BaseData from './components/BaseData';
import Addresses from './components/Addresses';
import Purchasers from './components/Purchasers';

function CustomerDetails() {
  const { customerId }: { customerId: string } = useParams();
  const [customer, setCustomer] = useState({} as CustomerData);
  const [purchasers, setPurchasers] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getInitialData();
  }, []); // eslint-disable-line

  const getInitialData = async () => {
    try {
      const foundCustomer = await getCustomerByCustomerId({ customerId });
      setCustomer(foundCustomer);

      const foundPurchasers = await getPurchasersByCustomerId({ customerId });
      setPurchasers(foundPurchasers);

      setIsLoading(false);
    } catch (error) {
      if (error) throw error;
    }
  };

  return !isLoading ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BaseData customer={customer} setCustomer={setCustomer} />
      </Grid>
      <Grid item xs={12}>
        <Addresses customer={customer} setCustomer={setCustomer} />
      </Grid>
      <Grid item xs={12}>
        <Purchasers purchasers={purchasers} setPurchasers={setPurchasers} />
      </Grid>
    </Grid>
  ) : null;
}

export default CustomerDetails;
