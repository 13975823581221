// Packages
import axios from 'axios';

// Interfaces
import { Invoice as InvoiceData } from 'src/services/interfaces/invoice.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/invoice`;

export function createInvoice(data: { toBeSettled: Record<string, unknown>; customerId: string; paymentTerm: string }) {
  return axios.post(`${apiURI}/create-invoice`, data).then((respone) => respone.data as InvoiceData);
}

export function getInvoiceByInvoiceId(data: { assignmentId: string }) {
  return axios.post(`${apiURI}/get-invoice-by-invoice-id`, data).then((respone) => respone.data as InvoiceData);
}
