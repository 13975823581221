// Packages
import axios from 'axios';

// Interfaces
import { Offer as OfferData } from 'src/services/interfaces/offer.interface';
import { Order as OrderData } from 'src/services/interfaces/order.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/offer`;

export function createOffer(data: FormData) {
  return axios.post(`${apiURI}/offer-create`, data).then((response) => response.data as OfferData);
}

export function convertOfferToOrder(data: OfferData) {
  return axios.post(`${apiURI}/convert-offer-to-order`, data).then((response) => response.data as OrderData);
}

export function updateOffer(data: FormData) {
  return axios.post(`${apiURI}/offer-update`, data).then((response) => response.data as OfferData);
}

export function getOfferByOfferId(data: string) {
  return axios.post(`${apiURI}/get-offer-by-offer-id`, data).then((response) => response.data as OfferData);
}
