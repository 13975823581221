// @ts-nocheck

// Packages
import React from 'react';

// Views
import { App as AppLayout } from 'src/layouts';
import { default as CustomerOverviewView } from './_CustomerOverview';

// Layouts

function CustomerOverview() {
  return (
    <AppLayout>
      <CustomerOverviewView />
    </AppLayout>
  );
}

export default CustomerOverview;
