// Packages
import axios from 'axios';

// Interfaces
import { Company as CompanyData } from 'src/services/interfaces/company.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/company`;

export function getCurrentCompany() {
  return axios.get(`${apiURI}/get-current-company`).then((response) => response.data as unknown);
}

export function updateCompany(data: CompanyData) {
  return axios.post(`${apiURI}/update-company`, data).then((response) => response.data as string);
}
