// @ts-nocheck

// Packages
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Services
import { updateCompany, getCurrentCompany } from 'src/services/company';

// Reducer
import { showSnackbarReducer } from 'src/slices/snackbarSlice';
import { setCurrentCompanyReducer } from 'src/slices/authSlice';

// MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import StoreIcon from '@mui/icons-material/Store';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SubjectIcon from '@mui/icons-material/Subject';
import StorageIcon from '@mui/icons-material/Storage';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import PersonIcon from '@mui/icons-material/Person';
import { FaPercent } from 'react-icons/fa';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExtensionOffIcon from '@mui/icons-material/ExtensionOff';

import CompanyData from './CompanyData';
import Salutations from './Salutations';
import Units from './Units';
import Services from './Services';
import Currencies from './Currencies';
import Materials from './Materials';
import PaymentTerms from './PaymentTerms';
import DeliveryTerms from './DeliveryTerms';
import WarehousePlaces from './WarehousePlaces';
import VatRates from './VatRates';

const useStyles = makeStyles((theme) => ({
  panel: {
    padding: '0 12px 0 24px',
  },
  box: {
    flexGrow: 1,
    bgcolor: 'background.paper',
    display: 'flex',
  },
  tab: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.palette.lightBlue.main,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.lightBlue.main,
    },
  },
  tabMainFirst: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.lightGrey.main,
    },
    '&.Mui-selected': {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.lightGrey.main,
    },
  },
  tabMain: {
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.lightGrey.main,
    },
    '&.Mui-selected': {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.lightGrey.main,
    },
  },
  boxMainTablist: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
    paddingBottom: '1px',
  },
  indicator: {
    backgroundColor: theme.palette.white.main,
  },
  noPadding: {
    padding: 0,
  },
}));

const CompanyChangeData = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { company } = useSelector((state) => state.auth);
  const [companyData, setCompanyData] = useState(company);
  const [value, setValue] = React.useState('1');
  const [subTabValue, setSubTabValue] = React.useState('1');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setSubTabValue('1');
  };

  const handleChangeSubTab = (event, newValue) => {
    setSubTabValue(newValue);
  };

  async function handleSubmit(updatedCompanyData, message, severity) {
    try {
      await updateCompany(updatedCompanyData);
      setCompanyData(updatedCompanyData);
      const foundCompany = await getCurrentCompany();
      dispatch(setCurrentCompanyReducer(foundCompany));
      dispatch(showSnackbarReducer({ message, severity }));
    } catch (error) {
      if (error) throw error;
    }
  }

  function showLastItemMessage() {
    const message = 'Das letzte Element der Liste kann nicht gelöscht werden';
    const severity = 'error';
    dispatch(showSnackbarReducer({ message, severity }));
  }

  return (
    <>
      <Grid container>
        <Grid item mobile={12}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value} className={classes.noPadding}>
              <Box className={classes.boxMainTablist}>
                <TabList onChange={handleChangeTab} TabIndicatorProps={{ className: classes.indicator }}>
                  <Tab
                    label={isMobile ? <StoreIcon /> : 'Firma'}
                    value="1"
                    className={isMobile ? classes.tabMain : classes.tabMainFirst}
                  />
                  <Tab label={isMobile ? <AssignmentIcon /> : 'Aufträge'} value="2" className={classes.tabMain} />
                  <Tab label={isMobile ? <SubjectIcon /> : 'Positionen'} value="3" className={classes.tabMain} />
                  <Tab label={isMobile ? <StorageIcon /> : 'Lager'} value="4" className={classes.tabMain} />
                </TabList>
              </Box>
              <TabPanel value="1">
                <CompanyData
                  handleSubmit={handleSubmit}
                  companyData={companyData}
                  setCompanyData={setCompanyData}
                  showLastItemMessage={showLastItemMessage}
                />
              </TabPanel>

              <TabPanel value="2" className={classes.noPadding}>
                <Box className={classes.box}>
                  <TabContext value={subTabValue}>
                    <Box sx={{ borderRight: 1, borderColor: 'divider' }}>
                      <TabList orientation="vertical" onChange={handleChangeSubTab}>
                        <Tab
                          label={isMobile ? <LocalShippingIcon /> : 'Lieferbedingungen'}
                          value="1"
                          className={classes.tab}
                        />
                        <Tab
                          label={isMobile ? <EuroSymbolIcon /> : 'Zahlungsbedingungen'}
                          value="2"
                          className={classes.tab}
                        />
                        <Tab label={isMobile ? <PersonIcon /> : 'Anreden'} value="3" className={classes.tab} />
                        <Tab label={isMobile ? <FaPercent /> : 'MwSt-Sätze'} value="4" className={classes.tab} />
                      </TabList>
                    </Box>
                    <TabPanel value="1" className={classes.panel}>
                      <DeliveryTerms
                        handleSubmit={handleSubmit}
                        companyData={companyData}
                        showLastItemMessage={showLastItemMessage}
                      />
                    </TabPanel>
                    <TabPanel value="2" className={classes.panel}>
                      <PaymentTerms
                        handleSubmit={handleSubmit}
                        companyData={companyData}
                        showLastItemMessage={showLastItemMessage}
                      />
                    </TabPanel>
                    <TabPanel value="3" className={classes.panel}>
                      <Salutations
                        handleSubmit={handleSubmit}
                        companyData={companyData}
                        showLastItemMessage={showLastItemMessage}
                      />
                    </TabPanel>
                    <TabPanel value="4" className={classes.panel}>
                      <VatRates
                        handleSubmit={handleSubmit}
                        companyData={companyData}
                        setCompanyData={setCompanyData}
                        showLastItemMessage={showLastItemMessage}
                      />
                    </TabPanel>
                  </TabContext>
                </Box>
              </TabPanel>

              <TabPanel value="3" className={classes.noPadding}>
                <Box className={classes.box}>
                  <TabContext value={subTabValue}>
                    <Box sx={{ borderRight: 1, borderColor: 'divider' }}>
                      <TabList orientation="vertical" onChange={handleChangeSubTab}>
                        <Tab
                          label={isMobile ? <DesignServicesIcon /> : 'Diensleistungen'}
                          value="1"
                          className={classes.tab}
                        />
                        <Tab label={isMobile ? <BlurLinearIcon /> : 'Werkstoffe'} value="2" className={classes.tab} />
                        <Tab label={isMobile ? <AttachMoneyIcon /> : 'Währungen'} value="3" className={classes.tab} />
                        <Tab label={isMobile ? <ExtensionOffIcon /> : 'Einheiten'} value="4" className={classes.tab} />
                      </TabList>
                    </Box>
                    <TabPanel value="1" className={classes.panel}>
                      <Services
                        handleSubmit={handleSubmit}
                        companyData={companyData}
                        showLastItemMessage={showLastItemMessage}
                      />
                    </TabPanel>
                    <TabPanel value="2" className={classes.panel}>
                      <Materials
                        handleSubmit={handleSubmit}
                        companyData={companyData}
                        showLastItemMessage={showLastItemMessage}
                      />
                    </TabPanel>
                    <TabPanel value="3" className={classes.panel}>
                      <Currencies
                        handleSubmit={handleSubmit}
                        companyData={companyData}
                        showLastItemMessage={showLastItemMessage}
                      />
                    </TabPanel>
                    <TabPanel value="4" className={classes.panel}>
                      <Units
                        handleSubmit={handleSubmit}
                        companyData={companyData}
                        showLastItemMessage={showLastItemMessage}
                      />
                    </TabPanel>
                  </TabContext>
                </Box>
              </TabPanel>

              <TabPanel value="4" className={classes.panel}>
                <WarehousePlaces />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyChangeData;
