// Packages
import React from 'react';

// MUI
import TextField from '@mui/material/TextField';

// Interfaces
import { Position as PositionData } from 'src/services/interfaces/position.interface';

interface Props {
  position: PositionData;
  setPosition: React.Dispatch<React.SetStateAction<PositionData>>;
}

function DrawNo({ position, setPosition }: Props): JSX.Element {
  return (
    <TextField
      variant="outlined"
      label="Zeichnungs-Nr."
      value={position.drawNo || ''}
      name="drawNo"
      onChange={(event) => setPosition({ ...position, [event.target.name]: event.target.value })}
      fullWidth
    />
  );
}

export default DrawNo;
