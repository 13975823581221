// Packages
import React, { useState } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

// Services
import { updateCompany, getCurrentCompany } from 'src/services/company';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Interfaces
import { Material as MaterialData } from 'src/services/interfaces/company.interface';

// Reducers
import { showSnackbarReducer } from 'src/slices/snackbarSlice';
import { setCurrentCompanyReducer } from 'src/slices/authSlice';

// MUI
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  material: MaterialData;
  handleUpdatePosition: (data: any) => void;
}

function SelectMaterial({ material, handleUpdatePosition }: Props): JSX.Element {
  const dispatch = useDispatch();
  const { company } = useSelector((state: RootStateOrAny) => state.auth);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newMaterial, setNewMaterial] = useState({
    name: '',
    shortName: '',
    number: '',
    dinEn: '',
  });

  function handleClose() {
    setIsAddForm(false);
    setNewMaterial({ name: '', shortName: '', number: '', dinEn: '' });
  }

  function handleSetNewMaterial(event: { target: { name: any; value: any } }) {
    setNewMaterial({ ...newMaterial, [event.target.name]: event.target.value });
  }

  async function handleAddNewMaterial() {
    setIsLoading(true);
    await updateCompany({ ...company, materials: [...company.materials, { ...newMaterial }] });
    const foundCompany = await getCurrentCompany();
    dispatch(setCurrentCompanyReducer(foundCompany));
    dispatch(showSnackbarReducer({ message: 'Neuer Werkstoff wurde erstellt', severity: 'success' }));
    handleUpdatePosition({ target: { name: 'material', value: newMaterial } });
    handleClose();
    setIsLoading(false);
  }

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs>
        <Autocomplete
          value={!isEmpty(material) ? material : null}
          clearText="Enfernen"
          openText="Oeffnen"
          onChange={(event, material) => {
            handleUpdatePosition({
              target: { name: 'material', value: material },
            });
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => `${option.name} | ${option.shortName} | ${option.number} | ${option.dinEn}`}
          options={company.materials}
          renderInput={(params) => <TextField {...params} label="Werkstoff" variant="outlined" />}
        />
      </Grid>
      <Grid>
        <IconButton onClick={() => setIsAddForm(!isAddForm)} size="large">
          <AddIcon />
        </IconButton>
      </Grid>
      {isAddForm && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3}>
              <TextField
                onChange={handleSetNewMaterial}
                value={newMaterial.name}
                name="name"
                label="Name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                onChange={handleSetNewMaterial}
                value={newMaterial.shortName}
                name="shortName"
                label="Kurzname"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                onChange={handleSetNewMaterial}
                value={newMaterial.number}
                name="number"
                label="Nummer"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                onChange={handleSetNewMaterial}
                value={newMaterial.dinEn}
                name="dinEn"
                label="DIN EN"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <Button onClick={handleClose} fullWidth variant="outlined">
                Abbrechen
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={
                  isLoading || !newMaterial.name || !newMaterial.shortName || !newMaterial.number || !newMaterial.dinEn
                }
                onClick={handleAddNewMaterial}
                color="secondary"
                fullWidth
                variant="outlined"
              >
                hinzufügen
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default SelectMaterial;
