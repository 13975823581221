// Packages
import React from 'react';

// Packages
import { useState } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useParams } from 'react-router-dom';

// Services
import { createPosition } from 'src/services/position';
import { updatePosition } from 'src/services/position';

// Redux
import { addPositionReducer } from 'src/slices/assignmentSlice';
import { updatePositionReducer } from 'src/slices/assignmentSlice';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Interfaces
import { Position as PositionData, File as FileData } from 'src/services/interfaces/position.interface';

// MUI
import Button from '@mui/material/Button';

interface Props {
  position: PositionData;
  existingPosition: PositionData;
  setOpenPositionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  filesToUpload: any;
  filesToDelete: FileData[];
}

function Submit({
  position,
  existingPosition,
  setOpenPositionDialog,
  filesToUpload,
  filesToDelete,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { assignmentId, assignmentType }: { assignmentId: string; assignmentType: string } = useParams();
  const { assignmentByAssignmentId } = useSelector((state: RootStateOrAny) => state.assignment);
  const customerId = assignmentByAssignmentId.customer._id;

  async function handleCreateNewPosition() {
    try {
      setIsLoading(true);

      const positionData = {
        ...position,
        [assignmentType]: assignmentId,
        customer: customerId,
      };

      const formData = new FormData();
      formData.append('positionData', JSON.stringify(positionData));

      if (!isEmpty(filesToUpload)) {
        // @ts-ignore
        Array.from(filesToUpload).map((file) => formData.append('file', file));
      }

      const createdPosition = await createPosition(formData);
      dispatch(addPositionReducer(createdPosition));
      setOpenPositionDialog(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error) throw error;
    }
  }

  async function handleUpdatePosition() {
    try {
      setIsLoading(true);

      const positionData = { ...position, filesToDelete };
      const formData = new FormData();
      formData.append('positionData', JSON.stringify(positionData));

      if (!isEmpty(filesToUpload)) {
        // @ts-ignore
        Array.from(filesToUpload).map((file) => formData.append('file', file));
      }

      const updatedPosition = await updatePosition(formData);
      dispatch(updatePositionReducer(updatedPosition));
      setOpenPositionDialog(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error) throw error;
    }
  }

  return (
    <Button
      onClick={existingPosition ? handleUpdatePosition : handleCreateNewPosition}
      variant="contained"
      color="secondary"
      disabled={
        isLoading ||
        isEmpty(
          position.description &&
            position.drawNo &&
            position.quantity &&
            position.unit &&
            position.price &&
            position.vatRate &&
            position.revision,
        )
      }
    >
      {existingPosition ? 'Aktualisieren' : 'Erstellen'}
    </Button>
  );
}

export default Submit;
