// @ts-nocheck

// Packages
import React, { useState } from 'react';
import clsx from 'clsx';

// MUI
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import EmailIcon from '@mui/icons-material/Email';
import { makeStyles } from '@mui/styles';

// Global Components
import { CustomerForm } from 'src/components';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  textFieldIcon: {
    '& .MuiFormLabel-root': {
      display: 'flex',
      alignItems: 'center',
      '& .smallIcon': {
        paddingLeft: '8px',
        order: 1,
      },
    },
  },
  buttonName: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  grid: {
    minWidth: '100%',
  },
  textfield: {
    '& .MuiOutlinedInput-input': {
      cursor: 'pointer',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function BaseData({ customer, setCustomer }) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Card>
        <CardHeader
          className={classes.header}
          title={
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Tooltip title="Stammdaten bearbeiten" placement="bottom-end" followCursor>
                  <Button onClick={() => setIsDialogOpen(true)} className={classes.buttonName}>
                    <Typography variant="h4" color="text.white">
                      {customer.name} ({customer.nameShort})
                    </Typography>
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Button onClick={() => setIsDialogOpen(true)} className={classes.buttonName}>
                  <Tooltip title="Stammdaten bearbeiten" placement="bottom-end" followCursor>
                    <Typography variant="body2" color="text.white">
                      Kunden-Nr. {customer.number}
                    </Typography>
                  </Tooltip>
                </Button>
              </Grid>
            </Grid>
          }
        />

        <CardContent>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            <Grid item mobile={12} desktop={4}>
              <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item className={classes.grid}>
                  <Tooltip title="Stammdaten bearbeiten" placement="bottom-end" followCursor>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      label="Telefon"
                      value={customer.tel}
                      InputProps={{
                        readOnly: true,
                      }}
                      onClick={() => setIsDialogOpen(true)}
                    />
                  </Tooltip>
                </Grid>

                <Grid item className={classes.grid}>
                  <Tooltip title="Stammdaten bearbeiten" placement="bottom-end" followCursor>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      label="Fax"
                      value={customer.fax}
                      InputProps={{
                        readOnly: true,
                      }}
                      onClick={() => setIsDialogOpen(true)}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            <Grid item mobile={12} desktop={4}>
              <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item className={classes.grid}>
                  <Tooltip title="Stammdaten bearbeiten" placement="bottom-end" followCursor>
                    <TextField
                      className={clsx(classes.textfield, classes.textFieldIcon)}
                      fullWidth
                      label={
                        <>
                          <EmailIcon className="smallIcon" fontSize="medium" />
                          Zentrale
                        </>
                      }
                      value={customer.email}
                      InputProps={{
                        readOnly: true,
                      }}
                      onClick={() => setIsDialogOpen(true)}
                    />
                  </Tooltip>
                </Grid>
                <Grid item className={classes.grid}>
                  <Tooltip title="Stammdaten bearbeiten" placement="bottom-end" followCursor>
                    <TextField
                      className={clsx(classes.textfield, classes.textFieldIcon)}
                      fullWidth
                      label={
                        <>
                          <EmailIcon className="smallIcon" fontSize="medium" />
                          Aufträge
                        </>
                      }
                      value={customer.emailConfirmation}
                      InputProps={{
                        readOnly: true,
                      }}
                      onClick={() => setIsDialogOpen(true)}
                    />
                  </Tooltip>
                </Grid>
                <Grid item className={classes.grid}>
                  <Tooltip title="Stammdaten bearbeiten" placement="bottom-end" followCursor>
                    <TextField
                      className={clsx(classes.textfield, classes.textFieldIcon)}
                      fullWidth
                      label={
                        <>
                          <EmailIcon className="smallIcon" fontSize="medium" />
                          Rechnungen
                        </>
                      }
                      value={customer.emailInvoice}
                      InputProps={{
                        readOnly: true,
                      }}
                      onClick={() => setIsDialogOpen(true)}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            <Grid item mobile={12} desktop={4}>
              <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item className={classes.grid}>
                  <Tooltip title="Stammdaten bearbeiten" placement="bottom-end" followCursor>
                    <TextField
                      className={clsx(classes.textfield, classes.textFieldIcon)}
                      fullWidth
                      label="Lieferbedingung (Standard)"
                      value={customer.defaultDeliveryTerm && customer.defaultDeliveryTerm.meaning}
                      InputProps={{
                        readOnly: true,
                      }}
                      onClick={() => setIsDialogOpen(true)}
                    />
                  </Tooltip>
                </Grid>
                <Grid item className={classes.grid}>
                  <Tooltip title="Stammdaten bearbeiten" placement="bottom-end" followCursor>
                    <TextField
                      className={clsx(classes.textfield, classes.textFieldIcon)}
                      fullWidth
                      label="Zahlungsbedingung (Standard)"
                      value={customer.defaultPaymentTerm && customer.defaultPaymentTerm.name}
                      InputProps={{
                        readOnly: true,
                      }}
                      onClick={() => setIsDialogOpen(true)}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <CustomerForm
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        existingCustomer={customer}
        setExistingCustomer={setCustomer}
      />
    </>
  );
}
