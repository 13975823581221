// @ts-nocheck

// Packages
import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  abortButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  greenButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

function SalutationDialog({
  editSalutation,
  openSalutationDialog,
  setOpenSalutationDialog,
  handleCreateSalutation,
  handleUpdateSalutation,
}) {
  const classes = useStyles();
  const [salutationData, setSalutationData] = useState(editSalutation.id ? editSalutation : { id: uuid() });

  useEffect(() => {
    setSalutationData(editSalutation.id ? editSalutation : { id: uuid() });
    return () => setSalutationData({});
  }, []); // eslint-disable-line

  function handleCloseSalutationDialog() {
    setOpenSalutationDialog(false);
  }

  function handleChangeSalutationData(event) {
    const { name, value } = event.target;
    setSalutationData({ ...salutationData, [name]: value });
  }

  function handleSubmit() {
    editSalutation.id ? handleUpdateSalutation(salutationData) : handleCreateSalutation(salutationData);
    handleCloseSalutationDialog();
  }

  return (
    <Dialog maxWidth="md" open={openSalutationDialog} onClose={() => handleCloseSalutationDialog()}>
      <Box textAlign="center">
        <DialogTitle className={classes.dialogTitle}>
          Anrede {editSalutation.id ? 'bearbeiten' : 'hinzufügen'}
        </DialogTitle>
      </Box>
      <DialogContent>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Geschlecht</InputLabel>
              <Select
                label="Geschlecht"
                name="gender"
                value={salutationData.gender || ''}
                onChange={handleChangeSalutationData}
              >
                <MenuItem value="Herr">Herr</MenuItem>
                <MenuItem value="Frau">Frau</MenuItem>
                <MenuItem value="Divers">Divers</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="Titel"
              type="text"
              value={salutationData.title || ''}
              name="title"
              fullWidth
              onChange={handleChangeSalutationData}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="Kurzschreibweise"
              type="text"
              value={salutationData.shortTitle || ''}
              name="shortTitle"
              fullWidth
              onChange={handleChangeSalutationData}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid item mobile={6}>
          <Button
            fullWidth
            variant="contained"
            className={classes.abortButton}
            onClick={() => handleCloseSalutationDialog()}
          >
            Abbrechen
          </Button>
        </Grid>
        <Grid item mobile={6}>
          <Button fullWidth variant="contained" className={classes.greenButton} onClick={handleSubmit}>
            {editSalutation.id ? 'Aktualisieren' : 'Erstellen'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default SalutationDialog;
