// @ts-nocheck

// Packages
import React from 'react';

// Views
import { App as AppLayout } from 'src/layouts';
import { default as StaffView } from './_Staff';

// Layouts

function Staff() {
  return (
    <AppLayout>
      <StaffView />
    </AppLayout>
  );
}

export default Staff;
