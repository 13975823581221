// @ts-nocheck
import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';

const SocketContext = createContext();

export function useSocket() {
  return useContext(SocketContext);
}

export function SocketContextProvider({ children }) {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socketIo = io(process.env.REACT_APP_API_URI);

    socketIo.on('connect', () => {
      socketIo.emit('connected', ' currentUser._id'); // userName is unique
      setSocket(socketIo);
      // console.log(`${socketIo.id} connected!`); // eslint-disable-line
    });

    socketIo.on('disconnect', () => {
      setSocket(socketIo);
      // console.log(`${socketIo.id} disconnected!`); // eslint-disable-line
    });

    socketIo.on('reconnect', () => {
      setSocket(socketIo);
      // console.log(`${socketIo.id} reconnected!`); // eslint-disable-line
    });
  }, []);

  const defaultContext = { socket };
  return <SocketContext.Provider value={defaultContext}>{children}</SocketContext.Provider>;
}

SocketContextProvider.propTypes = {
  children: PropTypes.node,
};
