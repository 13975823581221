// Packages
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Interfaces
import { Currency as CurrencyData } from 'src/services/interfaces/company.interface';

// MUI
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

interface Props {
  // eslint-disable-next-line no-empty-pattern
  handleUpdateBaseData: ({}: { name: string; value: CurrencyData }) => void;
}

function Currency({ handleUpdateBaseData }: Props) {
  const { currency }: { currency: CurrencyData } = useSelector(
    (state: RootStateOrAny) => state.assignment.assignmentForm,
  );
  const { currencies }: { currencies: CurrencyData[] } = useSelector((state: RootStateOrAny) => state.auth.company);
  const [indexCurrency, setIndexCurrency] = useState<number>(0);

  useEffect(() => {
    if (!isEmpty(currency)) {
      setIndexCurrency(currencies.map((currency) => currency.name).indexOf(currency.name));
    } else {
      setIndexCurrency(0);
      handleUpdateBaseData({ name: 'currency', value: currencies[0] });
    }
  }, []); // eslint-disable-line

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Währung</InputLabel>
      <Select
        label="Währung"
        value={currencies[indexCurrency] || currencies[0]}
        name="currency"
        onChange={(event) => {
          // @ts-ignore
          const { name, value }: { value: CurrencyData; name: string } = event.target;
          handleUpdateBaseData({ name, value });
          setIndexCurrency(currencies.map((currency) => currency.name).indexOf(value.name));
        }}
      >
        {currencies.map((currency, index) => {
          return (
            // @ts-ignore
            <MenuItem value={currency} key={index}>
              {currency.symbol}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default Currency;
