// @ts-nocheck
// Packages
import React, { useState } from 'react';

// Services
import { updateWarehousePlace, deleteWarehousePlace } from 'src/services/warehousePlace';
import { createWarehouseSpace } from 'src/services/warehouseSpace';

// MUI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
// import List from '@mui/material/List';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

// Locale Components
import { WarehousePlace } from 'src/services/interfaces/warehousePlace.interface';
import { SpacesItem } from './components';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  abortButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  greenButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
  expanded: {},
  accSummary: {
    color: theme.palette.blue.main,
    backgroundColor: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.lightBlue.main,
    },
    '&$expanded': {
      // backgroundColor: theme.palette.lightBlue.main,
    },
  },
  placeTypo: {
    fontSize: '20px',
  },
  greenIcon: {
    color: theme.palette.green.main,
  },
  yellowIcon: {
    color: theme.palette.yellow.main,
  },
  redIcon: {
    color: theme.palette.red.main,
  },
  item: {
    '&:hover': {
      backgroundColor: theme.palette.lightBlue.main,
    },
  },
}));

// Interfaces
interface Props {
  warehousePlace: WarehousePlace;
  warehousePlaces: WarehousePlace[];
  setWarehousePlaces: React.Dispatch<React.SetStateAction<WarehousePlace[]>>;
}

function PlaceItem({
  warehousePlace,
  warehousePlaces,
  setWarehousePlaces,
  handleExpanded,
  expanded,
}: Props): JSX.Element {
  const classes = useStyles();
  const [isAddSpaceDialogOpen, setIsAddSpaceDialogOpen] = useState<boolean>(false);
  const [isEditPlaceDialogOpen, setIsEditPlaceDialogOpen] = useState<boolean>(false);
  const [editedPlaceName, setEditedPlaceName] = useState<string>('');
  const [newWarehousePlaceSpace, setNewWarehousePlaceSpace] = useState<string>('');

  async function handleUpdateWarehousePlaceName(event: { preventDefault: () => void }): Promise<void> {
    event.preventDefault();

    const updatedWarehousePlace = await updateWarehousePlace({
      ...warehousePlace,
      name: editedPlaceName,
    });

    const index = warehousePlaces.map((warehousePlace) => warehousePlace._id).indexOf(updatedWarehousePlace._id);
    setWarehousePlaces([
      ...warehousePlaces.slice(0, index),
      updatedWarehousePlace,
      ...warehousePlaces.slice(index + 1),
    ]);

    setIsEditPlaceDialogOpen(false);
  }

  async function handleDeleteWarehousePlace(warehousePlaceId: string): Promise<void> {
    const deletedWarehousePlace = await deleteWarehousePlace({
      warehousePlaceId,
    });
    const index = warehousePlaces.map((warehousePlace) => warehousePlace._id).indexOf(deletedWarehousePlace._id);
    setWarehousePlaces([...warehousePlaces.slice(0, index), ...warehousePlaces.slice(index + 1)]);
  }

  async function handleCreateWarehouseSpace(event: { preventDefault: () => void }): Promise<void> {
    event.preventDefault();
    const createdWarehouseSpace = await createWarehouseSpace({
      name: newWarehousePlaceSpace,
      placeId: warehousePlace._id,
    });

    const warehousePlaceIndex = warehousePlaces
      .map((warehousePlace) => warehousePlace._id)
      // @ts-ignore
      .indexOf(createdWarehouseSpace.place);

    setWarehousePlaces([
      ...warehousePlaces.slice(0, warehousePlaceIndex),
      {
        ...warehousePlaces[warehousePlaceIndex],
        spaces: [...warehousePlaces[warehousePlaceIndex].spaces, createdWarehouseSpace],
      },
      ...warehousePlaces.slice(warehousePlaceIndex + 1),
    ]);
    setIsAddSpaceDialogOpen(false);
    setNewWarehousePlaceSpace('');
  }

  return (
    <>
      <Accordion onChange={handleExpanded} expanded={expanded}>
        <AccordionSummary
          // classes={{ content: classes.accSummary, expanded: classes.expanded }}
          className={classes.accSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item mobile={6}>
              <Typography className={classes.placeTypo}>{warehousePlace.name}</Typography>
            </Grid>
            <Grid item mobile={2}>
              <Tooltip title="Lagerplatz hinzufügen" placement="bottom-end" followCursor>
                <IconButton
                  className={classes.greenIcon}
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsAddSpaceDialogOpen(true);
                  }}
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item mobile={2}>
              <Tooltip title="Lagerort bearbeiten" placement="bottom-end" followCursor>
                <IconButton
                  className={classes.yellowIcon}
                  onClick={(event) => {
                    event.stopPropagation();
                    setEditedPlaceName(warehousePlace.name);
                    setIsEditPlaceDialogOpen(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item mobile={2}>
              <Tooltip title="Lagerort löschen" placement="bottom-end" followCursor>
                <IconButton
                  className={classes.redIcon}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDeleteWarehousePlace(warehousePlace._id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionSummary>

        <Divider />

        <AccordionDetails>
          {warehousePlace.spaces.map((space, index) => (
            <Box className={classes.item} key={index}>
              <SpacesItem space={space} warehousePlaces={warehousePlaces} setWarehousePlaces={setWarehousePlaces} />
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>

      <Dialog open={isAddSpaceDialogOpen} onClose={() => setIsAddSpaceDialogOpen(false)}>
        <Box textAlign="center">
          <DialogTitle className={classes.dialogTitle}>Lagerplatz hinzufügen</DialogTitle>
        </Box>

        <DialogContent>
          <Grid container spacing={3}>
            <Grid item mobile={12}>
              <TextField
                autoFocus
                onChange={(event) => {
                  setNewWarehousePlaceSpace(event.target.value);
                }}
                value={newWarehousePlaceSpace}
                fullWidth
                variant="outlined"
                label="Name des neuen Lagerplatzes"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item mobile={6}>
            <Button
              fullWidth
              variant="contained"
              className={classes.abortButton}
              onClick={() => setIsAddSpaceDialogOpen(false)}
            >
              Abbrechen
            </Button>
          </Grid>
          <Grid item mobile={6}>
            <Button fullWidth variant="contained" className={classes.greenButton} onClick={handleCreateWarehouseSpace}>
              Hinzufügen
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog open={isEditPlaceDialogOpen} onClose={() => setIsEditPlaceDialogOpen(false)}>
        <Box textAlign="center">
          <DialogTitle className={classes.dialogTitle}>Lagerort bearbeiten</DialogTitle>
        </Box>

        <DialogContent>
          <Grid container spacing={3}>
            <Grid item mobile={12}>
              <TextField
                label="Name des Lagerorts"
                onChange={(event) => setEditedPlaceName(event.target.value)}
                fullWidth
                variant="outlined"
                value={editedPlaceName}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item mobile={6}>
            <Button
              fullWidth
              variant="contained"
              className={classes.abortButton}
              onClick={() => setIsEditPlaceDialogOpen(false)}
            >
              Abbrechen
            </Button>
          </Grid>
          <Grid item mobile={6}>
            <Button
              fullWidth
              variant="contained"
              className={classes.greenButton}
              onClick={handleUpdateWarehousePlaceName}
            >
              Speichern
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PlaceItem;
