// @ts-nocheck
// Packages
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { updateAssignmentFormReducer, resetAssignmentFormReducer } from 'src/slices/assignmentSlice';

// Services
import { getCustomersByCompanyId } from 'src/services/customer';
import { getPurchasersByCustomerId } from 'src/services/purchaser';

// Interfaces
import { Offer } from 'src/services/interfaces/offer.interface';
import { Order } from 'src/services/interfaces/order.interface';
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';
import { Purchaser as PurchaserData } from 'src/services/interfaces/purchaser.interface';

// MUI
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tooltip from '@mui/material/Tooltip';

// Local Components
import Customer from './components/Customer';
import Purchaser from './components/Purchaser';
import DeliveryDate from './components/DeliveryDate';
import DeliveryTerm from './components/DeliveryTerm';
import PaymentTerm from './components/PaymentTerm';
import OfferOrConfirmation from './components/OfferOrConfirmation';
import Submit from './components/Submit';
import RequestOrderNumber from './components/RequestOrderNumber';
import EuDelivery from './components/EuDelivery';
import Currency from './components/Currency';
import Pdf from './components/Pdf';

const useStyles = makeStyles({
  button: {
    minHeight: '54px',
  },
});

interface Props {
  assignment: Offer | Order;
}

function AssignmentForm({ assignment }: Props) {
  const classes = useStyles();
  const [offerOrOrder, setOfferOrOrder] = useState<'Angebot' | 'Auftrag'>('Auftrag');
  const dispatch = useDispatch();
  const [purchasers, setPurchasers] = useState([] as PurchaserData[]);
  const [customers, setCustomers] = useState([] as CustomerData[]);
  const isOffer = window.location.pathname.includes('offer');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getInitialData();
    handleSetInitialOfferOrOrder();
    return () => handleResetAssignmentForm();
  }, []);

  function handleResetAssignmentForm() {
    dispatch(resetAssignmentFormReducer());
  }

  function handleSetInitialOfferOrOrder() {
    const isOffer: boolean = window.location.pathname.includes('offer');
    const isOrder: boolean = window.location.pathname.includes('order');

    if (isOffer) {
      setOfferOrOrder('Angebot');
    } else if (isOrder) {
      setOfferOrOrder('Auftrag');
    }
  }

  const getInitialData = async () => {
    const customers = await getCustomersByCompanyId();
    setCustomers(customers);
    assignment && handleUpdatePurchasers(assignment.customer);
  };

  const handleUpdateBaseData = (data: { name: string; value: any }) => {
    const { name, value } = data;
    dispatch(updateAssignmentFormReducer({ name, value }));
  };

  const handleUpdatePurchasers = async (customer: CustomerData) => {
    const foundPurchasers = await getPurchasersByCustomerId({
      customerId: customer._id as string,
    });
    setPurchasers(foundPurchasers);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <OfferOrConfirmation
              assignment={assignment}
              offerOrOrder={offerOrOrder}
              setOfferOrOrder={setOfferOrOrder}
              handleUpdateBaseData={handleUpdateBaseData}
            />
          </Grid>
          <Grid item xs={12}>
            <Customer
              customers={customers}
              setCustomers={setCustomers}
              handleUpdatePurchasers={handleUpdatePurchasers}
              handleUpdateBaseData={handleUpdateBaseData}
            />
          </Grid>

          <Grid item xs={12}>
            <Purchaser
              purchasers={purchasers}
              setPurchasers={setPurchasers}
              handleUpdateBaseData={handleUpdateBaseData}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <RequestOrderNumber handleUpdateBaseData={handleUpdateBaseData} offerOrOrder={offerOrOrder} />
              </Grid>
              <Grid item xs={5}>
                <DeliveryDate handleUpdateBaseData={handleUpdateBaseData} />
              </Grid>

              <Grid item xs={2}>
                <Tooltip title="weitere Optionen" placement="bottom-end" followCursor>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => setOpen(!open)}
                    size="large"
                    className={classes.button}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          {open && (
            <Grid item xs={12}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <DeliveryTerm handleUpdateBaseData={handleUpdateBaseData} />
                  </Grid>
                  <Grid item xs={5}>
                    <PaymentTerm handleUpdateBaseData={handleUpdateBaseData} />
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={5}>
                    <Currency handleUpdateBaseData={handleUpdateBaseData} />
                  </Grid>
                  <Grid item xs={5}>
                    <EuDelivery handleUpdateBaseData={handleUpdateBaseData} />
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </Collapse>
            </Grid>
          )}

          <Grid item xs={10}>
            <Submit assignment={assignment} isOffer={isOffer} offerOrOrder={offerOrOrder} />
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Grid>

      <Grid item xs={12} md={8}>
        <Pdf offerOrOrder={offerOrOrder} />
      </Grid>
    </Grid>
  );
}

export default AssignmentForm;
