// @ts-nocheck
// Packages
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Redux
import { dragPositionReducer } from 'src/slices/assignmentSlice';

// Services
import { updatePosition } from 'src/services/position';

// Global Components
import PositionDialog from 'src/components/PositionDialog';

// MUI
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Local Components
import PositionItem from './components/PositionItem';

function Positions({ assignment }) {
  const dispatch = useDispatch();
  const { assignmentType } = useParams();
  const [openPositionDialog, setOpenPositionDialog] = useState(false);
  const { positions, order, customer, deliveryDate, currency } = useSelector(
    (state) => state.assignment.assignmentByAssignmentId,
  );
  const { pdfsByAssignmentId } = useSelector((state) => state.assignment);

  const isOrder = assignmentType === 'order';
  const isOffer = assignmentType === 'offer';

  function isDragableEnabled() {
    return (
      (isOffer || (isOrder && pdfsByAssignmentId.length < 0)) &&
      positions.every((position) => position.packingLists.length < 1)
    );
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  async function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const items = reorder(positions, result.source.index, result.destination.index);
    await handleUpdateDragedPositions(items);
  }

  async function handleUpdateDragedPositions(positions) {
    const updatedPositions = await Promise.all(
      positions.map(async (position, index) => {
        const positionData = { ...position, number: index + 1 };
        const formData = new FormData();
        formData.append('positionData', JSON.stringify(positionData));

        const updatedPosition = await updatePosition(formData);
        return updatedPosition;
      }),
    );

    dispatch(dragPositionReducer(updatedPositions));
  }

  return (
    <>
      <Card>
        <TableContainer>
          <CardHeader
            title={
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>Positionen {positions ? `(${positions.length})` : ''}</Grid>

                {assignmentType !== 'packinglist' &&
                  assignmentType !== 'invoice' &&
                  !order &&
                  !assignment.deliveryCompleted && (
                    <Grid item>
                      <IconButton onClick={() => setOpenPositionDialog(true)} color="primary" size="large">
                        <AddCircleIcon />
                      </IconButton>
                    </Grid>
                  )}
              </Grid>
            }
            subheader={
              (isOrder || isOffer) &&
              positions &&
              `Gesamt netto: ${positions.reduce((curr, next) => curr + next.price * next.quantity, 0)} ${
                currency && currency.symbol
              }`
            }
          />
          <Divider />
          {positions && (
            <Table>
              <TableHead>
                <TableRow>
                  {isDragableEnabled() && <TableCell>&nbsp;</TableCell>}
                  <TableCell>Pos.-Nr.</TableCell>
                  {assignmentType === 'packinglist' && <TableCell>Auftrags-Nr.</TableCell>}
                  <TableCell>Beschreibung</TableCell>
                  <TableCell>Zeichnungsnummer</TableCell>
                  <TableCell>Text</TableCell>
                  <TableCell>Material</TableCell>
                  <TableCell>Menge</TableCell>
                  {assignmentType !== 'packinglist' && (
                    <>
                      <TableCell>Preis</TableCell>
                      <TableCell>Gesamt</TableCell>
                      <TableCell>Steuern</TableCell>

                      {assignmentType !== 'invoice' && !order && <TableCell />}
                    </>
                  )}
                  {assignmentType === 'packinglist' && <TableCell>Status</TableCell>}
                </TableRow>
              </TableHead>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(droppableProvided) => (
                    <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                      {positions.map((position, index) => (
                        <Draggable key={position._id} draggableId={position._id} index={index}>
                          {(draggableProvided) => (
                            <PositionItem
                              index={index}
                              draggableProvided={draggableProvided}
                              key={position._id}
                              assignment={assignment}
                              position={position}
                              isDragableEnabled={isDragableEnabled}
                            />
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          )}
        </TableContainer>
      </Card>
      {openPositionDialog && (
        <PositionDialog
          openPositionDialog={openPositionDialog}
          setOpenPositionDialog={setOpenPositionDialog}
          customer={customer}
          assignmentDeliveryDate={deliveryDate}
          position={undefined}
        />
      )}
    </>
  );
}

export default Positions;
