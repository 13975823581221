// Packages
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import moment from 'moment';

// Interfaces
import { Assignment as AssignmentData } from 'src/services/interfaces/assignment.inteface';

// Services
import { getFilteredAssignment } from 'src/services/assignment';

// Utils
import objToQuery from 'src/utils/objToQuery';

// Global Components
import Link from 'src/components/Link';

// MUI
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { makeStyles } from '@mui/styles';
import EngineeringIcon from '@mui/icons-material/Engineering';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.black.main,
  },
  headTypo: {
    color: theme.palette.white.main,
  },
  headTextfield: {
    color: theme.palette.black.main,
    backgroundColor: theme.palette.white.main,
  },
  headCell: {
    backgroundColor: theme.palette.black.main,
  },
  datePicker: {
    color: theme.palette.black.main,
    backgroundColor: theme.palette.white.main,
    width: '50%',
  },
  tab: {
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.black.main,
    '&:hover': {
      backgroundColor: theme.palette.superLightGrey.main,
    },
    '&.Mui-selected': {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.black.main,
    },
  },
  indicator: {
    backgroundColor: theme.palette.blue.main,
  },
  greenIcon: {
    color: theme.palette.green.main,
  },
  yellowIcon: {
    color: theme.palette.yellow.main,
  },
  typoNumber: {
    fontWeight: 600,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AssignmentOverview = () => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [assignments, setAssignments] = useState(
    {} as {
      from: number;
      to: number;
      total: number;
      page: number;
      pages: number;
      size: number;
      result: AssignmentData[];
      limit: number;
    },
  );
  const [isLoading, setIsLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
  const size = useWindowSize();
  const [path] = useState(location.pathname);
  const [searchQueries, setSearchQueries] = useState({
    number: query.get('number') || '',
    customersNoForOffer: query.get('customersNoForOffer') || '',
    customersNoForOrder: query.get('customersNoForOrder') || '',
    customer: query.get('customer') || '',
    from: query.get('from') || '',
    to: query.get('to') || '',
    type: query.get('type') || '',
    status: query.get('status') || '',
  });

  useEffect(() => {
    handleGetFilteredAssignment(location.search.replace('?', ''));
  }, []); // eslint-disable-line

  useEffect(() => {
    history.listen((location) => {
      if (path === location.pathname) {
        handleGetFilteredAssignment(location.search.replace('?', ''));
      }
    });
  }, [history]); // eslint-disable-line

  function handleQueryChange(event: { target: { name: any; value: any } }) {
    setSearchQueries({ ...searchQueries, [event.target.name]: event.target.value });
  }

  function handleDateQueryChange(event: { target: any }) {
    const { name, value } = event.target;

    history.push({
      pathname: location.pathname,
      search: `?${objToQuery({
        ...searchQueries,
        [name]: value ? moment(value).format('YYYY-MM-DD') : '',
      })}`,
    });

    setSearchQueries({
      ...searchQueries,
      [name]: value ? moment(value).format('YYYY-MM-DD') : '',
    });
  }

  function handleStatusQueryChange(event: { target: { name: any; value: any } }) {
    const { name, value } = event.target;

    history.push({
      pathname: location.pathname,
      search: `?${objToQuery({ ...searchQueries, [name]: value })}`,
    });

    setSearchQueries({ ...searchQueries, [name]: value });
  }

  function handleTypeQueryChange(event: any, value: any) {
    history.push({
      pathname: location.pathname,
      search: `?${objToQuery({ ...searchQueries, type: value })}`,
    });

    setSearchQueries({ ...searchQueries, type: value });
  }

  async function handleGetFilteredAssignment(query: string) {
    const filteredAssignments = await getFilteredAssignment(query);
    setAssignments(filteredAssignments);
    setIsLoading(false);
  }

  function handleSearchSubmit(event: { keyCode: number }) {
    const isQuery = searchQueries.customer || searchQueries.number || searchQueries.type;

    if (event.keyCode === 13) {
      if (isQuery) {
        history.push({
          pathname: location.pathname,
          search: `?${objToQuery(searchQueries)}`,
        });
      } else {
        history.push({ pathname: location.pathname, search: `` });
      }
    }
  }

  function useWindowSize() {
    // +++ copied from: https://usehooks.com/useWindowSize/ +++
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener('resize', handleResize);

      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
  }

  return !isLoading ? (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={searchQueries.type}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTypeQueryChange} TabIndicatorProps={{ className: classes.indicator }}>
              <Tab label="Angebot" value="offer" className={classes.tab} />
              <Tab label="Auftrag" value="order" className={classes.tab} />
              <Tab label="Lieferschein" value="packinglist" className={classes.tab} />
              <Tab label="Rechnung" value="invoice" className={classes.tab} />
            </TabList>
          </Box>
        </TabContext>
      </Box>
      <Card>
        <TableContainer sx={{ maxHeight: size.height - 180, overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell width="20%" className={classes.headCell}>
                  <Tooltip title={`${openFilter ? 'schließe' : 'öffne'} Filter`} placement="bottom-end" followCursor>
                    <Typography className={classes.headTypo} onClick={() => setOpenFilter(!openFilter)}>
                      Nummer
                    </Typography>
                  </Tooltip>
                  {openFilter && (
                    <TextField
                      placeholder="Nummer"
                      name="number"
                      value={searchQueries.number || ''}
                      onChange={handleQueryChange}
                      onKeyDown={handleSearchSubmit}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                setSearchQueries({ ...searchQueries, number: '' });
                                history.push({
                                  pathname: location.pathname,
                                  search: `?${objToQuery({ ...searchQueries, number: '' })}`,
                                });
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        className: classes.headTextfield,
                      }}
                    />
                  )}
                </TableCell>
                {query.get('type') === 'order' && (
                  <TableCell width="5%" className={classes.headCell}>
                    <Tooltip title={`${openFilter ? 'schließe' : 'öffne'} Filter`} placement="bottom-end" followCursor>
                      <Typography className={classes.headTypo} onClick={() => setOpenFilter(!openFilter)}>
                        Status
                      </Typography>
                    </Tooltip>
                    {searchQueries.type === 'order' && openFilter && (
                      <TextField
                        name="status"
                        fullWidth
                        select
                        variant="outlined"
                        margin="dense"
                        value={searchQueries.status || 'opened'}
                        onChange={handleStatusQueryChange}
                        InputProps={{
                          className: classes.headTextfield,
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <MenuItem value="opened">offen</MenuItem>
                        <MenuItem value="completed">geliefert</MenuItem>
                        <MenuItem value="all" className={classes.typoNumber}>
                          alle
                        </MenuItem>
                      </TextField>
                    )}
                  </TableCell>
                )}
                <TableCell width="20%" className={classes.headCell}>
                  <Tooltip title={`${openFilter ? 'schließe' : 'öffne'} Filter`} placement="bottom-end" followCursor>
                    <Typography className={classes.headTypo} onClick={() => setOpenFilter(!openFilter)}>
                      Kunde
                    </Typography>
                  </Tooltip>
                  {openFilter && (
                    <TextField
                      placeholder="Kunde"
                      name="customer"
                      value={searchQueries.customer}
                      onChange={handleQueryChange}
                      onKeyDown={handleSearchSubmit}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                setSearchQueries({ ...searchQueries, customer: '' });
                                history.push({
                                  pathname: location.pathname,
                                  search: `?${objToQuery({ ...searchQueries, customer: '' })}`,
                                });
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        className: classes.headTextfield,
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  )}
                </TableCell>
                {query.get('type') === 'offer' && (
                  <TableCell width="20%" className={classes.headCell}>
                    <Tooltip title={`${openFilter ? 'schließe' : 'öffne'} Filter`} placement="bottom-end" followCursor>
                      <Typography className={classes.headTypo} onClick={() => setOpenFilter(!openFilter)}>
                        Anfrage-Nr.
                      </Typography>
                    </Tooltip>
                    {searchQueries.type === 'offer' && openFilter && (
                      <TextField
                        placeholder="Anfrage-Nr."
                        name="customersNoForOffer"
                        value={searchQueries.customersNoForOffer || ''}
                        onChange={handleQueryChange}
                        onKeyDown={handleSearchSubmit}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setSearchQueries({ ...searchQueries, customersNoForOffer: '' });
                                  history.push({
                                    pathname: location.pathname,
                                    search: `?${objToQuery({ ...searchQueries, customersNoForOffer: '' })}`,
                                  });
                                }}
                              >
                                <CancelIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: classes.headTextfield,
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    )}
                  </TableCell>
                )}
                {query.get('type') === 'order' && (
                  <TableCell width="20%" className={classes.headCell}>
                    <Tooltip title={`${openFilter ? 'schließe' : 'öffne'} Filter`} placement="bottom-end" followCursor>
                      <Typography className={classes.headTypo} onClick={() => setOpenFilter(!openFilter)}>
                        Bestell-Nr.
                      </Typography>
                    </Tooltip>
                    {searchQueries.type === 'order' && openFilter && (
                      <TextField
                        placeholder="Bestell-Nr."
                        name="customersNoForOrder"
                        value={searchQueries.customersNoForOrder || ''}
                        onChange={handleQueryChange}
                        onKeyDown={handleSearchSubmit}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setSearchQueries({ ...searchQueries, customersNoForOrder: '' });
                                  history.push({
                                    pathname: location.pathname,
                                    search: `?${objToQuery({ ...searchQueries, customersNoForOrder: '' })}`,
                                  });
                                }}
                              >
                                <CancelIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: classes.headTextfield,
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    )}
                  </TableCell>
                )}
                {!isMobile && (
                  <TableCell width="35%" className={classes.headCell}>
                    <Grid container>
                      {/* @ts-ignore */}
                      <Grid item mobile={12}>
                        <Tooltip
                          title={`${openFilter ? 'schließe' : 'öffne'} Filter`}
                          placement="bottom-end"
                          followCursor
                        >
                          <Typography className={classes.headTypo} onClick={() => setOpenFilter(!openFilter)}>
                            erstellt am
                          </Typography>
                        </Tooltip>
                      </Grid>
                      {openFilter && (
                        <>
                          {/* @ts-ignore */}
                          <Grid item mobile={5.75}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              utils={DateFnsUtils}
                              locale={deLocale}
                              // @ts-ignore
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            >
                              <DatePicker
                                clearable
                                disableMaskedInput
                                label="von"
                                value={searchQueries.from || null}
                                renderInput={(props) => <TextField {...props} />}
                                onChange={(date) =>
                                  handleDateQueryChange({
                                    target: { name: 'from', value: date },
                                  })
                                }
                                InputProps={{
                                  className: classes.headTextfield,
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          {/* @ts-ignore */}
                          <Grid item mobile={0.5} />
                          {/* @ts-ignore */}
                          <Grid item mobile={5.75}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils} locale={deLocale}>
                              <DatePicker
                                clearable
                                label="bis"
                                value={searchQueries.to || null}
                                renderInput={(props) => <TextField {...props} />}
                                onChange={(date) =>
                                  handleDateQueryChange({
                                    target: { name: 'to', value: date },
                                  })
                                }
                                InputProps={{
                                  className: classes.headTextfield,
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {assignments.result &&
                assignments.result.map((assignment) => (
                  <TableRow hover key={assignment._id}>
                    <TableCell>
                      {assignment.assignmentType === 'offer' && assignment._id && (
                        <Link to={`/assignment/details/offer/${assignment._id}`}>
                          <Typography className={classes.typoNumber}>AN{assignment.number}</Typography>
                        </Link>
                      )}
                      {assignment.assignmentType === 'order' && assignment._id && (
                        <Link to={`/assignment/details/order/${assignment._id}`}>
                          <Typography className={classes.typoNumber}>AB{assignment.number}</Typography>
                        </Link>
                      )}
                      {assignment.assignmentType === 'packinglist' && assignment._id && (
                        <Link to={`/assignment/details/packinglist/${assignment._id}`}>
                          <Typography className={classes.typoNumber}>LS{assignment.number}</Typography>
                        </Link>
                      )}
                      {assignment.assignmentType === 'invoice' && assignment._id && (
                        <Link to={`/assignment/details/invoice/${assignment._id}`}>
                          <Typography className={classes.typoNumber}>RE{assignment.number}</Typography>
                        </Link>
                      )}
                      {assignment.assignmentType === 'order' && assignment.offer && (
                        <Link to={`/assignment/details/offer/${assignment.offer._id}`}>
                          {' '}
                          (aus Angebot {assignment.offer.number})
                        </Link>
                      )}
                      {assignment.assignmentType === 'offer' && assignment.order && (
                        <Link to={`/assignment/details/order/${assignment.order._id}`}>
                          {' '}
                          (zu Auftrag {assignment.order.number})
                        </Link>
                      )}
                    </TableCell>
                    {assignment.assignmentType === 'order' && (
                      <TableCell align="center">
                        <Typography>
                          {assignment.deliveryCompleted ? (
                            <LocalShippingIcon className={classes.greenIcon} />
                          ) : (
                            <EngineeringIcon className={classes.yellowIcon} />
                          )}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell>
                      <Typography>{assignment.customer.name}</Typography>
                    </TableCell>
                    {assignment.assignmentType === 'offer' && (
                      <TableCell>
                        <Typography>{assignment.customersNoForOffer}</Typography>
                      </TableCell>
                    )}
                    {assignment.assignmentType === 'order' && (
                      <TableCell>
                        <Typography>{assignment.customersNoForOrder}</Typography>
                      </TableCell>
                    )}
                    {!isMobile && (
                      <TableCell>
                        <Typography>
                          <Moment format="DD.MM.YYYY">{assignment.dateCreated}</Moment>
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  ) : null;
};

export default AssignmentOverview;
