// Packages
import React from 'react';

// MUI
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme) => ({
  container: { width: '250px' },
  orangeButton: { color: 'white', backgroundColor: 'orange' },
  greenButton: { color: 'white', backgroundColor: theme.palette.green.main },
  disabledButton: { color: 'white', backgroundColor: 'white' },
  digit: { fontSize: '20px', padding: '0' },
}));

interface Props {
  _id: string;
  handleSubmit: () => Promise<void>;
  handleResetPassword: () => Promise<void>;
  passwordStatus: string;
  signInUserData: {
    _id: string;
    password: string;
    passwordStatus: string;
  };
  setSignInUserData: React.Dispatch<
    React.SetStateAction<{
      _id: string;
      password: string;
      passwordStatus: string;
    }>
  >;
  errors: {
    password: string;
  };
}

function NumPad({
  _id,
  handleSubmit,
  handleResetPassword,
  passwordStatus,
  signInUserData,
  setSignInUserData,
  errors,
}: Props): JSX.Element {
  const classes = useStyles();

  function handleAddCharacter(number: string) {
    setSignInUserData({
      ...signInUserData,
      password: String(signInUserData.password + number).slice(0, 4),
      passwordStatus,
      _id,
    });
  }

  function handleDeleteCharacter() {
    setSignInUserData({ ...signInUserData, password: signInUserData.password.slice(0, -1) });
  }

  return (
    <Grid container spacing={1} className={classes.container} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" color="primary" align="center">
          LOGIN
        </Typography>

        {passwordStatus === 'reseted' && (
          <>
            <Typography variant="h6" color="secondary" align="center">
              PIN wurde zurückgesetzt
            </Typography>
            <Typography variant="subtitle2" color="secondary" align="center">
              (bitte neue PIN zweifach eintippen)
            </Typography>
          </>
        )}
        {passwordStatus === 'new' && (
          <>
            <Typography variant="h6" color="secondary" align="center">
              Herzlich willkommen!
            </Typography>
            <Typography variant="subtitle2" color="secondary" align="center">
              (bitte neue PIN zweifach eintippen)
            </Typography>
          </>
        )}
        {passwordStatus === '1stInputOK' && (
          <Typography variant="subtitle2" color="secondary" align="center">
            ...bitte erneut die PIN eintippen
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          value={signInUserData.password}
          name="password"
          type="password"
          placeholder="PIN (4 Ziffern 0-9)"
          label="PIN (4 Ziffern 0-9)"
          inputProps={{ maxLength: 4 }}
          error={!!errors.password}
          helperText={errors && errors.password}
        />
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} fullWidth onClick={() => handleAddCharacter('1')} variant="outlined">
          1
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} fullWidth onClick={() => handleAddCharacter('2')} variant="outlined">
          2
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} fullWidth onClick={() => handleAddCharacter('3')} variant="outlined">
          3
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} fullWidth onClick={() => handleAddCharacter('4')} variant="outlined">
          4
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} fullWidth onClick={() => handleAddCharacter('5')} variant="outlined">
          5
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} fullWidth onClick={() => handleAddCharacter('6')} variant="outlined">
          6
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} fullWidth onClick={() => handleAddCharacter('7')} variant="outlined">
          7
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} fullWidth onClick={() => handleAddCharacter('8')} variant="outlined">
          8
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} fullWidth onClick={() => handleAddCharacter('9')} variant="outlined">
          9
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          fullWidth
          disabled={signInUserData.password.length < 1}
          className={signInUserData.password.length > 0 ? classes.orangeButton : classes.disabledButton}
          onClick={() => handleDeleteCharacter()}
          variant="contained"
        >
          <ArrowBackIcon />
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button className={classes.digit} fullWidth onClick={() => handleAddCharacter('0')} variant="outlined">
          0
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          disabled={signInUserData.password.length !== 4}
          fullWidth
          className={signInUserData.password.length === 4 ? classes.greenButton : classes.disabledButton}
          onClick={passwordStatus === 'saved' ? handleSubmit : handleResetPassword}
          variant="contained"
        >
          <CheckIcon />
        </Button>
      </Grid>
    </Grid>
  );
}

export default NumPad;
