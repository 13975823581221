// Packages
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';
import { signOutReducer } from 'src/slices/authSlice';

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const useStyles = makeStyles((theme) => ({
  icon: { fontSize: 30 },
  root: { display: 'flex' },
  toolbar: { paddingRight: 24, background: theme.palette.black.main },
  title: { flexGrow: 1 },
  appBarSpacer: theme.mixins.toolbar,
  content: { flexGrow: 1, height: '100vh', overflow: 'auto' },
  container: { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4) },
}));

interface Props {
  children: ReactNode;
}

function UserAuth({ children }: Props): JSX.Element {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { company } = useSelector((state: RootStateOrAny) => state.auth);

  function handleLogout() {
    dispatch(signOutReducer());
    dispatch(
      showSnackbarReducer({
        message: 'Logout erfolgreich',
        severity: 'success',
      }),
    );
    history.push('/sign-in-company');
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {`${company.companyName} (${company.email})`}
          </Typography>
          <Button onClick={handleLogout} color="inherit">
            Logout
          </Button>
          <AccountCircleIcon />
          <Typography>{company.companyName}</Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
}

export default UserAuth;
