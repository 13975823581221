// @ts-nocheck

// Packages
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Utils
import isEmpty from 'src/utils/isEmpty';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  abortButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  greenButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

function ServiceDialog({
  editService,
  openServiceDialog,
  setOpenServiceDialog,
  handleCreateService,
  handleUpdateService,
  companyData,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [serviceData, setServiceData] = useState(editService || '');

  useEffect(() => {
    setServiceData(editService || '');
    return () => setServiceData({});
  }, []); //eslint-disable-line

  function handleCloseServiceDialog() {
    setOpenServiceDialog(false);
  }

  function handleChangeServiceData(event) {
    const { value } = event.target;
    setServiceData(value);
  }

  function handleSubmit() {
    const isDuplicateServiceName = companyData.services ? companyData.services.includes(serviceData) : false;

    const serviceIndex = companyData.services ? companyData.services.indexOf(editService) : -1;

    if (!isDuplicateServiceName) {
      isEmpty(editService) ? handleCreateService(serviceData) : handleUpdateService(serviceData, serviceIndex);

      handleCloseServiceDialog();
    } else {
      dispatch(
        showSnackbarReducer({
          message: 'diese Dienstleistung ist bereits vorhanden',
          severity: 'error',
        }),
      );
    }
  }

  return (
    <Dialog maxWidth="md" open={openServiceDialog} onClose={() => handleCloseServiceDialog()}>
      <Box textAlign="center">
        <DialogTitle className={classes.dialogTitle}>
          Dienstleistung {editService ? 'bearbeiten' : 'hinzufügen'}
        </DialogTitle>
      </Box>
      <DialogContent>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Dienstleistung"
              type="text"
              value={serviceData || ''}
              name="name"
              fullWidth
              onChange={handleChangeServiceData}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item mobile={6}>
          <Button
            fullWidth
            variant="contained"
            className={classes.abortButton}
            onClick={() => handleCloseServiceDialog()}
          >
            Abbrechen
          </Button>
        </Grid>
        <Grid item mobile={6}>
          <Button
            fullWidth
            variant="contained"
            className={classes.greenButton}
            onClick={handleSubmit}
            disabled={isEmpty(serviceData) || editService === serviceData}
          >
            {editService ? 'Aktualisieren' : 'Erstellen'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ServiceDialog;
