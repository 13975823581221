// @ts-nocheck

// Packages
import React, { useState } from 'react';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Local Components

// MUI
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import ListItem from './components/ListItem';
import AddressDialog from '../AddressDialog';

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  addButton: {
    color: theme.palette.green.main,
  },
}));

export default function List({ customer, handleCreateAddress, handleUpdateAddress }) {
  const classes = useStyles();
  const [openAddressDialog, setOpenAddressDialog] = useState(false);

  return (
    <>
      <Card>
        <TableContainer>
          <CardHeader
            className={classes.header}
            title={
              <Grid container alignItems="center">
                <Grid item>Anschriften</Grid>
                <Grid item>
                  <Tooltip title="Adresse hinzufügen" placement="bottom-end" followCursor>
                    <IconButton onClick={() => setOpenAddressDialog(true)} size="large" className={classes.addButton}>
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {!isEmpty(customer.addresses) && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>Art</TableCell>
                  <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>Strasse</TableCell>
                  <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>HausNr.</TableCell>
                  <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>PLZ</TableCell>
                  <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>Ort</TableCell>
                  <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>Land</TableCell>
                  <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>aktiv</TableCell>
                  <TableCell sx={{ display: { mobile: 'table-cell', desktop: 'none' } }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {customer.addresses.map((address, index) => (
                  <ListItem
                    key={index}
                    addresses={customer.addresses}
                    address={address}
                    handleCreateAddress={handleCreateAddress}
                    handleUpdateAddress={handleUpdateAddress}
                  />
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Card>
      {openAddressDialog && (
        <AddressDialog
          addresses={customer.addresses}
          handleCreateAddress={handleCreateAddress}
          openAddressDialog={openAddressDialog}
          setOpenAddressDialog={setOpenAddressDialog}
          address={undefined}
          handleUpdateAddress={undefined}
        />
      )}
    </>
  );
}
