// @ts-nocheck
// Packages
import React, { useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

// Utils
import isEmpty from 'src/utils/isEmpty';

// MUI
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

// Interfaces
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';

// Local Components
import UpdateCustomerDialog from './components/UpdateCustomerDialog';
import CreateCustomerDialog from './components/CreateCustomerDialog';

const useStyles = makeStyles({
  button: {
    minHeight: '54px',
  },
});

interface Props {
  customers: CustomerData[];
  setCustomers: React.Dispatch<React.SetStateAction<CustomerData[]>>;
  handleUpdatePurchasers: (customer: CustomerData) => void;
  handleUpdateBaseData: (data: { name: string; value: any }) => void;
}

function Customer({ customers, setCustomers, handleUpdatePurchasers, handleUpdateBaseData }: Props): JSX.Element {
  const classes = useStyles();
  const { customer } = useSelector((state: RootStateOrAny) => state.assignment.assignmentForm);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

  const handleChangeCustomer = (changedCustomer: CustomerData) => {
    changedCustomer._id !== customer._id && handleUpdateBaseData({ name: 'purchaser', value: {} });
    handleUpdateBaseData({ name: 'customer', value: changedCustomer });
    handleUpdateBaseData({
      name: 'deliveryTerm',
      value: changedCustomer.defaultDeliveryTerm,
    });
    handleUpdateBaseData({
      name: 'paymentTerm',
      value: changedCustomer.defaultPaymentTerm,
    });
    handleUpdatePurchasers(changedCustomer);
  };

  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={10}>
          <Autocomplete
            value={!isEmpty(customer) ? customer : null}
            disableClearable
            clearText="Enfernen"
            openText="Oeffnen"
            onChange={(event, customer: CustomerData) => {
              handleChangeCustomer(customer);
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={(option) => `${option.name} (${option.nameShort})`}
            options={customers}
            renderInput={(params) => <TextField {...params} label="Kunde" variant="outlined" />}
          />
        </Grid>

        {isEmpty(customer) && (
          <Grid item xs={1}>
            <Tooltip title="Kunde hinzufügen" placement="bottom-end" followCursor>
              <Button
                variant="contained"
                color="green"
                onClick={() => setIsCreateDialogOpen(true)}
                size="large"
                className={classes.button}
              >
                <AddIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}

        {!isEmpty(customer) && (
          <Grid item xs={1}>
            <Tooltip title="Kunde bearbeiten" placement="bottom-end" followCursor>
              <Button
                variant="contained"
                color="yellow"
                onClick={() => setIsUpdateDialogOpen(true)}
                size="large"
                className={classes.button}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={1} />
      </Grid>
      <CreateCustomerDialog
        customers={customers}
        setCustomers={setCustomers}
        isCreateDialogOpen={isCreateDialogOpen}
        setIsCreateDialogOpen={setIsCreateDialogOpen}
        handleUpdateBaseData={handleUpdateBaseData}
      />
      <UpdateCustomerDialog
        customers={customers}
        setCustomers={setCustomers}
        isUpdateDialogOpen={isUpdateDialogOpen}
        setIsUpdateDialogOpen={setIsUpdateDialogOpen}
        handleUpdateBaseData={handleUpdateBaseData}
      />
    </>
  );
}

export default Customer;
