// @ts-nocheck

// Packages
import React from 'react';

// Global Components
import Link from 'src/components/Link';

// MUI
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  avatarBlue: {
    backgroundColor: theme.palette.blue.main,
  },
  avatarBlueSmall: {
    backgroundColor: theme.palette.blue.main,
  },
  avatarGreen: {
    backgroundColor: theme.palette.green.main,
  },
  avatarGreenSmall: {
    backgroundColor: theme.palette.green.main,
    width: '24',
    height: '24',
  },
  typoHeader: {
    fontWeight: 'bold',
    color: theme.palette.green.main,
    width: '80px',
  },
  customBadge: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.green.main,
  },
  cell: {
    borderBottom: 0,
    minWidth: '100%',
    paddingLeft: '0',
    paddingRight: '0',
  },
  cellAlignRight: {
    display: 'flex',
  },
  cellAlignCenter: {
    display: 'inherit',
  },
  checkBoxPadding: {
    paddingLeft: '0',
  },
  typoBold: {
    fontWeight: 600,
  },
  accDetails: {
    padding: '0',
    backgroundColor: theme.palette.lightGreen.main,
  },
  accSummary: {
    padding: '0 5px 0 0',
    '&:hover': {
      backgroundColor: theme.palette.lightBlue.main,
    },
  },
}));

function Row({ order, toBeSettled, handlePositionOnToBeSettled, handleOrderOnToBeSettled, expanded, handleExpanded }) {
  const classes = useStyles();

  return (
    <Accordion onChange={handleExpanded} expanded={expanded}>
      {/* ORDERS */}
      <AccordionSummary className={classes.accSummary}>
        <Grid container alignItems="center">
          <Grid item mobile={1} dektop={1}>
            <Checkbox
              disabled={!order.positions.some((position) => position.deliveryCompleted)}
              color={
                toBeSettled[order._id] &&
                order.positions.some(
                  (position) => toBeSettled[order._id] && toBeSettled[order._id].includes(position._id),
                ) &&
                !order.positions.every(
                  (position) => toBeSettled[order._id] && toBeSettled[order._id].includes(position._id),
                )
                  ? 'yellow'
                  : 'green'
              }
              indeterminate={
                !!(
                  toBeSettled[order._id] &&
                  order.positions.some(
                    (position) => toBeSettled[order._id] && toBeSettled[order._id].includes(position._id),
                  ) &&
                  !order.positions.every(
                    (position) => toBeSettled[order._id] && toBeSettled[order._id].includes(position._id),
                  )
                )
              }
              checked={
                !!(
                  toBeSettled[order._id] &&
                  order.positions.some(
                    (position) => toBeSettled[order._id] && toBeSettled[order._id].includes(position._id),
                  )
                )
              }
              onChange={() => {
                onChange();
                handleOrderOnToBeSettled(order);
              }}
            />
          </Grid>

          <Grid item mobile={1} desktop={1}>
            {order.numberLetter && (
              <Avatar
                className={classes.avatarBlue}
                sx={{ width: { mobile: 28, desktop: 40 }, height: { mobile: 28, desktop: 40 } }}
              >
                {order.numberLetter ? order.numberLetter : ''}
              </Avatar>
            )}
          </Grid>
          <Grid item mobile={5.5} desktop={3}>
            <Tooltip title={`${order.positions.length} Pos. enthalten`} placement="bottom-end" followCursor>
              <Typography variant="h6" className={classes.typoHeader}>
                <Link to={`/assignment/details/order/${order._id}`}>{order.number}</Link>
              </Typography>
            </Tooltip>
          </Grid>

          <Grid item mobile={0} desktop={3} sx={{ display: { mobile: 'none', desktop: 'flex' } }}>
            <Typography variant="h6" sx={{ fontWeight: '400' }}>
              {order.customersNoForOrder}
            </Typography>
          </Grid>

          <Grid item mobile={0} desktop={2} sx={{ display: { mobile: 'none', desktop: 'flex' } }}>
            {order.deliveryCompleted ? (
              <Chip label="vollständig" color="green" />
            ) : order.positions.some((position) => position.deliveryCompleted) ? (
              <Chip label="teilweise" color="yellow" />
            ) : (
              <Chip label="nein" color="red" />
            )}
          </Grid>

          <Grid item mobile={1} desktop={0} sx={{ display: { mobile: 'block', desktop: 'none' } }}>
            <Grid container justifyContent="center">
              <Grid item>
                {order.deliveryCompleted ? (
                  <CheckIcon color="green" />
                ) : order.positions.some((position) => position.deliveryCompleted) ? (
                  <RemoveIcon color="yellow" />
                ) : (
                  <CloseIcon color="red" />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item mobile={3.5} desktop={2} justifyContent="center">
            <Typography align="right" variant="h6" color="text.red">
              {order.positions
                .reduce((curr, next) => curr + next.price * next.quantity, 0)
                .toFixed(2)
                .toString()
                .replace(/\./g, ',')}{' '}
              {order.currency && order.currency.symbol}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      {/* POSITIONS */}
      <AccordionDetails className={classes.accDetails}>
        {order.positions
          .sort((a, b) => {
            if (a.number < b.number) {
              return -1;
            }
            if (a.number > b.number) {
              return 1;
            }
            return 0;
          })
          .map((position) => (
            <Grid container alignItems="center">
              <Grid item mobile={1} desktop={1}>
                <Checkbox
                  align="center"
                  color="green"
                  checked={!!(toBeSettled[position.order] && toBeSettled[position.order].includes(position._id))}
                  disabled={!position.deliveryCompleted}
                  onChange={() => {
                    handlePositionOnToBeSettled(position);
                  }}
                />
              </Grid>
              <Grid item mobile={1} desktop={1}>
                <Avatar
                  className={classes.avatarGreen}
                  sx={{ width: { mobile: 28, desktop: 40 }, height: { mobile: 28, desktop: 40 } }}
                >
                  {position.number}
                </Avatar>
              </Grid>
              <Grid item mobile={5.5} desktop={5.5}>
                <Typography variant="body1" className={classes.typoBold}>
                  {position.description}
                </Typography>
                <Typography variant="body2" className={classes.typoBold}>
                  nach Zeichnung {position.drawNo} {position.service}
                </Typography>
                <Typography variant="body2" sx={{ display: { mobile: 'none', desktop: 'flex' } }}>
                  {position.text}
                </Typography>
                <Typography variant="body2" sx={{ display: { mobile: 'none', desktop: 'flex' } }}>
                  {position.materialProvided && 'aus kundenseitig beigestelltem Rohmaterial '}
                  {position.materialIncluded && 'inkl. Material '} {position.materialCertificate && 'und Zeugnis \n'}
                </Typography>
                {position.material && (
                  <Typography
                    variant="caption"
                    sx={{ display: { mobile: 'none', desktop: 'flex' } }}
                  >{`(${position.material.name} | ${position.material.shortName} | ${position.material.number} | ${position.material.dinEn})`}</Typography>
                )}
              </Grid>
              <Grid item mobile={1.5} desktop={1}>
                <Typography variant="body1" className={classes.typoBold}>
                  {position.quantityDelivered} {position.unit.shortName}
                </Typography>
              </Grid>
              <Grid item mobile={0} desktop={2} sx={{ display: { mobile: 'none', desktop: 'block' } }}>
                <Typography align="center" variant="body1">
                  {position.price.toFixed(2).toString().replace(/\./g, ',')} {order.currency.symbol}/
                  {position.unit.shortName}
                </Typography>
              </Grid>
              <Grid item mobile={3} desktop={1.5}>
                <Typography align="right" variant="body1" className={classes.typoBold}>
                  {(position.price * position.quantityDelivered).toFixed(2).toString().replace(/\./g, ',')}{' '}
                  {order.currency.symbol}
                </Typography>
              </Grid>
            </Grid>
          ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default Row;
