// @ts-nocheck

// Packages
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Utils
import isEmpty from 'src/utils/isEmpty';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  abortButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  greenButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

function MaterialDialog({
  editMaterial,
  openMaterialDialog,
  setOpenMaterialDialog,
  handleCreateMaterial,
  handleUpdateMaterial,
  companyData,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [materialData, setMaterialData] = useState(editMaterial.name ? editMaterial : {});

  useEffect(() => {
    setMaterialData(editMaterial.name ? editMaterial : {});
    return () => setMaterialData({});
  }, []); //eslint-disable-line

  function handleCloseMaterialDialog() {
    setOpenMaterialDialog(false);
  }

  function handleChangeMaterialData(event) {
    const { name, value } = event.target;
    setMaterialData({ ...materialData, [name]: value });
  }

  function handleSubmit() {
    const isDuplicateMaterialName = companyData.materials
      ? companyData.materials.map((material) => material.name).includes(materialData.name)
      : false;

    const materialIndex = companyData.materials
      ? companyData.materials.map((material) => material.name).indexOf(editMaterial.name)
      : -1;

    if (!isDuplicateMaterialName || editMaterial.name === materialData.name) {
      isEmpty(editMaterial) ? handleCreateMaterial(materialData) : handleUpdateMaterial(materialData, materialIndex);

      handleCloseMaterialDialog();
    } else {
      dispatch(
        showSnackbarReducer({
          message: 'dieser Werkstoff-Name ist bereits vorhanden',
          severity: 'error',
        }),
      );
    }
  }

  return (
    <Dialog maxWidth="md" open={openMaterialDialog} onClose={() => handleCloseMaterialDialog()}>
      <Box textAlign="center">
        <DialogTitle className={classes.dialogTitle}>
          Werkstoff {editMaterial.name ? 'bearbeiten' : 'hinzufügen'}
        </DialogTitle>
      </Box>
      <DialogContent>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              variant="outlined"
              label="Name"
              type="text"
              value={materialData.name || ''}
              name="name"
              fullWidth
              onChange={handleChangeMaterialData}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              variant="outlined"
              label="Kurzname"
              type="text"
              value={materialData.shortName || ''}
              name="shortName"
              fullWidth
              onChange={handleChangeMaterialData}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              variant="outlined"
              label="Nummer"
              type="text"
              value={materialData.number || ''}
              name="number"
              fullWidth
              onChange={handleChangeMaterialData}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              variant="outlined"
              label="DIN EN"
              type="text"
              value={materialData.dinEn || ''}
              name="dinEn"
              fullWidth
              onChange={handleChangeMaterialData}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item mobile={6}>
          <Button
            fullWidth
            variant="contained"
            className={classes.abortButton}
            onClick={() => handleCloseMaterialDialog()}
          >
            Abbrechen
          </Button>
        </Grid>
        <Grid item mobile={6}>
          <Button
            fullWidth
            variant="contained"
            className={classes.greenButton}
            onClick={handleSubmit}
            disabled={
              isEmpty(materialData.name) ||
              (editMaterial.name === materialData.name &&
                editMaterial.shortName === materialData.shortName &&
                editMaterial.number === materialData.number &&
                editMaterial.dinEn === materialData.dinEn)
            }
          >
            {editMaterial.name ? 'Aktualisieren' : 'Erstellen'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default MaterialDialog;
