// @ts-nocheck

// Packages
import React, { useState } from 'react';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Global Components

// Local Components

// MUI
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import ListItem from './components/ListItem';
import PurchaserDialog from '../../../../components/PurchaserDialog';

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  addButton: {
    color: theme.palette.green.main,
  },
}));

export default function Purchasers({ purchasers, setPurchasers }) {
  const classes = useStyles();
  const [openPurchaserDialog, setOpenPurchaserDialog] = useState(false);

  return (
    <>
      <Card>
        <TableContainer>
          <CardHeader
            className={classes.header}
            title={
              <Grid container alignItems="center">
                <Grid item>Bearbeiter</Grid>
                <Grid item>
                  <Tooltip title="Bearbeiter hinzufügen" placement="bottom-end" followCursor>
                    <IconButton onClick={() => setOpenPurchaserDialog(true)} size="large" className={classes.addButton}>
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {!isEmpty(purchasers) && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>Anrede</TableCell>
                  <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>Name</TableCell>
                  <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>Tel.</TableCell>
                  <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>E-Mail</TableCell>
                  <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>aktiv</TableCell>
                  <TableCell sx={{ display: { mobile: 'table-cell', desktop: 'none' } }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {purchasers.map((purchaser, index) => (
                  <ListItem key={index} purchaser={purchaser} purchasers={purchasers} setPurchasers={setPurchasers} />
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Card>
      {openPurchaserDialog && (
        <PurchaserDialog
          openPurchaserDialog={openPurchaserDialog}
          setOpenPurchaserDialog={setOpenPurchaserDialog}
          purchasers={purchasers}
          setPurchasers={setPurchasers}
          handleUpdateBaseData={undefined}
          purchaser={undefined}
        />
      )}
    </>
  );
}
