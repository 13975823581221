// @ts-nocheck
// Packages
import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

// Local Components

// Global Components
import { Link } from 'src/components';

// Utils
import toggleFullScreen from 'src/utils/toggleFullScreen';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';
import { signOutReducer } from 'src/slices/authSlice';

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import PostAddIcon from '@mui/icons-material/PostAdd';
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EuroIcon from '@mui/icons-material/Euro';
import StorageIcon from '@mui/icons-material/Storage';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';

// React-icons
import { FiLogOut } from 'react-icons/fi';
import DrawerListItem from './components/DrawerListItem';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  icon: { fontSize: 30 },
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    justifyContent: 'flex-start',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.down('desktop')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  menu: {
    padding: '0',
  },
  typoUsername: {
    padding: '10px 0 10px 0',
    fontWeight: '600',
    color: theme.palette.black.main,
  },
}));

interface Props {
  children: ReactNode;
}

function App({ children }: Props): JSX.Element {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const isAuthenticated = useSelector((state: RootStateOrAny) => state.auth.isAuthenticated);
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  useEffect(() => {
    handleSetPageTitle();
  }, [history]);

  function handleSetPageTitle() {
    if (pageTitle !== history.location.pathname) {
      let convertedPageTitle = '';

      switch (history.location.pathname) {
        case '/assignment/create':
          convertedPageTitle = 'Auftrag erstellen';
          break;
        case '/packinglist':
          convertedPageTitle = 'Lieferschein erstellen';
          break;
        case '/invoice':
          convertedPageTitle = 'Rechnung erstellen';
          break;
        case '/assignment/overview':
          convertedPageTitle = 'Auftrags-Übersicht';
          break;
        case '/warehouse':
          convertedPageTitle = 'Lager';
          break;
        case '/customer/overview':
          convertedPageTitle = 'Kunden-Verwaltung';
          break;
        case '/staff':
          convertedPageTitle = 'Benutzer-Verwaltung';
          break;
        case '/company-change-data':
          convertedPageTitle = 'Einstellungen';
          break;
        default:
          convertedPageTitle = '';
      }
      setPageTitle(convertedPageTitle);
    }
  }

  function handleLogout() {
    handleDrawerClose();
    dispatch(signOutReducer());
    dispatch(
      showSnackbarReducer({
        message: 'Logout erfolgreich',
        severity: 'success',
      }),
    );
    history.push(user ? '/sign-in-user' : '/sign-in-company');
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  // @ts-ignore
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <IconButton onClick={toggleFullScreen} style={{ color: 'white' }} className={classes.title}>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              {pageTitle}
            </Typography>
          </IconButton>
          {isAuthenticated ? (
            <>
              <Tooltip title={`${user.firstName} ${user.lastName}`} placement="bottom-end" followCursor>
                <Button
                  id="basic-button"
                  onClick={handleClickMenu}
                  aria-controls={openMenu ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                  color="inherit"
                >
                  {user.avatar ? <Avatar src={user.avatar.Location} /> : <AccountCircleIcon />}
                </Button>
              </Tooltip>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                classes={{ list: classes.menu }}
              >
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                  <Grid item>
                    <Typography variant="subtitle1" className={classes.typoUsername}>
                      {user.firstName} {user.lastName}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Button
                      fullWidth
                      onClick={handleLogout}
                      variant="contained"
                      color="red"
                      size="large"
                      startIcon={<FiLogOut />}
                    >
                      ABMELDEN
                    </Button>
                  </Grid>
                </Grid>
              </Menu>
            </>
          ) : (
            <Button to="/sign-in-user" component={Link} color="inherit">
              Benutzer-Anmeldung
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <List>
          <Link to="/assignment/create">
            <DrawerListItem itemText="Auftrag Erstellen" itemIcon={<PostAddIcon className={classes.icon} />} />
          </Link>
          <Link to="/packinglist">
            <DrawerListItem
              itemText="Lieferschein Erstellen"
              itemIcon={<LocalShippingIcon className={classes.icon} />}
            />
          </Link>
          <Link to="/invoice">
            <DrawerListItem itemText="Rechnung Erstellen" itemIcon={<EuroIcon className={classes.icon} />} />
          </Link>
          <Link to="/assignment/overview?type=order&status=opened">
            <DrawerListItem itemText="Dokumente" itemIcon={<DescriptionIcon className={classes.icon} />} />
          </Link>
          <Link to="/warehouse">
            <DrawerListItem itemText="Lagerverwaltung" itemIcon={<StorageIcon className={classes.icon} />} />
          </Link>
          {user.role === 'admin' && (
            <>
              <Divider />

              <Link to="/customer/overview">
                <DrawerListItem itemText="Kunden" itemIcon={<SupervisedUserCircleIcon className={classes.icon} />} />
              </Link>

              <Divider />

              <Link to="/staff">
                <DrawerListItem itemText="Benutzer" itemIcon={<GroupIcon className={classes.icon} />} />
              </Link>
              <Link to="/company-change-data">
                <DrawerListItem itemText="Einstellungen" itemIcon={<SettingsIcon className={classes.icon} />} />
              </Link>
            </>
          )}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
}

export default App;
