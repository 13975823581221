// @ts-nocheck
// Packages
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Services
import { getAllUser, updateUser, getMaxPersonnelNo } from 'src/services/user';
import { register } from 'src/services/auth';

// Interfaces
import { User as UserData } from 'src/services/interfaces/user.interface';

// MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { makeStyles } from '@mui/styles';
import Switch from '@mui/material/Switch';
import StaffItem from './components/StaffItem';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.blue.main,
  },
  addButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.green.main,
    minHeight: '150px',
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
  deactivatedButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.green.main,
    minHeight: '50px',
    maxHeight: '50px',
    borderRadius: '0 0 4px 4px',
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  abortButton: {
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  submitButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
  iconPersonAdd: {
    width: 40,
    height: 40,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  switch: {
    '&.Mui-checked': {
      color: theme.palette.white.main,
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.black.main,
    },
  },
}));

function Staff() {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showDeactivatedUser, setShowDeactivatedUser] = useState(false);
  const [users, setUsers] = useState([] as UserData[]);
  const [newUser, setNewUser] = useState({
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    personnelNumber: 0,
    role: '',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    getInitialData();
  }, []);

  function handleClickOpen() {
    setIsDialogOpen(true);
    findMaxPersonnelNo();
  }

  async function findMaxPersonnelNo() {
    try {
      const foundMaxPersonnelNo = await getMaxPersonnelNo();
      setNewUser({ ...newUser, personnelNumber: foundMaxPersonnelNo + 1 });
    } catch (error) {
      if (error) throw error;
    }
  }

  function handleClose() {
    setIsDialogOpen(false);
    resetNewUser();
  }

  async function getInitialData() {
    try {
      const foundUsers = await getAllUser();
      setUsers(foundUsers);
    } catch (error) {
      if (error) throw error;
    }
  }

  function handleChange(event: { target: { name: any; value: any } }) {
    setNewUser({ ...newUser, [event.target.name]: event.target.value });
  }

  function resetNewUser() {
    setNewUser({ firstName: '', lastName: '', username: '', password: '', personnelNumber: 0, role: '' });
  }

  async function handleSubmit() {
    try {
      const registeredUser = await register({
        ...newUser,
        username: `${newUser.firstName.toLowerCase()}_${newUser.lastName.toLowerCase()}`,
      });

      setUsers([...users, registeredUser].sort((a, b) => (a.username > b.username ? -1 : 1)));

      setIsDialogOpen(false);
      resetNewUser();
      dispatch(
        showSnackbarReducer({
          message: 'Neuer Benutzer wurde angelegt',
          severity: 'success',
        }),
      );
    } catch (error) {
      if (error) throw error;
    }
  }

  return (
    <>
      <Grid container spacing={6}>
        <Grid item mobile={6} desktop={2}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item sx={{ padding: 0, width: '100%' }}>
              <Tooltip title="Benutzer hinzufügen" placement="bottom-end" followCursor>
                <Button fullWidth onClick={handleClickOpen} variant="contained" className={classes.addButton}>
                  <Grid container direction="column">
                    <Grid item sx={{ padding: 0, width: '100%' }}>
                      <PersonAddIcon className={classes.iconPersonAdd} />
                    </Grid>
                    <Grid item sx={{ padding: 0, width: '100%' }}>
                      Benutzer hinzufügen
                    </Grid>
                  </Grid>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item sx={{ padding: 0, width: '100%' }}>
              <Tooltip
                title={showDeactivatedUser ? 'NUR AKTIVE Benutzer anzeigen' : 'ALLE Benutzer anzeigen'}
                placement="bottom-end"
                followCursor
              >
                <Button
                  fullWidth
                  onClick={() => setShowDeactivatedUser(!showDeactivatedUser)}
                  variant="contained"
                  className={classes.deactivatedButton}
                >
                  <Switch checked={!showDeactivatedUser} classes={{ colorPrimary: classes.switch }} />
                  {showDeactivatedUser ? 'alle' : 'nur Aktive'}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        {users
          .sort((a, b) => a.personnelNumber > b.personnelNumber)
          .map((user, index) => (
            <>
              {user.isActive && (
                <Grid item mobile={6} desktop={2}>
                  <StaffItem key={index} user={user} updateUser={updateUser} getInitialData={getInitialData} />
                </Grid>
              )}
              {showDeactivatedUser && !user.isActive && (
                <Grid item mobile={6} desktop={2}>
                  <StaffItem key={index} user={user} updateUser={updateUser} getInitialData={getInitialData} />
                </Grid>
              )}
            </>
          ))}
      </Grid>

      <Dialog open={isDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Box textAlign="center">
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            Benutzer hinzufügen
          </DialogTitle>
        </Box>

        <DialogContent>
          <Grid container spacing={3}>
            <Grid item mobile={12}>
              <TextField
                variant="outlined"
                label="Vorname"
                type="text"
                value={newUser.firstName}
                name="firstName"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item mobile={12}>
              <TextField
                variant="outlined"
                label="Nachname"
                type="text"
                value={newUser.lastName}
                name="lastName"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item mobile={12}>
              <TextField
                variant="outlined"
                label="Personal-Nummer"
                type="text"
                helperText="neue Personal-Nummer wurde automatisch eingefügt"
                value={newUser.personnelNumber}
                onChange={handleChange}
                name="personnelNumber"
                fullWidth
              />
            </Grid>
            <Grid item mobile={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Rolle</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={newUser.role}
                  onChange={handleChange}
                  label="Age"
                  name="role"
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="chief">Chef</MenuItem>
                  <MenuItem value="employee">Mitarbeiter</MenuItem>
                  <MenuItem value="foreman">Vorarbeiter</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.abortButton} fullWidth variant="contained" size="large">
            Abbrechen
          </Button>
          <Button
            className={classes.submitButton}
            fullWidth
            variant="contained"
            size="large"
            onClick={handleSubmit}
            disabled={!newUser.firstName || !newUser.lastName || !newUser.personnelNumber || !newUser.role}
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Staff;
