// @ts-nocheck

// Packages
import React from 'react';

// Views
import { App as AppLayout } from 'src/layouts';
import { default as CompanyChangeDataView } from './_CompanyChangeData';

// Layouts

function Settings() {
  return (
    <AppLayout>
      <CompanyChangeDataView />
    </AppLayout>
  );
}

export default Settings;
