// Packages
import axios from 'axios';

// Interfaces
import { Customer as CustomerData } from './interfaces/customer.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/customer`;

export function getCustomersByCompanyId() {
  return axios.get(`${apiURI}/get-customers-by-company-id`).then((response) => response.data as CustomerData[]);
}

export function getCustomerByCustomerId(data: { customerId: string }) {
  return axios.post(`${apiURI}/get-customer-by-customer-id`, data).then((response) => response.data as CustomerData);
}

export function createCustomer(data: { number: string; name: string; nameShort: string }) {
  return axios.post(`${apiURI}/create-customer`, data).then((response) => response.data as CustomerData);
}

export function updateCustomer(data: CustomerData) {
  return axios.post(`${apiURI}/update-customer`, data).then((response) => response.data as CustomerData);
}

export function getNextFreeCustomerNumberByCompanyId(data: { number: number }) {
  return axios
    .post(`${apiURI}/get-next-free-customer-number-by-company-id`, data)
    .then((response) => response.data as string);
}

export function checkDuplicateCustomerNumberByCompanyId(data: { number: string }) {
  return axios
    .post(`${apiURI}/check-duplicate-customer-number-by-company-id`, data)
    .then((response) => response.data as boolean);
}
