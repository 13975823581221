// @ts-nocheck

// Packages
import React, { useState } from 'react';

// MUI
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import PurchaserDialog from '../../../../../../components/PurchaserDialog';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.lightBlue.main} !important`,
      cursor: 'pointer',
    },
  },
  deleteButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
}));

export default function ListItem({ purchaser, purchasers, setPurchasers }) {
  const classes = useStyles();
  const [openPurchaserDialog, setOpenPurchaserDialog] = useState(false);

  return (
    <>
      <Tooltip title="Bearbeiter ändern" placement="bottom-end" followCursor>
        <TableRow hover className={classes.tableRow} key={purchaser._id} onClick={() => setOpenPurchaserDialog(true)}>
          <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>
            {purchaser.salutation.shortTitle}
          </TableCell>
          <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>{purchaser.name}</TableCell>
          <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>{purchaser.tel}</TableCell>
          <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>{purchaser.email}</TableCell>
          <TableCell sx={{ display: { mobile: 'none', desktop: 'table-cell' } }}>
            {purchaser.isActive ? 'ja' : 'nein'}
          </TableCell>
          <TableCell sx={{ display: { mobile: 'table-cell', desktop: 'none' } }}>
            {purchaser.salutation.shortTitle} {purchaser.name} <br />
            {purchaser.tel} <br />
            {purchaser.email}
          </TableCell>
        </TableRow>
      </Tooltip>
      {openPurchaserDialog && (
        <PurchaserDialog
          purchaser={purchaser}
          purchasers={purchasers}
          setPurchasers={setPurchasers}
          openPurchaserDialog={openPurchaserDialog}
          setOpenPurchaserDialog={setOpenPurchaserDialog}
          handleUpdateBaseData={undefined}
        />
      )}
    </>
  );
}
