import { createTheme, Theme } from '@mui/material/styles';

import palette from './_palette';
import components from './components';
// import typography from './_typography';
import breakpoints from './_breakpoints';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  // @ts-ignore
  palette,
  components,
  breakpoints,
});

export default theme;
