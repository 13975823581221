// @ts-nocheck

// Packages
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';
import { setCurrentUserReducer } from 'src/slices/authSlice';

import { addAvatar, deleteAvatar, getCurrentUser } from 'src/services/user';

// MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.blue.main,
    minHeight: '150px',
    maxHeight: '200px',
    height: '30vh',
    textTransform: 'none',
  },
  buttonDeactivated: {
    color: theme.palette.black.main,
    backgroundColor: theme.palette.white.main,
    minHeight: '150px',
    maxHeight: '200px',
    height: '30vh',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.white.main,
    },
  },
  typoName: { fontSize: '20px', fontWeight: '400' },
  typoRole: { fontSize: '12px', fontWeight: '200' },
  typoNumber: { fontSize: '20px', fontWeight: '400' },
  typoDeactivated: { color: theme.palette.yellow.main, fontSize: '16px', fontWeight: '400' },
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  avatarDialog: {
    width: 200,
    height: 200,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  avatarItem: {
    width: 60,
    height: 60,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconDialog: {
    width: 150,
    height: 150,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function StaffItem({ user, updateUser, getInitialData }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const role = useSelector((state) => state.auth.user.role);
  const loggedInUser = useSelector((state: RootStateOrAny) => state.auth.user);
  const [userState, setUserState] = useState(user);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function handleClickOpen() {
    setIsDialogOpen(true);
  }

  function handleClose() {
    setIsDialogOpen(false);
    setUserState(user);
  }

  function handleChange(event) {
    setUserState({ ...userState, [event.target.name]: event.target.value });
  }

  async function handleResetPassword() {
    const { data } = await updateUser({
      ...userState,
      password: '',
      passwordStatus: 'reseted',
    });
    setUserState(data);
    getInitialData();
    dispatch(
      showSnackbarReducer({
        message: 'PIN wurde zurückgesetzt',
        severity: 'success',
      }),
    );
  }

  async function handleToggle(userData) {
    const { data } = await updateUser(userData);
    setUserState(data);
  }

  async function handleSaveUser() {
    updateUser(userState);
    getInitialData();
    setIsDialogOpen(false);
  }

  async function handleAvatarAdd(event) {
    try {
      event.preventDefault();
      const formData = new FormData();
      formData.append('avatar', event.target.files[0]);
      formData.append('userId', user._id);
      await addAvatar(formData);
      getInitialData();
      if (loggedInUser._id === user._id) {
        const foundUser = await getCurrentUser();
        dispatch(setCurrentUserReducer(foundUser));
      }
    } catch (error) {
      if (error) throw error;
    }
  }

  async function handleAvatarDelete() {
    try {
      await deleteAvatar({ user });
      getInitialData();
      if (loggedInUser._id === user._id) {
        const foundUser = await getCurrentUser();
        dispatch(setCurrentUserReducer(foundUser));
      }
    } catch (error) {
      if (error) throw error;
    }
  }

  return (
    <>
      <Tooltip title="Benutzer ändern" placement="bottom-end" followCursor>
        <Button
          fullWidth
          variant="contained"
          onClick={handleClickOpen}
          className={clsx(user.isActive ? classes.button : classes.buttonDeactivated)}
        >
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item>
              {user.avatar ? (
                <Avatar
                  alt={`${userState.firstName} ${userState.lastName}`}
                  src={user.avatar.Location}
                  className={classes.avatarItem}
                  onClick={handleClickOpen}
                />
              ) : (
                <Avatar onClick={handleClickOpen}>
                  <PersonIcon />
                </Avatar>
              )}
            </Grid>

            <Grid item>
              <Typography variant="h6" className={classes.typoName}>
                {`${user.firstName} ${user.lastName}`}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h6" className={classes.typoRole}>
                {user.role === 'admin' && `Administrator`}
                {user.role === 'chief' && `Chef`}
                {user.role === 'foreman' && `Vorarbeiter`}
                {user.role === 'employee' && `Mitarbeiter`}
              </Typography>
            </Grid>

            {user.personnelNumber !== 0 && (
              <Grid item>
                <Typography variant="h6" className={classes.typoNumber}>
                  # {user.personnelNumber}
                </Typography>
              </Grid>
            )}

            {user.isActive === false && (
              <Grid item>
                <Typography variant="h6" className={classes.typoDeactivated}>
                  deaktiviert
                </Typography>
              </Grid>
            )}
          </Grid>
        </Button>
      </Tooltip>

      <Dialog maxWidth="xs" open={isDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Box textAlign="center">
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            Benutzer bearbeiten
          </DialogTitle>
        </Box>

        <DialogContent sx={{ alignContent: 'center' }}>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item mobile={12}>
              <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item>
                  {user.avatar ? (
                    <Tooltip title="Foto löschen" placement="bottom-end" followCursor>
                      <Avatar
                        alt={`${userState.firstName} ${userState.lastName}`}
                        src={user.avatar.Location}
                        className={classes.avatarDialog}
                        onClick={handleAvatarDelete}
                      />
                    </Tooltip>
                  ) : (
                    <>
                      <input
                        accept="image/jpeg"
                        onChange={handleAvatarAdd}
                        className={userState.image}
                        id="raised-button-file"
                        type="file"
                        hidden
                      />
                      <div className="icons">
                        <label htmlFor="raised-button-file">
                          <Tooltip title="Foto hinzufügen" placement="bottom-end" followCursor>
                            <Avatar className={classes.avatarDialog}>
                              <PersonIcon className={classes.iconDialog} />
                            </Avatar>
                          </Tooltip>
                        </label>
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item mobile={12}>
              <TextField
                variant="outlined"
                label="Vorname"
                type="text"
                value={userState.firstName}
                name="firstName"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item mobile={12}>
              <TextField
                variant="outlined"
                label="Nachname"
                type="text"
                value={userState.lastName}
                name="lastName"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item mobile={12}>
              <TextField
                variant="outlined"
                label="Personal-Nummer"
                type="text"
                value={userState.personnelNumber}
                name="personnelNumber"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item mobile={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Rolle</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={userState.role}
                  onChange={handleChange}
                  label="Age"
                  name="role"
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="chief">Chef</MenuItem>
                  <MenuItem value="employee">Mitarbeiter</MenuItem>
                  <MenuItem value="foreman">Vorarbeiter</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item mobile={12}>
              <Box textAlign="center">
                ist aktiv
                <Switch
                  disabled={role !== 'admin'}
                  onChange={() =>
                    handleToggle({
                      _id: userState._id,
                      isActive: !userState.isActive,
                    })
                  }
                  checked={userState.isActive}
                />
              </Box>
            </Grid>
            <Grid item mobile={12}>
              {(userState.passwordStatus === 'saved' || userState.passwordStatus === '1stInputOK') && (
                <Button fullWidth variant="contained" color="red" onClick={handleResetPassword}>
                  PIN zurücksetzen
                </Button>
              )}
              {userState.passwordStatus === 'reseted' && (
                <Typography variant="subtitle1" color="text.red" align="center">
                  PIN wurde zurückgesetzt
                </Typography>
              )}
              {userState.passwordStatus === 'new' && (
                <Typography variant="subtitle1" color="text.blue" align="center">
                  - noch keine PIN vergeben -
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid item mobile={6}>
            <Button onClick={handleClose} color="red" fullWidth variant="contained" size="large">
              Abbrechen
            </Button>
          </Grid>
          <Grid item mobile={6}>
            <Button onClick={handleSaveUser} color="green" fullWidth variant="contained" size="large">
              Speichern
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default StaffItem;
