// Packages
import React from 'react';

// Packages
import { useState, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Global Components
import { TextField, Link } from 'src/components';

// Redux
import { signInCompanyReducer } from 'src/slices/authSlice';

// Services
import { signUpCompany } from 'src/services/auth';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import MuiLink from '@mui/material/Link';

import logo from 'src/assets/images/digifull.jpeg';

const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    opacity: 0.8,
    paddingBottom: '80px',
  },
  cardTitle: {
    padding: '16px',
    backgroundColor: theme.palette.black.main,
    borderRadius: '10px 10px 0 0',
  },
  cardContent: {
    padding: '16px',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0 0 10px 10px',
  },
  grid: {
    width: '100%',
    alignContent: 'center',
    alignItems: 'center',
  },
  gridTextfield: {
    width: '90%',
    paddingTop: '24px',
  },
  gridPassword: {
    width: '90%',
    paddingTop: '0',
    paddingLeft: '8px',
    textAlign: 'center',
  },
  redButton: {
    color: theme.palette.white.main,
    borderColor: theme.palette.red.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
      borderColor: theme.palette.red.main,
    },
    fontSize: '24px',
  },
  box: {
    padding: '32px',
  },
}));

function SignUpCompany() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isAuthenticated, isCompany, isUser } = useSelector((state: RootStateOrAny) => state.auth);
  const [errors, setErrors] = useState(
    {} as { email: string; companyName: string; firstName: string; lastName: string; password: string },
  );
  const [companySignUpData, setCompanySignUpData] = useState({
    email: '',
    companyName: '',
    firstName: '',
    lastName: '',
    password: '',
  });

  useEffect(() => {
    if (isAuthenticated && isCompany) {
      history.push('/sign-in-user');
    } else if (isAuthenticated && isUser) {
      history.push('/assignment/create');
    }
  }, [isAuthenticated, history, isCompany, isUser]);

  const handleChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setCompanySignUpData({ ...companySignUpData, [name]: value });
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    try {
      event.preventDefault();
      const jwtToken = await signUpCompany(companySignUpData);
      await dispatch(signInCompanyReducer(jwtToken));
      history.push('sign-in-user');
    } catch (error) {
      // @ts-ignore
      setErrors(error.response.data);
    }
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
        {/* @ts-ignore */}
        <Grid item mobile={12} className={classes.grid}>
          <MuiLink href="https://www.digifull.de/" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="DIGIFULL-logo" className={classes.logo} />
          </MuiLink>
        </Grid>

        {/* @ts-ignore */}
        <Grid item mobile={12} className={classes.grid}>
          <Card className={classes.cardTitle}>
            <Typography variant="h5" color="text.white" align="center">
              Firmen-Registrierung
            </Typography>
          </Card>
        </Grid>
        {/* @ts-ignore */}
        <Grid item mobile={12} className={classes.grid}>
          <Card className={classes.cardContent}>
            <form onSubmit={handleSubmit}>
              <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                {/* @ts-ignore */}
                <Grid item mobile={12} className={classes.gridTextfield}>
                  <TextField
                    placeholder="E-Mail Adresse"
                    label="E-Mail Adresse"
                    name="email"
                    value={companySignUpData.email}
                    onChange={handleChange}
                    error={errors && errors.email}
                    margin={undefined}
                    color={undefined}
                    type={undefined}
                    multiline={undefined}
                    rows={undefined}
                  />
                </Grid>
                {/* @ts-ignore */}
                <Grid item mobile={12} className={classes.gridTextfield}>
                  <TextField
                    placeholder="Firmenname"
                    label="Firmenname"
                    name="companyName"
                    value={companySignUpData.companyName}
                    onChange={handleChange}
                    error={errors && errors.companyName}
                    margin={undefined}
                    color={undefined}
                    type={undefined}
                    multiline={undefined}
                    rows={undefined}
                  />
                </Grid>
                {/* @ts-ignore */}
                <Grid item mobile={12} className={classes.gridTextfield}>
                  <TextField
                    placeholder="Vorname"
                    label="Vorname"
                    name="firstName"
                    value={companySignUpData.firstName}
                    onChange={handleChange}
                    error={errors && errors.firstName}
                    margin={undefined}
                    color={undefined}
                    type={undefined}
                    multiline={undefined}
                    rows={undefined}
                  />
                </Grid>
                {/* @ts-ignore */}
                <Grid item mobile={12} className={classes.gridTextfield}>
                  <TextField
                    placeholder="Nachname"
                    label="Nachname"
                    name="lastName"
                    value={companySignUpData.lastName}
                    onChange={handleChange}
                    error={errors && errors.lastName}
                    margin={undefined}
                    color={undefined}
                    type={undefined}
                    multiline={undefined}
                    rows={undefined}
                  />
                </Grid>
                {/* @ts-ignore */}
                <Grid item mobile={12} className={classes.gridTextfield}>
                  <TextField
                    type="password"
                    placeholder="Passwort"
                    label="Passwort"
                    name="password"
                    value={companySignUpData.password}
                    onChange={handleChange}
                    error={errors && errors.password}
                    margin={undefined}
                    color={undefined}
                    multiline={undefined}
                    rows={undefined}
                  />
                </Grid>
                {/* @ts-ignore */}
                <Grid item mobile={12} className={classes.gridTextfield}>
                  <Button
                    fullWidth
                    type="submit"
                    onClick={handleSubmit}
                    variant="outlined"
                    className={classes.redButton}
                  >
                    Registrieren
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>

      <Box textAlign="center" className={classes.box}>
        <Link to="/sign-in-company">
          <Typography variant="button" color="text.blue">
            Du hast bereits einen Zugang?
          </Typography>
        </Link>
      </Box>
    </>
  );
}

export default SignUpCompany;
