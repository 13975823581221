// @ts-nocheck
// Packages
import React from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

// Redux
import { updatePdfReducer } from 'src/slices/assignmentSlice';

// Interfaces
import { Offer as OfferData } from 'src/services/interfaces/offer.interface';
import { Order as OrderData } from 'src/services/interfaces/order.interface';

// MUI
import { makeStyles } from '@mui/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

// React-Icons
import { IoDocumentTextOutline } from 'react-icons/io5';
import { MdOutlineDeleteForever } from 'react-icons/md';

const useStyles = makeStyles({
  button: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
});

interface Props {
  assignment: OrderData | OfferData;
  offerOrOrder: 'Angebot' | 'Auftrag';
  setOfferOrOrder: React.Dispatch<React.SetStateAction<'Angebot' | 'Auftrag'>>;
  handleUpdateBaseData: (data: { name: string; value: any }) => void;
}

function OfferOrConfirmation({ assignment, offerOrOrder, setOfferOrOrder, handleUpdateBaseData }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pdf } = useSelector((state: RootStateOrAny) => state.assignment.assignmentForm);

  async function onFileChange(event) {
    dispatch(updatePdfReducer(event.target.files[0]));
  }

  async function onFileDelete() {
    dispatch(updatePdfReducer(null));
    assignment && handleUpdateBaseData({ name: 'pdfDeleted', value: true });
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={10}>
        <ToggleButtonGroup exclusive value={offerOrOrder} className={classes.button} disabled={assignment}>
          <ToggleButton
            className={classes.button}
            onClick={() => setOfferOrOrder('Angebot')}
            value="Angebot"
            aria-label="centered"
          >
            Angebot
          </ToggleButton>

          <ToggleButton
            className={classes.button}
            onClick={() => setOfferOrOrder('Auftrag')}
            value="Auftrag"
            aria-label="centered"
          >
            Auftrag
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={2}>
        {pdf === null ? (
          <>
            <input
              accept="application/pdf"
              onChange={onFileChange}
              className={classes.input}
              id="raised-button-file"
              type="file"
            />
            <div className="icons">
              <label htmlFor="raised-button-file">
                <Tooltip
                  title={`PDF der ${offerOrOrder === 'Angebot' ? 'Anfrage' : 'Bestellung'} hinzufügen`}
                  placement="bottom-end"
                  followCursor
                >
                  <Button variant="contained" component="span" color="green">
                    <IoDocumentTextOutline fontSize="2.2rem" color="white" />
                  </Button>
                </Tooltip>
              </label>
            </div>
          </>
        ) : (
          <Tooltip
            title={`PDF der ${offerOrOrder === 'Angebot' ? 'Anfrage' : 'Bestellung'} löschen`}
            placement="bottom-end"
            followCursor
          >
            <Button onClick={onFileDelete} variant="contained" component="span" color="red">
              <MdOutlineDeleteForever fontSize="2.2rem" color="white" />
            </Button>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
}

export default OfferOrConfirmation;
