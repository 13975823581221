// Packages
import axios from 'axios';
import { Company } from './interfaces/company.interface';
import { User } from './interfaces/user.interface';

// Interfaces
import { WarehousePlace as WarehousePlaceData } from './interfaces/warehousePlace.interface';
import { WarehouseSpace as WarehouseSpaceData } from './interfaces/warehouseSpace.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/warehouseplace`;

export function createWarehousePlace(data: { name: string }) {
  return axios.post(`${apiURI}/create-warehouseplace`, data).then((response) => response.data as WarehousePlaceData);
}

export function getWarehousePlacesByCompanyId() {
  return axios
    .get(`${apiURI}/get-warehouseplaces-by-company-id`)
    .then((response) => response.data as WarehousePlaceData[]);
}

export function deleteWarehousePlace(data: { warehousePlaceId: string }) {
  return axios
    .post(`${apiURI}/delete-warehouseplace-by-id`, data)
    .then((response) => response.data as WarehousePlaceData);
}

export function updateWarehousePlace(data: {
  name: string;
  _id: string;
  company: Company;
  user: User;
  spaces: WarehouseSpaceData[];
  dateCreated: string;
}) {
  return axios
    .post(`${apiURI}/update-warehouseplace-by-id`, data)
    .then((response) => response.data as WarehousePlaceData);
}
