// @ts-nocheck

// Packages
import React, { useState } from 'react';

// Local Components

// MUI
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

// Utils
import isEmpty from 'src/utils/isEmpty';
import ServiceDialog from './components/_ServiceDialog';

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
  editIcon: {
    color: theme.palette.yellow.main,
  },
  deleteIcon: {
    color: theme.palette.red.main,
  },
  cell: {
    borderBottom: 0,
  },
}));

function Services({ handleSubmit, companyData, showLastItemMessage }) {
  const classes = useStyles();
  const [openServiceDialog, setOpenServiceDialog] = useState(false);
  const [editService, setEditService] = useState();

  function handleCreateService(serviceData) {
    const updatedCompanyData = {
      ...companyData,
      services: companyData.services ? [...companyData.services, serviceData] : [serviceData],
    };

    const message = 'Neue Dienstleistung wurde erstellt';
    const severity = 'success';

    handleSubmit(updatedCompanyData, message, severity);
  }

  function handleUpdateService(serviceData, serviceIndex) {
    const updatedCompanyData = {
      ...companyData,
      services: [
        ...companyData.services.slice(0, serviceIndex),
        serviceData,
        ...companyData.services.slice(serviceIndex + 1),
      ],
    };

    const message = 'Dienstleistung wurde aktualisiert';
    const severity = 'success';

    handleSubmit(updatedCompanyData, message, severity);
  }

  function handleDeleteService(serviceData) {
    const isLastItem = companyData.services.length < 2;

    if (isLastItem) {
      showLastItemMessage();
    } else {
      const index = companyData.services.indexOf(serviceData);

      const updatedCompanyData = {
        ...companyData,
        services: [...companyData.services.slice(0, index), ...companyData.services.slice(index + 1)],
      };

      const message = 'Dienstleistung wurde gelöscht';
      const severity = 'info';

      handleSubmit(updatedCompanyData, message, severity);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            {!isEmpty(companyData.services) && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Dienstleistung</TableCell>
                    <TableCell>
                      <Tooltip title="Dienstleistung hinzufügen" placement="bottom-end" followCursor>
                        <Button
                          fullWidth
                          variant="contained"
                          className={classes.addButton}
                          onClick={() => {
                            setEditService('');
                            setOpenServiceDialog(true);
                          }}
                          color="primary"
                          size="large"
                        >
                          <AddCircleIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {companyData.services.map((service, index) => (
                    <TableRow hover key={index}>
                      <TableCell className={classes.cell}>{service}</TableCell>
                      <TableCell className={classes.cell}>
                        <Tooltip title="Dienstleistung bearbeiten" placement="bottom-end" followCursor>
                          <IconButton
                            className={classes.editIcon}
                            onClick={() => {
                              setEditService(service);
                              setOpenServiceDialog(true);
                            }}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Dienstleistung löschen" placement="bottom-end" followCursor>
                          <IconButton
                            onClick={() => handleDeleteService(service)}
                            size="large"
                            className={classes.deleteIcon}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          {openServiceDialog && (
            <ServiceDialog
              openServiceDialog={openServiceDialog}
              setOpenServiceDialog={setOpenServiceDialog}
              handleCreateService={handleCreateService}
              editService={editService}
              handleUpdateService={handleUpdateService}
              companyData={companyData}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Services;
