// @ts-nocheck
// Packages
import React, { useState } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Interfaces
import { Purchaser as PurchaserData } from 'src/services/interfaces/purchaser.interface';
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';

// MUI
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

// Global Components
import { PurchaserDialog } from 'src/components';

const useStyles = makeStyles({
  button: {
    minHeight: '54px',
  },
});

interface Props {
  handleUpdateBaseData: (data: { name: string; value: any }) => void;
  purchasers: PurchaserData[];
  setPurchasers: React.Dispatch<React.SetStateAction<PurchaserData[]>>;
}

function Purchaser({ purchasers, setPurchasers, handleUpdateBaseData }: Props) {
  const classes = useStyles();
  const [openPurchaserDialog, setOpenPurchaserDialog] = useState(false);
  const { customer, purchaser }: { customer: CustomerData; purchaser: PurchaserData } = useSelector(
    (state: RootStateOrAny) => state.assignment.assignmentForm,
  );

  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={10}>
          <Autocomplete
            value={!isEmpty(purchaser) ? purchaser : null}
            disabled={isEmpty(customer)}
            clearText="Enfernen"
            openText="Oeffnen"
            onChange={(event, purchaser) => {
              handleUpdateBaseData({ name: 'purchaser', value: purchaser });
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => `${option.salutation.shortTitle} ${option.name}`}
            options={purchasers}
            renderInput={(params) => <TextField {...params} label="Bearbeiter" variant="outlined" />}
          />
        </Grid>

        {isEmpty(purchaser) && (
          <Grid item xs={2}>
            <Tooltip title="Bearbeiter hinzufügen" placement="bottom-end" followCursor>
              <Button
                disabled={isEmpty(customer)}
                variant="contained"
                color="green"
                onClick={() => {
                  handleUpdateBaseData({ name: 'purchaser', value: {} });
                  setOpenPurchaserDialog(true);
                }}
                size="large"
                className={classes.button}
              >
                <AddIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}

        {!isEmpty(purchaser) && (
          <Grid item xs={2}>
            <Tooltip title="Bearbeiter ändern" placement="bottom-end" followCursor>
              <Button
                disabled={isEmpty(purchaser)}
                variant="contained"
                color="yellow"
                onClick={() => setOpenPurchaserDialog(true)}
                size="large"
                className={classes.button}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {openPurchaserDialog && (
        <PurchaserDialog
          openPurchaserDialog={openPurchaserDialog}
          setOpenPurchaserDialog={setOpenPurchaserDialog}
          purchasers={purchasers}
          setPurchasers={setPurchasers}
          handleUpdateBaseData={handleUpdateBaseData}
        />
      )}
    </>
  );
}

export default Purchaser;
