// Packages
import axios from 'axios';

// Interfaces
import { Purchaser as PurchaserData } from './interfaces/purchaser.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/purchaser`;

export function getAllPurchasers() {
  return axios.get(`${apiURI}/get-all-purchasers`);
}

export function getPurchasersByCustomerId(data: { customerId: string }) {
  return axios
    .post(`${apiURI}/get-purchasers-by-customer-id`, data)
    .then((response) => response.data as PurchaserData[]);
}

export function createPurchaser(data: PurchaserData) {
  return axios.post(`${apiURI}/create-purchaser`, data).then((response) => response.data as PurchaserData);
}

export function updatePurchaser(data: PurchaserData) {
  return axios.post(`${apiURI}/update-purchaser`, data).then((response) => response.data as PurchaserData);
}

export function deletePurchaser(data: { _id: string; customer: string }) {
  return axios.post(`${apiURI}/delete-purchaser`, data).then((response) => response.data as PurchaserData);
}
