import axios from 'axios';
import { User as UserData } from './interfaces/user.interface';

const apiURI = `${process.env.REACT_APP_API_URI}/user`;

export function getAllUser() {
  return axios.get(`${apiURI}/get-all-user`).then((response) => response.data as UserData[]);
}

export function getActiveUsersByCompanyId() {
  return axios.get(`${apiURI}/get-active-users-by-company-id`).then((response) => response.data as UserData[]);
}

export function getCurrentUser() {
  return axios.get(`${apiURI}/get-current-user`).then((response) => response.data as unknown);
}

export function getMaxPersonnelNo() {
  return axios.get(`${apiURI}/get-max-personnel-no`).then((response) => response.data as number);
}

export function updateUser(data: { _id: string; passwordStatus: string | undefined; password: string }) {
  return axios.patch(`${apiURI}/update-user`, data);
}

export function addAvatar(data: FormData) {
  return axios.post(`${apiURI}/add-avatar`, data);
}

export function deleteAvatar(data: string) {
  return axios.post(`${apiURI}/delete-avatar`, data);
}
