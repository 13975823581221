// @ts-nocheck

// Packages
import React, { useState, useEffect } from 'react';

// Services
import { getWarehousePlacesByCompanyId } from 'src/services/warehousePlace';

// Interfaces
import { WarehousePlace } from 'src/services/interfaces/warehousePlace.interface';

// MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

// Locale Components
import { PlaceItem, Header } from './components';

const useStyles = makeStyles((theme) => ({
  box: {
    maxWidth: '300px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  gridTitle: {
    padding: '24px 0 12px 16px',
  },
}));

function WarehousePlaces() {
  const classes = useStyles();
  const [warehousePlaces, setWarehousePlaces] = useState([] as WarehousePlace[]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    getInitialData();
  }, []);

  async function getInitialData() {
    const foundWarehousePlaces: WarehousePlace[] = await getWarehousePlacesByCompanyId();
    setWarehousePlaces(foundWarehousePlaces);
  }

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box className={classes.box}>
      <Grid container spacing={5}>
        <Grid item>
          <Grid container spacing={4} alignItems="center" className={classes.gridTitle}>
            <Grid item mobile={7}>
              <Typography className={classes.title}>Lagerorte</Typography>
            </Grid>
            <Grid item mobile={5}>
              <Typography>
                <Header warehousePlaces={warehousePlaces} setWarehousePlaces={setWarehousePlaces} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        {warehousePlaces.map((warehousePlace, index) => (
          <Grid item mobile={12} key={index}>
            <PlaceItem
              warehousePlace={warehousePlace}
              warehousePlaces={warehousePlaces}
              setWarehousePlaces={setWarehousePlaces}
              expanded={expanded === warehousePlace._id}
              handleExpanded={handleExpanded(warehousePlace._id)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default WarehousePlaces;
