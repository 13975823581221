// @ts-nocheck

// Packages
import React from 'react';

// Views
import { App as AppLayout } from 'src/layouts';
import { default as InvoiceView } from './_Invoice';

// Layouts

function Invoice() {
  return (
    <AppLayout>
      <InvoiceView />
    </AppLayout>
  );
}

export default Invoice;
