// Packages
import React, { useEffect } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';

// Interfaces
import { Position as PositionData } from 'src/services/interfaces/position.interface';
import { Unit as UnitData, VatRate as VatRateData } from 'src/services/interfaces/company.interface';

// MUI
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

interface Props {
  position: PositionData;
  setPosition: React.Dispatch<React.SetStateAction<PositionData>>;
  handleUpdatePosition: (data: any) => void;
}

function Price({ position, setPosition, handleUpdatePosition }: Props): JSX.Element {
  const { units, vatRates }: { units: UnitData[]; vatRates: VatRateData[] } = useSelector(
    (state: RootStateOrAny) => state.auth.company,
  );
  const { zeroVatRate } = useSelector((state: RootStateOrAny) => state.assignment.assignmentByAssignmentId);
  const { quantity, unit, vatRate, price } = position;

  useEffect(() => {
    handleSetUnitAndVatRate();
  }, [position.unit, position.service, position.material]); // eslint-disable-line

  function getCurrentVatRate() {
    // @ts-ignore
    return vatRates.filter((vatRate) => new Date(vatRate.date) <= new Date()).sort((a, b) => a.date - b.date)[
      vatRates.length - 1
    ];
  }

  function getCurrentUnit() {
    const unitIndex = units.map((unit) => unit.name).indexOf(unit && unit.name);
    return unitIndex === -1 ? units[0] : units[unitIndex];
  }

  function handleSetUnitAndVatRate() {
    setPosition({
      ...position,
      vatRate: zeroVatRate
        ? '0'
        : getCurrentVatRate().reduced === vatRate
        ? getCurrentVatRate().reduced
        : getCurrentVatRate().regular,
      unit: getCurrentUnit(),
    });
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
      <Grid item xs>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            label="Menge"
            type="number"
            value={quantity || 1}
            name="quantity"
            onChange={handleUpdatePosition}
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Einheit</InputLabel>
          <Select
            label="Einheit"
            name="unit"
            value={units[unit ? units.map((unit) => unit.name).indexOf(unit.name) : 0]}
            onChange={handleUpdatePosition}
          >
            {units.map((unit, index) => (
              // @ts-ignore
              <MenuItem value={unit} key={index}>
                {unit.shortName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            name="price"
            label="Preis"
            type="number"
            value={price || ''}
            onChange={handleUpdatePosition}
          />
        </FormControl>
      </Grid>
      <Grid item xs>
        <FormControl fullWidth variant="outlined">
          <InputLabel>MwSt</InputLabel>
          <Select
            disabled={zeroVatRate}
            label="MwSt"
            name="vatRate"
            value={vatRate || vatRates[0]}
            onChange={handleUpdatePosition}
          >
            {zeroVatRate && <MenuItem value="0">ohne (0%)</MenuItem>}
            {!zeroVatRate && (
              <MenuItem value={getCurrentVatRate().regular}>regulär ({getCurrentVatRate().regular}%)</MenuItem>
            )}
            {!zeroVatRate && (
              <MenuItem value={getCurrentVatRate().reduced}>reduziert ({getCurrentVatRate().reduced}%)</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default Price;
