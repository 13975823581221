// @ts-nocheck

// Packages
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import moment from 'moment';

// Redux
import { showSnackbarReducer } from 'src/slices/snackbarSlice';

// Utils
import isEmpty from 'src/utils/isEmpty';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  abortButton: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  },
  greenButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

function VatRateDialog({
  editVatRate,
  openVatRateDialog,
  setOpenVatRateDialog,
  handleCreateVatRate,
  handleUpdateVatRate,
  companyData,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [vatRateData, setVatRateData] = useState(editVatRate.date ? editVatRate : {});

  useEffect(() => {
    setVatRateData(editVatRate.date ? editVatRate : {});
    return () => setVatRateData({});
  }, []); //eslint-disable-line

  function handleChangeVatRateData(event) {
    const { name, value } = event.target;
    setVatRateData({ ...vatRateData, [name]: value });
  }

  function handleSubmit() {
    const isDuplicateVatRateDate = companyData.vatRates
      ? companyData.vatRates.map((vatRate) => vatRate.date).includes(vatRateData.date)
      : false;

    const vatRateIndex = companyData.vatRates
      ? companyData.vatRates.map((vatRate) => vatRate.date).indexOf(editVatRate.date)
      : -1;

    const snackbarNote = {
      message: 'ab diesem Datum ist bereits ein MwSt-Satz vorhanden',
      severity: 'error',
    };

    if (isEmpty(editVatRate)) {
      !isDuplicateVatRateDate ? handleCreateVatRate(vatRateData) : dispatch(showSnackbarReducer(snackbarNote));
    } else {
      !isDuplicateVatRateDate || editVatRate.date === vatRateData.date
        ? handleUpdateVatRate(vatRateData, vatRateIndex)
        : dispatch(showSnackbarReducer(snackbarNote));
    }
  }

  return (
    <Dialog maxWidth="md" open={openVatRateDialog} onClose={() => setOpenVatRateDialog(false)}>
      <Box textAlign="center">
        <DialogTitle className={classes.dialogTitle}>
          MwSt-Satz {editVatRate.date ? 'bearbeiten' : 'hinzufügen'}
        </DialogTitle>
      </Box>
      <DialogContent>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils} locale={deLocale}>
              <DatePicker
                // fullWidth
                // disableToolbar
                // autoOk
                // variant="inline"
                // inputVariant="outlined"
                // format="dd.MM.yyyy"
                // invalidDateMessage=""
                // id="date-picker-inline"
                label="Gültig ab"
                value={vatRateData.date || null}
                renderInput={(props) => <TextField {...props} />}
                onChange={(date) =>
                  setVatRateData({
                    ...vatRateData,
                    date: moment(date).format('YYYY-MM-DD'),
                  })
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="regulärer Satz (%)"
              type="number"
              value={vatRateData.regular || ''}
              name="regular"
              fullWidth
              onChange={handleChangeVatRateData}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="reduzierter Satz (%)"
              type="number"
              value={vatRateData.reduced || ''}
              name="reduced"
              fullWidth
              onChange={handleChangeVatRateData}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item mobile={6}>
          <Button
            fullWidth
            variant="contained"
            className={classes.abortButton}
            onClick={() => setOpenVatRateDialog(false)}
          >
            Abbrechen
          </Button>
        </Grid>
        <Grid item mobile={6}>
          <Button
            fullWidth
            variant="contained"
            className={classes.greenButton}
            onClick={handleSubmit}
            disabled={
              isEmpty(vatRateData.date) ||
              (editVatRate.date === vatRateData.date &&
                editVatRate.regular === vatRateData.regular &&
                editVatRate.reduced === vatRateData.reduced)
            }
          >
            {editVatRate.date ? 'Aktualisieren' : 'Erstellen'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default VatRateDialog;
