// @ts-nocheck

// converts a simple object ({ a: 'b' }) into a query string ('a=b') and remove values with empty key

function objToQuery(object): string {
  // remove keys with empty values
  Object.keys(object).forEach((k) => object[k] === '' && delete object[k]);

  // convert cleaned object to query string
  const queryString = Object.keys(object)
    .map((key) => `${key}=${object[key]}`)
    .join('&');

  return queryString;
}

export default objToQuery;
