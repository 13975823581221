// @ts-nocheck

//  Packages
import React from 'react';

// MUI
import { List, Typography, Paper } from '@mui/material';

// Local Components
import { Item } from './components';

function PackingListPositions({
  packingListPositions,
  handleDeletePositionOnPackingList,
  handleUpdatePositionOnPackingList,
}) {
  return (
    <>
      <Typography variant="h6" color="text.red" align="center">
        LIEFERSCHEIN
      </Typography>

      <Paper>
        <List disablePadding>
          {packingListPositions
            .sort((a, b) => {
              return a.order.number.localeCompare(b.order.number) || a.number - b.number;
            })
            .map((position) => (
              <Item
                key={position._id}
                position={position}
                handleUpdatePositionOnPackingList={handleUpdatePositionOnPackingList}
                handleDeletePositionOnPackingList={handleDeletePositionOnPackingList}
              />
            ))}
        </List>
      </Paper>
    </>
  );
}

export default PackingListPositions;
