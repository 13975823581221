// Packages
import React, { useState, useEffect } from 'react';
// Packages
import { useSelector, RootStateOrAny } from 'react-redux';

// Utils
import isEmpty from 'src/utils/isEmpty';

// Services
import { getWarehouseItemsByFilter, updateWarehouseItem } from 'src/services/warehouseItem';

// Interfaces
import { WarehousePlace } from 'src/services/interfaces/warehousePlace.interface';
import { WarehouseItem } from 'src/services/interfaces/warehouseItem.interface';

// MUI
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';

// React-Icons
import { BsCircleFill, BsCircle, BsBoxSeam, BsFillHexagonFill, BsSquareFill, BsTrophy } from 'react-icons/bs';
import { WarehouseCategoryData } from '../../_Warehouse';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
    textAlign: 'center',
  },
  button: {
    fontSize: '22px',
    height: '56px',
    padding: '0',
  },
  avatar: {
    color: theme.palette.black.main,
    backgroundColor: theme.palette.yellow.main,
    display: 'inline-flex',
    marginLeft: '10px',
  },
}));

interface Props {
  handleGetOutsourcedWarehouseItems: () => Promise<void>;
  warehouseItems: WarehouseItem[];
  category: WarehouseCategoryData;
  setWarehouseItems: Function;
  warehousePlaces: WarehousePlace[];
  warehouseItemToFind: any;
  handleChangeWarehouseItemToFind: Function;
  handleOpenNumPad: (data: string) => void;
  setNumPadOpen: (data: boolean) => void;
  setWarehouseItemToFind: React.Dispatch<React.SetStateAction<any>>;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  setOutscourcedListOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function WarehouseItemFind({
  handleGetOutsourcedWarehouseItems,
  warehouseItems,
  category,
  setWarehouseItems,
  warehousePlaces,
  warehouseItemToFind,
  handleChangeWarehouseItemToFind,
  handleOpenNumPad,
  setNumPadOpen,
  setWarehouseItemToFind,
  setTabValue,
  setOutscourcedListOpen,
}: Props): JSX.Element {
  const classes = useStyles();
  const { company } = useSelector((state: RootStateOrAny) => state.auth);
  const [isStoreListDialogOpen, setIsStoreListDialogOpen] = useState(false);
  const size = useWindowSize();

  async function handleOutscourceWarehouseItem(warehouseItem: WarehouseItem): Promise<void> {
    try {
      const updatedWarehouseItem: WarehouseItem = await updateWarehouseItem({
        ...warehouseItem,
        isOutsourced: true,
        dateOutsourced: Date.now(),
      });

      const itemIndex = warehouseItems.map((warehouseItem) => warehouseItem._id).indexOf(updatedWarehouseItem._id);

      setWarehouseItems([
        ...warehouseItems.slice(0, itemIndex),
        updatedWarehouseItem,
        ...warehouseItems.slice(itemIndex + 1),
      ]);

      setTabValue(0);
      setIsStoreListDialogOpen(false);
      handleGetOutsourcedWarehouseItems();
      setOutscourcedListOpen(true);
    } catch (error) {
      if (error) throw error;
    }
  }

  async function handleGetWarehouseItemsByFilter(event: { preventDefault: () => void }): Promise<void> {
    event.preventDefault();
    const foundWarehouseItems = await getWarehouseItemsByFilter({ ...warehouseItemToFind, category });
    setWarehouseItems(foundWarehouseItems);
    setIsStoreListDialogOpen(true);
  }

  function useWindowSize() {
    // +++ copied from: https://usehooks.com/useWindowSize/ +++
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener('resize', handleResize);

      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
  }

  return (
    <>
      <form onSubmit={handleGetWarehouseItemsByFilter}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="min. Außen-Ø (mm)"
              type="number"
              value={warehouseItemToFind.outerDiameter || ''}
              name="outerDiameter"
              onChange={({ target }) => {
                handleChangeWarehouseItemToFind({
                  name: target.name,
                  value: parseFloat(target.value),
                });
              }}
              inputProps={{ onClick: () => handleOpenNumPad('outerDiameter') }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="max. Innen-Ø (mm)"
              type="number"
              value={warehouseItemToFind.innerDiameter || ''}
              name="innerDiameter"
              onChange={({ target }) => {
                handleChangeWarehouseItemToFind({
                  name: target.name,
                  value: parseFloat(target.value),
                });
              }}
              inputProps={{ onClick: () => handleOpenNumPad('innerDiameter') }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="min. Breite (mm)"
              type="number"
              value={warehouseItemToFind.width || ''}
              name="width"
              onChange={({ target }) => {
                handleChangeWarehouseItemToFind({
                  name: target.name,
                  value: parseFloat(target.value),
                });
              }}
              inputProps={{ onClick: () => handleOpenNumPad('width') }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="min. Höhe (mm)"
              type="number"
              value={warehouseItemToFind.height || ''}
              name="height"
              onChange={({ target }) => {
                handleChangeWarehouseItemToFind({
                  name: target.name,
                  value: parseFloat(target.value),
                });
              }}
              inputProps={{ onClick: () => handleOpenNumPad('height') }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="min. Länge (mm)"
              type="number"
              value={warehouseItemToFind.length || ''}
              name="length"
              onChange={({ target }) => {
                handleChangeWarehouseItemToFind({
                  name: target.name,
                  value: parseFloat(target.value),
                });
              }}
              inputProps={{ onClick: () => handleOpenNumPad('length') }}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <Autocomplete
              value={!isEmpty(warehouseItemToFind.material) ? warehouseItemToFind.material : undefined}
              clearText="Enfernen"
              openText="Oeffnen"
              onChange={(event, material) => {
                handleChangeWarehouseItemToFind({
                  name: 'material',
                  value: material,
                });
              }}
              // @ts-ignore
              isOptionEqualToValue={(option, value) => option._id === value._id}
              // @ts-ignore
              getOptionLabel={(option) => `${option.number} | ${option.shortName} | ${option.name} | ${option.dinEn}`}
              options={company.materials}
              renderInput={(params) => <TextField {...params} label="Werkstoff" variant="outlined" />}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={warehouseItemToFind.certificate || false}
                  color="primary"
                  name="certificate"
                  onChange={(event) => {
                    setWarehouseItemToFind({
                      ...warehouseItemToFind,
                      certificate: event.target.checked,
                    });
                  }}
                />
              }
              label=" Zeugnis"
              labelPlacement="start"
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              variant="outlined"
              label="Artikel-Nr."
              type="text"
              value={warehouseItemToFind.articleNo || ''}
              name="articleNo"
              onChange={({ target }) => {
                handleChangeWarehouseItemToFind({
                  name: target.name,
                  value: target.value,
                });
              }}
              inputProps={{ onClick: () => handleOpenNumPad('articleNo') }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Bezeichnung"
              type="text"
              value={warehouseItemToFind.description || ''}
              name="description"
              onChange={({ target }) => {
                handleChangeWarehouseItemToFind({
                  name: target.name,
                  value: target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="min. Menge"
              type="number"
              value={warehouseItemToFind.quantity || ''}
              name="quantity"
              onChange={({ target }) => {
                handleChangeWarehouseItemToFind({
                  name: target.name,
                  value: parseFloat(target.value),
                });
              }}
              inputProps={{ onClick: () => handleOpenNumPad('quantity') }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Lager-Ort</InputLabel>
              <Select
                label="Lager-Ort"
                value={warehouseItemToFind.place || ''}
                name="place"
                onChange={({ target }) => {
                  handleChangeWarehouseItemToFind({
                    name: target.name,
                    value: target.value,
                  });
                }}
              >
                {warehousePlaces.map((place, index) => {
                  return (
                    // @ts-ignore
                    <MenuItem value={place} key={index}>
                      {place.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Lager-Platz</InputLabel>
              <Select
                label="Lager-Platz"
                value={warehouseItemToFind.space || ''}
                name="space"
                onChange={({ target }) => {
                  handleChangeWarehouseItemToFind({
                    name: target.name,
                    value: target.value,
                  });
                }}
              >
                {warehousePlaces
                  .filter((place) => place === warehouseItemToFind.place)
                  .map((place) =>
                    place.spaces.map((space, index) => (
                      // @ts-ignore
                      <MenuItem value={space} key={index}>
                        {space.name}
                      </MenuItem>
                    )),
                  )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <Button
              onClick={() => {
                setWarehouseItemToFind({
                  category: '',
                  outerDiameter: '',
                  innerDiameter: '',
                  length: '',
                  width: '',
                  height: '',
                  material: '',
                  description: '',
                  quantity: '',
                  articleNo: '',
                  place: '',
                  space: '',
                  certificate: false,
                } as unknown as WarehouseItem);
                setNumPadOpen(false);
              }}
              className={classes.button}
              fullWidth
              // @ts-ignore
              color="red"
              variant="contained"
            >
              Filter zurücksetzen
            </Button>
          </Grid>

          <Grid item xs={12} md={6}>
            <Button
              className={classes.button}
              type="submit"
              onClick={handleGetWarehouseItemsByFilter}
              // @ts-ignore
              color="green"
              fullWidth
              variant="contained"
            >
              Finden
            </Button>
          </Grid>
        </Grid>
      </form>

      <Dialog
        maxWidth="xl"
        open={isStoreListDialogOpen}
        onClose={() => setIsStoreListDialogOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.title} onClick={() => setIsStoreListDialogOpen(false)}>
          Lager-Liste
        </DialogTitle>
        <DialogContent sx={{ overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: size.height - 130, overflow: 'auto' }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Kategorie</TableCell>
                  <TableCell align="right">AußenØ</TableCell>
                  <TableCell align="right">InnenØ</TableCell>
                  <TableCell align="right">Breite</TableCell>
                  <TableCell align="right">Höhe</TableCell>
                  <TableCell align="right">Länge</TableCell>
                  <TableCell align="right">Werkstoff</TableCell>
                  <TableCell align="right">Zeugnis</TableCell>
                  <TableCell align="right">ArtikelNr.</TableCell>
                  <TableCell align="right">FertigTeil</TableCell>
                  <TableCell align="right">Bezeichnung</TableCell>
                  <TableCell align="right">Menge</TableCell>
                  <TableCell />
                  <TableCell align="right">LagerOrt</TableCell>
                  <TableCell align="right">LagerPlatz</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {warehouseItems.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {item.category === 'round' && <BsCircleFill fontSize="2rem" color="#2196f3" />}
                      {item.category === 'tube' && <BsCircle fontSize="2rem" color="#2196f3" />}
                      {item.category === 'hexagon' && <BsFillHexagonFill fontSize="2rem" color="#2196f3" />}
                      {item.category === 'flat' && <BsSquareFill fontSize="2rem" color="#2196f3" />}
                      {item.category === 'article' && <BsTrophy fontSize="2rem" color="#2196f3" />}
                      {item.category === 'diverse' && <BsBoxSeam fontSize="2rem" color="#2196f3" />}
                    </TableCell>
                    <TableCell align="right">{item.outerDiameter && `Ø${item.outerDiameter}`}</TableCell>
                    <TableCell align="right">{item.innerDiameter && `Ø${item.innerDiameter}`}</TableCell>
                    <TableCell align="right">{item.width}</TableCell>
                    <TableCell align="right">{item.height}</TableCell>
                    <TableCell align="right">{item.length}</TableCell>

                    <TableCell align="right">
                      {!isEmpty(item.material)
                        ? `${item.material.name} | ${item.material.shortName} | ${item.material.number} | ${item.material.dinEn}`
                        : ''}
                    </TableCell>

                    <TableCell align="right">{item.certificate}</TableCell>
                    <TableCell align="right">{item.articleNo}</TableCell>
                    <TableCell align="right">{item.finished}</TableCell>
                    <TableCell align="right">{item.description}</TableCell>
                    <TableCell align="center">
                      <Avatar className={classes.avatar}>{item.quantity}</Avatar>
                    </TableCell>
                    <TableCell />
                    <TableCell align="center">{item.place.name}</TableCell>
                    <TableCell align="center">{item.space.name}</TableCell>
                    <TableCell>
                      <Button variant="contained" color="primary" onClick={() => handleOutscourceWarehouseItem(item)}>
                        Auslagern
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default WarehouseItemFind;
