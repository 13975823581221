// @ts-nocheck

// Packages
import React, { useState } from 'react';

// Local Components

// MUI
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

// Utils
import isEmpty from 'src/utils/isEmpty';
import UnitDialog from './components/_UnitDialog';

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
  editIcon: {
    color: theme.palette.yellow.main,
  },
  deleteIcon: {
    color: theme.palette.red.main,
  },
  cell: {
    borderBottom: 0,
  },
}));

function Units({ handleSubmit, companyData, showLastItemMessage }) {
  const classes = useStyles();
  const [openUnitDialog, setOpenUnitDialog] = useState(false);
  const [editUnit, setEditUnit] = useState({});

  function handleCreateUnit(unitData) {
    const updatedCompanyData = {
      ...companyData,
      units: companyData.units ? [...companyData.units, unitData] : [unitData],
    };

    const message = 'Neue Einheit wurde erstellt';
    const severity = 'success';

    handleSubmit(updatedCompanyData, message, severity);
  }

  function handleUpdateUnit(unitData, unitIndex) {
    const updatedCompanyData = {
      ...companyData,
      units: [...companyData.units.slice(0, unitIndex), unitData, ...companyData.units.slice(unitIndex + 1)],
    };

    const message = 'Einheit wurde aktualisiert';
    const severity = 'success';

    handleSubmit(updatedCompanyData, message, severity);
  }

  function handleDeleteUnit(unitData) {
    const isLastItem = companyData.units.length < 2;

    if (isLastItem) {
      showLastItemMessage();
    } else {
      const index = companyData.units.map((unit) => unit.name).indexOf(unitData.name);

      const updatedCompanyData = {
        ...companyData,
        units: [...companyData.units.slice(0, index), ...companyData.units.slice(index + 1)],
      };

      const message = 'Einheit wurde gelöscht';
      const severity = 'info';

      handleSubmit(updatedCompanyData, message, severity);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            {!isEmpty(companyData.units) && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Einheit</TableCell>
                    <TableCell>Kurschreibweise</TableCell>
                    <TableCell>
                      <Tooltip title="Einheit hinzufügen" placement="bottom-end" followCursor>
                        <Button
                          fullWidth
                          variant="contained"
                          className={classes.addButton}
                          onClick={() => {
                            setEditUnit({});
                            setOpenUnitDialog(true);
                          }}
                          size="large"
                        >
                          <AddCircleIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {companyData.units.map((unit, index) => (
                    <TableRow hover key={index}>
                      <TableCell className={classes.cell}>{unit.name}</TableCell>
                      <TableCell className={classes.cell}>{unit.shortName}</TableCell>
                      <TableCell className={classes.cell}>
                        <Tooltip title="Einheit bearbeiten" placement="bottom-end" followCursor>
                          <IconButton
                            className={classes.editIcon}
                            onClick={() => {
                              setEditUnit(unit);
                              setOpenUnitDialog(true);
                            }}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Einheit löschen" placement="bottom-end" followCursor>
                          <IconButton
                            onClick={() => handleDeleteUnit(unit)}
                            size="large"
                            className={classes.deleteIcon}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          {openUnitDialog && (
            <UnitDialog
              openUnitDialog={openUnitDialog}
              setOpenUnitDialog={setOpenUnitDialog}
              handleCreateUnit={handleCreateUnit}
              editUnit={editUnit}
              handleUpdateUnit={handleUpdateUnit}
              companyData={companyData}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Units;
