// import { colors } from '@material-ui/core';
// https://mui.com/customization/palette/#adding-new-colors

export const white = '#FFFFFF';
export const black = '#424242';
export const lightGrey = '#666666';
export const superLightGrey = '#DCDCDC';
export const green = '#4caf50';
export const lightGreen = '#4caf5050';
export const superLightGreen = '#4caf50b8';
export const blue = '#2196f3';
export const lightBlue = '#2196f350';
export const yellow = '#ffc107';
export const lightYellow = '#ffc10750';
export const red = '#d50000';
export const lightRed = '#d5000050';

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary'];
    black: Palette['primary'];
    lightGrey: Palette['primary'];
    superLightGrey: Palette['primary'];
    green: Palette['primary'];
    lightGreen: Palette['primary'];
    superLightGreen: Palette['primary'];
    blue: Palette['primary'];
    lightBlue: Palette['primary'];
    yellow: Palette['primary'];
    lightYellow: Palette['primary'];
    red: Palette['primary'];
    lightRed: Palette['primary'];
  }
  interface PaletteOptions {
    white: PaletteOptions['primary'];
    black: PaletteOptions['primary'];
    lightGrey: PaletteOptions['primary'];
    superLightGrey: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    lightGreen: PaletteOptions['primary'];
    superLightGreen: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    lightBlue: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
    lightYellow: PaletteOptions['primary'];
    red: PaletteOptions['primary'];
    lightRed: PaletteOptions['primary'];
  }
}

export default {
  white: { main: white, contrastText: '#fff' },
  black: { main: black, contrastText: '#fff' },
  lightGrey: { main: lightGrey, contrastText: '#fff' },
  superLightGrey: { main: superLightGrey, contrastText: '#fff' },
  green: { main: green, contrastText: '#fff' },
  lightGreen: { main: lightGreen, contrastText: '#fff' },
  superLightGreen: { main: superLightGreen, contrastText: '#fff' },
  blue: { main: blue, contrastText: '#fff' },
  lightBlue: { main: lightBlue, contrastText: '#fff' },
  yellow: { main: yellow, contrastText: '#fff' },
  lightYellow: { main: lightYellow, contrastText: '#fff' },
  red: { main: red, contrastText: '#fff' },
  lightRed: { main: lightRed, contrastText: '#fff' },
  text: {
    white,
    black,
    lightGrey,
    superLightGrey,
    green,
    lightGreen,
    superLightGreen,
    blue,
    lightBlue,
    yellow,
    lightYellow,
    red,
    lightRed,
  },
};
