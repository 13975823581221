// Packages
import React from 'react';

// Packages
import { Document, Page, View, Font, Text } from '@react-pdf/renderer';

// Interfaces
import {
  Company as CompanyData,
  DeliveryTerm as DeliveryTermData,
  PaymentTerm as PaymentTermData,
} from 'src/services/interfaces/company.interface';
import { PackingList as PackingListData } from 'src/services/interfaces/packingList.interface';
import { Customer as CustomerData } from 'src/services/interfaces/customer.interface';
import { Purchaser as PurchaserData } from 'src/services/interfaces/purchaser.interface';
import { Position as PositionData } from 'src/services/interfaces/position.interface';

// Fonts
import Roboto100 from '../../assets/fonts/Roboto-Thin.ttf';
import Roboto300 from '../../assets/fonts/Roboto-Light.ttf';
import Roboto400 from '../../assets/fonts/Roboto-Regular.ttf';
import Roboto500 from '../../assets/fonts/Roboto-Medium.ttf';
import Roboto700 from '../../assets/fonts/Roboto-Bold.ttf';
import Roboto900 from '../../assets/fonts/Roboto-Black.ttf';

// Local Components
import { Footer, Header, PositionsOfferOrderInvoice, PositionsPackingList } from './components';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: Roboto100, fontWeight: 100 },
    { src: Roboto300, fontWeight: 300 },
    { src: Roboto400, fontWeight: 400 },
    { src: Roboto500, fontWeight: 500 },
    { src: Roboto700, fontWeight: 700 },
    { src: Roboto900, fontWeight: 900 },
  ],
});

interface Props {
  company: CompanyData;
  number: string;
  revision: number;
  customer: CustomerData;
  positions: PositionData[];
  purchaser: PurchaserData;
  deliveryDate: string;
  deliveryTerm: DeliveryTermData;
  paymentTerm: PaymentTermData;
  customersNoForOffer: string;
  customersNoForOrder: string;
  isOffer: boolean;
  isOrder: boolean;
  isPackingList: boolean;
  isInvoice: boolean;
  deliveryCondition: PackingListData['deliveryCondition'];
}

function Pdf({
  // General
  company,
  customer,
  number,
  deliveryDate,
  deliveryTerm,
  isOffer,
  isOrder,
  isPackingList,
  isInvoice,
  // OfferOrderInvoice
  revision,
  positions,
  purchaser,
  paymentTerm,
  customersNoForOffer,
  customersNoForOrder,
  deliveryCondition,
}: Props): JSX.Element {
  const { companyName, street, houseNumber, zip, city, country, tel, eMailContact } = company;
  const footerHeight = 75;
  const transferHeight = 20;
  const subtotalHeight = 20;

  // Contains duplicates because "react-pdf" has strange rendering behaviour.
  let arrayOfPositionsPerPage: any[] = [];
  let totalPerPage: any[] = [];

  function cleanUpArrayOfPositionsPerPage(position: PositionData, pageNumber: number) {
    arrayOfPositionsPerPage = [...arrayOfPositionsPerPage, { ...position, pageNumber }];

    const groupedById = groupBy('_id', arrayOfPositionsPerPage);

    const onePositionPerPage = Object.keys(groupedById).map((key) => {
      const newArray = groupedById[key].reduce((prev: { pageNumber: number }, current: { pageNumber: number }) =>
        prev.pageNumber > current.pageNumber ? prev : current,
      );

      return newArray;
    });

    const groupedByPageNumber = groupBy('pageNumber', onePositionPerPage);
    totalPerPage = Object.keys(groupedByPageNumber).map((key) => ({
      page: key,
      total: groupedByPageNumber[key].reduce(
        (curr: number, next: { price: number; quantity: number }) => curr + next.price * next.quantity,
        0,
      ),
    }));
  }

  const groupBy = (key: string, array: any[]) =>
    array.reduce((acc: { [x: string]: any[] }, curr: { [x: string]: string | number }) => {
      if (!acc[curr[key]]) {
        acc[curr[key]] = [];
      }

      acc[curr[key]].push(curr);
      return acc;
    }, {});

  const grouped = groupBy('vatRate', positions);

  const groupedTotal = Object.keys(grouped).map((key) => {
    const positions: any = Object.values(grouped[key]);
    const totalNet = Number(
      positions
        .reduce((acc: number, curr: { price: number; quantity: number }) => acc + curr.price * curr.quantity, 0)
        .toFixed(2),
    );
    const totalVat = (totalNet / 100) * Number(key);
    const totalGross = totalNet + totalVat;

    return { vatRate: key, positions, totalNet, totalGross, totalVat };
  });

  const totalNet = Number(positions.reduce((curr, next) => curr + next.price * next.quantity, 0).toFixed(2));

  const totalVat = Number(groupedTotal.reduce((curr, acc) => curr + acc.totalVat, 0));

  const totalGross = totalNet + totalVat;

  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 90, paddingLeft: '15mm' }}>
        <View fixed>
          {/* Header */}
          <Header
            companyName={companyName}
            street={street}
            houseNumber={houseNumber}
            zip={zip}
            city={city}
            tel={tel}
            country={country}
            eMailContact={eMailContact}
            customer={customer}
            purchaser={purchaser}
            customersNoForOffer={customersNoForOffer}
            customersNoForOrder={customersNoForOrder}
            deliveryDate={deliveryDate}
            deliveryTerm={deliveryTerm}
            number={number}
            revision={revision}
            isOffer={isOffer}
            isOrder={isOrder}
            isPackingList={isPackingList}
            isInvoice={isInvoice}
          />

          {/* Transfer per Page - For offers and orders -  NOT SEPERABLE!? */}
          {(isOffer || isOrder || isInvoice) && (
            <Text
              style={{
                fontFamily: 'Roboto',
                fontSize: 10,
                fontWeight: 500,
                marginBottom: 10,
                marginRight: 20,
                height: transferHeight,
                textAlign: 'right',
              }}
              render={({ pageNumber, totalPages }) => {
                if (totalPages > 1 && pageNumber !== 1) {
                  const transfer = totalPerPage.slice(0, pageNumber - 1).reduce((acc, curr) => acc + curr.total, 0);

                  return (
                    <>
                      Übertrag{' '}
                      {new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(transfer.toString())}
                      <View style={{ borderBottom: 1, borderBottomColor: 'black' }} />
                    </>
                  );
                }
              }}
              fixed
            />
          )}
        </View>

        {(isOffer || isOrder || isInvoice) && (
          <PositionsOfferOrderInvoice
            positions={positions}
            cleanUpArrayOfPositionsPerPage={cleanUpArrayOfPositionsPerPage}
            totalNet={totalNet}
            totalGross={totalGross}
            groupedTotal={groupedTotal}
            paymentTerm={paymentTerm}
            isInvoice={isInvoice}
          />
        )}

        {isPackingList && <PositionsPackingList positions={positions} deliveryCondition={deliveryCondition} />}

        {/* Subtotal per Page - For offers and orders - NOT SEPERABLE!? */}
        {(isOffer || isOrder || isInvoice) && (
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: 10,
              fontWeight: 500,
              marginBottom: 5,
              marginRight: 20,
              height: subtotalHeight,
              textAlign: 'right',
            }}
            render={({ pageNumber, totalPages }) => {
              if (totalPages > 1 && pageNumber !== totalPages) {
                const subtotal = totalPerPage.slice(0, pageNumber).reduce((acc, curr) => acc + curr.total, 0);

                return (
                  <>
                    Zwischensumme{' '}
                    {new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(subtotal.toString())}
                  </>
                );
              }
            }}
            fixed
          />
        )}

        {/* Footer */}
        <Footer
          company={company}
          footerHeight={footerHeight}
          companyName={companyName}
          street={street}
          houseNumber={houseNumber}
          zip={zip}
          city={city}
          tel={tel}
        />
      </Page>
    </Document>
  );
}

export default Pdf;
