// @ts-nocheck

// Packages
import React, { useState } from 'react';

// MUI
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

// Utils
import isEmpty from 'src/utils/isEmpty';
import MaterialDialog from './components/_MaterialDialog';

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
  editIcon: {
    color: theme.palette.yellow.main,
  },
  deleteIcon: {
    color: theme.palette.red.main,
  },
  cell: {
    borderBottom: 0,
  },
}));

function Materials({ handleSubmit, companyData, showLastItemMessage }) {
  const classes = useStyles();
  const [openMaterialDialog, setOpenMaterialDialog] = useState(false);
  const [editMaterial, setEditMaterial] = useState();

  function handleCreateMaterial(materialData) {
    const updatedCompanyData = {
      ...companyData,
      materials: companyData.materials ? [...companyData.materials, materialData] : [materialData],
    };

    const message = 'Neuer Werkstoff wurde erstellt';
    const severity = 'success';

    handleSubmit(updatedCompanyData, message, severity);
  }

  function handleUpdateMaterial(materialData, materialIndex) {
    const updatedCompanyData = {
      ...companyData,
      materials: [
        ...companyData.materials.slice(0, materialIndex),
        materialData,
        ...companyData.materials.slice(materialIndex + 1),
      ],
    };

    const message = 'Werkstoff wurde aktualisiert';
    const severity = 'success';

    handleSubmit(updatedCompanyData, message, severity);
  }

  function handleDeleteMaterial(materialData) {
    const isLastItem = companyData.materials.length < 2;

    if (isLastItem) {
      showLastItemMessage();
    } else {
      const index = companyData.materials.map((material) => material.name).indexOf(materialData.name);

      const updatedCompanyData = {
        ...companyData,
        materials: [...companyData.materials.slice(0, index), ...companyData.materials.slice(index + 1)],
      };

      const message = 'Werkstoff wurde gelöscht';
      const severity = 'info';

      handleSubmit(updatedCompanyData, message, severity);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            {!isEmpty(companyData.materials) && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Kurzname</TableCell>
                    <TableCell>Nummer</TableCell>
                    <TableCell>DIN EN</TableCell>
                    <TableCell>
                      <Tooltip title="Werkstoff hinzufügen" placement="bottom-end" followCursor>
                        <Button
                          fullWidth
                          variant="contained"
                          className={classes.addButton}
                          onClick={() => {
                            setEditMaterial({});
                            setOpenMaterialDialog(true);
                          }}
                          size="large"
                        >
                          <AddCircleIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {companyData.materials.map((material, index) => (
                    <TableRow hover key={index}>
                      <TableCell className={classes.cell}>{material.name}</TableCell>
                      <TableCell className={classes.cell}>{material.shortName}</TableCell>
                      <TableCell className={classes.cell}>{material.number}</TableCell>
                      <TableCell className={classes.cell}>{material.dinEn}</TableCell>
                      <TableCell className={classes.cell}>
                        <Tooltip title="Werkstoff bearbeiten" placement="bottom-end" followCursor>
                          <IconButton
                            className={classes.editIcon}
                            onClick={() => {
                              setEditMaterial(material);
                              setOpenMaterialDialog(true);
                            }}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Werkstoff löschen" placement="bottom-end" followCursor>
                          <IconButton
                            onClick={() => handleDeleteMaterial(material)}
                            size="large"
                            className={classes.deleteIcon}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          {openMaterialDialog && (
            <MaterialDialog
              openMaterialDialog={openMaterialDialog}
              setOpenMaterialDialog={setOpenMaterialDialog}
              handleCreateMaterial={handleCreateMaterial}
              editMaterial={editMaterial}
              handleUpdateMaterial={handleUpdateMaterial}
              companyData={companyData}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Materials;
