// Packages
import React from 'react';

// Interfaces
import { Position as PositionData, File as FileData } from 'src/services/interfaces/position.interface';

// MUI
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface Props {
  position: PositionData;
  filesToUpload: any;
  setFilesToUpload: any;
  filesToDelete: FileData[];
  setFilesToDelete: React.Dispatch<React.SetStateAction<FileData[]>>;
}

function Files({ position, filesToUpload, setFilesToUpload, filesToDelete, setFilesToDelete }: Props): JSX.Element {
  async function onFileChange(event: { target: { files: Iterable<unknown> | ArrayLike<unknown> } }) {
    setFilesToUpload([...filesToUpload, ...Array.from(event.target.files)]);
  }

  async function onFileDelete(file: any) {
    const index = filesToUpload.map((file: { name: any }) => file.name).indexOf(file.name);
    setFilesToUpload([...Array.from(filesToUpload).slice(0, index), ...Array.from(filesToUpload).slice(index + 1)]);
  }

  function handleSetFilesToDelete(file: FileData) {
    setFilesToDelete([...filesToDelete, file]);
  }

  return (
    <Card>
      <CardHeader
        title={
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              Dateien
            </Grid>
            <Grid item>
              {/* @ts-ignore */}
              <input onChange={onFileChange} style={{ display: 'none' }} id="raised-button-file" type="file" multiple />
              <div className="icons">
                <label htmlFor="raised-button-file">
                  <IconButton color="primary" size="small" component="span">
                    <AddIcon />
                  </IconButton>
                </label>
              </div>
            </Grid>
          </Grid>
        }
      />

      <List>
        {Array.from(filesToUpload).map((file) => (
          <ListItem button>
            {/* @ts-ignore */}
            <ListItemText primary={file.name} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => onFileDelete(file)} edge="end" aria-label="delete" size="large">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {position.files &&
          position.files
            .filter((file) => {
              const ETags = filesToDelete.map((file) => file.ETag);
              return !ETags.includes(file.ETag);
            })
            .map((file) => (
              <ListItem button>
                <ListItemText primary={file.originalname} />
                <ListItemSecondaryAction>
                  <a href={file.Location} target="_blank" rel="noreferrer">
                    <IconButton size="large">
                      <VisibilityIcon />
                    </IconButton>
                  </a>
                  <IconButton onClick={() => handleSetFilesToDelete(file)} edge="end" aria-label="delete" size="large">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
      </List>
    </Card>
  );
}

export default Files;
