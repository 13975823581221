// @ts-nocheck

// Packages
import React from 'react';

// Global Components
import AssignmentForm from 'src/components/AssignmentForm';

function AssignmentCreate() {
  return <AssignmentForm assignment={null} />;
}

export default AssignmentCreate;
